import {
  FETCH_BRANDS_START,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
} from "../../constants";

import * as StrapiApi from "../../apis/strapi";

window.StrapiApi = StrapiApi;

export const getBrands = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BRANDS_START });
    const response = StrapiApi.getStrapi("brands?_limit=-1");
    response
      .then((res) => {
        dispatch({ type: FETCH_BRANDS_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_BRANDS_FAILURE, payload: err });
      });
  };
};
