import {
  FETCH_MENU_ITEMS_START,
  FETCH_MENU_ITEMS_SUCCESS,
  FETCH_MENU_ITEMS_FAILURE,
  ADD_MENU_ITEM_START,
  ADD_MENU_ITEM_SUCCESS,
  ADD_MENU_ITEM_FAILURE,
  UPDATE_MENU_ITEM_START,
  UPDATE_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_FAILURE,
  DELETE_MENU_ITEM_START,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_FAILURE,
  MESSAGE_DURATION,
  CLEAR_MENU_ITEMS,
} from "../../constants";

import { message } from "antd";

import { handleError } from "../../functions";

// import _ from "lodash";

import * as StrapiApi from "../../apis/strapi";

window.StrapiApi = StrapiApi;

export const getMenuItems = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MENU_ITEMS_START });
    const response = StrapiApi.getStrapi(`menu-items?menu.id=${id}&_limit=-1`);
    response
      .then((res) => {
        dispatch({ type: FETCH_MENU_ITEMS_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_MENU_ITEMS_FAILURE, payload: err });
      });
  };
};

export const addMenuItem = (body) => {
  return (dispatch) => {
    dispatch({ type: ADD_MENU_ITEM_START });
    const response = StrapiApi.createStrapi(`menu-items`, body);
    response
      .then((res) => {
        dispatch({ type: ADD_MENU_ITEM_SUCCESS, payload: res });
        message.success("Item successfully created!");
      })
      .catch((err) => {
        dispatch({ type: ADD_MENU_ITEM_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateMenuItem = (id, body) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MENU_ITEM_START });
    const response = StrapiApi.updateStrapi(`menu-items/${id}`, body);
    response
      .then((res) => {
        dispatch({ type: UPDATE_MENU_ITEM_SUCCESS, payload: res });
        message.success("Item succesfully updated!");
      })
      .catch((err) => {
        dispatch({ type: UPDATE_MENU_ITEM_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const deleteMenuItem = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_MENU_ITEM_START });
    const response = StrapiApi.deleteStrapi(`menu-items/${id}`);
    response
      .then((res) => {
        dispatch({ type: DELETE_MENU_ITEM_SUCCESS, payload: res });
        message.success("Item successfully deleted!");
      })
      .catch((err) => {
        dispatch({ type: DELETE_MENU_ITEM_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const clearMenuItems = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MENU_ITEMS });
  };
};
