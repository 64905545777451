import {
  FETCH_MENU_SKUS_START, FETCH_MENU_SKUS_SUCCESS, FETCH_MENU_SKUS_FAILURE
} from '../constants'

const initialState = {
  menu_skus: {},
  status: 'idle',
  error: '',
}

const menuSkusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_SKUS_START:
      return {
        ...state,
        status: 'pending',
      }
    case FETCH_MENU_SKUS_SUCCESS:
      return {
        ...state,
        status: 'succeeded',
        menu_skus: action.payload,
      }
    case FETCH_MENU_SKUS_FAILURE:
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      }
    default: {
      return state
    }
  }
}

export default menuSkusReducer
