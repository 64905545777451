import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import { DatePicker, Modal, Form, Row, Col, Button, InputNumber, Space, Select, Input } from 'antd'

import { addInternalPromo } from '../../../actions/internalPromos'
import { INTERNAL_PROMO_TYPES, INTERNAL_PLATFORMS } from '../../../constants'
import _ from 'lodash'

const {Option} = Select

const AddInternalPromoModal = ({
  internalPromosModalVisible, setInternalPromosModalVisible, addInternalPromo,
}) => {
  const [form] = Form.useForm()
  const [step, setStep] = useState('add')
  const [type, setType] = useState('')
  const [value, setValue] = useState(null)

  const menu = useSelector((state) => state.menu.menu)
  const targets = useSelector((state) => state.menu.menu.targets)
  const menuSkus = useSelector((state) => state.menu_skus.menu_skus)

  const handleSubmit = (value) => {
    addInternalPromo(menu, value, handleClose)
  }

  const handleClose = () => {
    setInternalPromosModalVisible(false)
    setType('')
    setStep('add')
    setValue(null)
    form.resetFields()
  }

  const onNext = () => {
    form.validateFields()
        .then(values => {
          setStep('link')
          setValue({...values, rule: {...values.rule, platforms: formatPlatforms(values.rule.platforms)}})
        })
  }

  const formatPlatforms=(value)=>{
    if (!value || value?.includes('All')) {
      return undefined
    }
    return value;
  }

  const onSubmit = () => {
    form.validateFields()
        .then(submitValue => {
          setValue({
            ...value, ...submitValue, targets: getTargetsByTargetIds(submitValue.targets)
          })

          handleSubmit({
            ...value, ...submitValue, menu: menu.id, targets: getTargetsByTargetIds(submitValue.targets)
          })
        })
  }

  const getTargetsByTargetIds = (targetIds) => {
    return _.chain(targets)
            .filter(target => _.includes(targetIds, target.id))
            .map(target => ({
              id: target.id, name: target.name, locationName: target.locationName
            }))
            .value()
  }

  const onTypeChange = (value) => {
    setType(value)
  }

  const onPlatformChange=(value)=>{
    if (value.includes('All') ) {
      form.setFieldsValue({ rule:{ platforms:['All'] } })
    }
  }

  const disabledDate = (current) => {
    return current && current < moment().endOf('day')
  }

  return (<>
    <Modal
      title={step === 'add' ? 'Add Internal Promo' : 'Select Menu Item & Targets'}
      centered={true}
      visible={internalPromosModalVisible}
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
      zIndex={1}
      footer={<Space>
        <Button
          onClick={handleClose}>Cancel</Button>

        <Button type="primary" onClick={() => form.submit()}>
          {step === 'add' ? 'Next' : 'Create'}
        </Button>
      </Space>}
    >
      {step === 'add' ? <Form
        form={form}
        onFinish={onNext}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="name" label="Name" rules={[{required: true}]}>
              <Input/>
            </Form.Item>

            <Form.Item shouldUpdate name="type" label="Type"
                       rules={[{required: true}]}>
              <Select
                placeholder="Select a type"
                onSelect={(value) => onTypeChange(value)}
              >
                {_.map(INTERNAL_PROMO_TYPES, (item) => (<Option key={item} value={item.id}>
                  {item}
                </Option>))}
              </Select>
            </Form.Item>
            {type === 'BASKET_SIZE' && (
              <Form.Item name={['rule', 'basketSizeThreshold']} label="Basket Size Threshold"
                         rules={[{required: true}]}>
                <InputNumber min={0} precision={0}/>
              </Form.Item>)}
            {(type === 'RETURN_CUSTOMER') && (
              <Form.Item name={['rule', 'orderIteration']} label="Order Iteration"
                         rules={[{required: true}]}>
                <InputNumber min={0} precision={0}/>
              </Form.Item>)}
            {(type === 'RETURN_CUSTOMER') && (
              <Form.Item name={['rule', 'daysSinceFirstOrder']} label="Days Since 1st Order">
                <InputNumber min={0} precision={0}/>
              </Form.Item>)}
            <Form.Item shouldUpdate name={['rule','platforms']} label="Platforms">
              <Select
                mode="multiple"
                placeholder="Select platforms"
                onChange={onPlatformChange}
              >
                {_.map(INTERNAL_PLATFORMS, (item) => (<Option key={item} value={item.id}>
                  {item}
                </Option>))}
              </Select>
            </Form.Item>
            <Form.Item name="expiry_time" label="Expiration">
              <DatePicker disabledDate={disabledDate}/>
            </Form.Item>
          </Col>
        </Row>
      </Form> : <Form
         form={form}
         onFinish={onSubmit}
       >
         <Row>
           <Col span={24}>
             <Form.Item name="sku" label="Sku" rules={[{required: true}]}>
               <Select
                 placeholder="Select a sku"
               >
                 {_.map(menuSkus, (item) => (<Option key={item.id} value={item.id}>
                   {item.name}
                 </Option>))}
               </Select>
             </Form.Item>

             <Form.Item name="targets" label="Targets" rules={[{required: true}]}>
               <Select
                 mode="multiple"
                 placeholder="Select a target"
               >
                 {_.map(targets, (item) => (<Option key={item.id} value={item.id}>
                   {item.name}
                 </Option>))}
               </Select>
             </Form.Item>
           </Col>
         </Row>
       </Form>}
    </Modal>
  </>)
}

export default connect(null, {addInternalPromo})(AddInternalPromoModal)
