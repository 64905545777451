import React, { useState } from "react";
import { Table, Input } from "antd";
import { connect, useSelector } from "react-redux";

import Fuse from "fuse.js";

import _ from "lodash";

import * as StrapiApi from "../../apis/strapi";

import "./styles.css";

window.StrapiApi = StrapiApi;

const ModifierTable = (props) => {
  const [searchPattern, setSearchPattern] = useState("");

  const { selectedModifier, setSelectedModifier } = props;

  const modifiers = useSelector((state) => state.modifiers.modifiers);

  const status = useSelector((state) => state.modifiers.status);

  const fuseSearchResults = () => {
    const options = {
      threshold: 0.2,
      keys: ["name"],
    };
    const fuse = new Fuse(modifiers, options);
    const pattern = searchPattern;
    return fuse.search(pattern).map((res) => res.item);
  };

  const tableData = searchPattern === "" ? modifiers : fuseSearchResults();

  const data = _.map(tableData, (modifier) => ({
    id: modifier.id,
    key: modifier.id,
    name: modifier.name,
    min_allowed: modifier.min_allowed,
    max_allowed: modifier.max_allowed,
    modifier_items: modifier.modifier_items,
  }));

  const handleOnChange = (e) => {
    const { value } = e.target;
    setSearchPattern(value);
  };

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <>
      <div className="modifier-search-div">
        <h2 className="modifier-table-label">Modifier Groups</h2>
        <Input
          id="modifier-search-input"
          placeholder="Search Modifier Group"
          onChange={(e) => {
            handleOnChange(e);
          }}
        />
      </div>
      <div className="modifier-table-wrapper">
        <Table
          size="small"
          className="modifier-table"
          showHeader={false}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={status === "pending" ? true : false}
          rowClassName={(record) => (selectedModifier && record.id === selectedModifier.id ? "highlighted-row" : null)}
          onRow={(record) => {
            return {
              onClick: () => setSelectedModifier(record),
            };
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu.menu,
  };
};

export default connect(mapStateToProps, null)(ModifierTable);
