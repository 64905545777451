import { 
  FETCH_TARGETS_START, 
  FETCH_TARGETS_SUCCESS, 
  FETCH_TARGETS_FAILURE, 
  UPDATE_MENU_TARGETS_START,
  UPDATE_MENU_TARGETS_SUCCESS,
  UPDATE_MENU_TARGETS_FAILURE,
  MESSAGE_DURATION 
} from "../../constants";

import * as ApiGateway from "../../apis/apiGateway";
import * as StrapiApi from "../../apis/strapi";
import { handleError } from "../../functions";
import { message } from "antd";

export const getTargets = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TARGETS_START });
    const promise = ApiGateway.fetchTargets();
    promise
      .then((res) => {
        dispatch({ type: FETCH_TARGETS_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_TARGETS_FAILURE, payload: err });
      });
  };
};

export const updateTargets = (menuId, targets, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MENU_TARGETS_START });
    const response = StrapiApi.updateStrapi(`menus/${menuId}`, {
      targets,
    });
    response
      .then((res) => {
        dispatch({ type: UPDATE_MENU_TARGETS_SUCCESS, payload: res });
        message.success("Targets successfully updated!");
        onSuccess()
      })
      .catch((err) => {
        dispatch({ type: UPDATE_MENU_TARGETS_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};
