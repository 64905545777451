import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { login } from "../../actions/login";

import "./styles.css";

const LoginRedirect = ({ login }) => {
  const [loggingIn, setLoggingIn] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (!location) {
      return;
    }
    setLoggingIn(true);
    login(`auth/${params.providerName}/callback${location.search}`, history);
    return () => setLoggingIn(false);
  }, [location, history, params, login]);

  return (
    <div className="login-wrapper">
      {loggingIn && <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />}
    </div>
  );
};

export default connect(null, { login })(LoginRedirect);
