import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, Form, Row, Col, Select } from 'antd'
import { updateTargets } from "../../../actions/targets";

import _ from "lodash";

import "./styles.css";

const UpdateTargetsModal = (props) => {
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [targetIds, setTargetIds] = useState(null);
  const [submitting, setSubmitting] = useState(false)

  const { targetsModalVisible, setTargetsModalVisible, updateTargets } = props;

  const [form] = Form.useForm();

  const { Option } = Select;

  const globalTargets = useSelector((state) => state.targets.targets);

  const existingTargets = useSelector((state) => state.menu.menu.targets);

  const menu = useSelector((state) => state.menu.menu);

  useEffect(() => {
    if (selectedTargets.length === 0) {
      setDisabled(true);
    }
  }, [selectedTargets]);

  useEffect(() => {
    const ids = _.map(existingTargets, (t) => t.id);
    setTargetIds(ids);
  }, [existingTargets]);

  const handleSubmit = async (values) => {
    setSubmitting(true);
    
    const { newTargets } = values;
    const validTargets = _.map(
      _.filter(globalTargets, (t) => newTargets.includes(t.id)), (t) => ({
        id: t.id,
        name: t.name,
        locationName: _.get(t, 'location.name', ''),
        categories_disabled: [],
        hours: null,
      })
    );

    updateTargets(menu.id, [...validTargets, ..._.defaultTo(existingTargets, [])]);

    setSubmitting(false)
    setTargetsModalVisible(false);
    setSelectedTargets([]);
    setDisabled(true);
    form.resetFields();
  };

  const handleClose = () => {
    setTargetsModalVisible(false);
    setSelectedTargets([]);
    setDisabled(true);
    form.resetFields();
  };

  const handleChange = (values) => {
    setSelectedTargets(values);
    setDisabled(false);
  };

  return (
    <>
      <Modal
        className="publish-modal"
        title="Add Targets"
        centered={true}
        confirmLoading={submitting}
        visible={targetsModalVisible}
        destroyOnClose={true}
        closable={false}
        okType={"submit"}
        okText="Add"
        onCancel={() => handleClose()}
        onOk={() => form.submit()}
        okButtonProps={{
          disabled: disabled,
        }}
        maskClosable={false}
        zIndex={1}
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="newTargets" label="Targets">
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Select targets"
                  onChange={(values) => handleChange(values)}
                  onClear={() => {
                    setSelectedTargets(null);
                    form.resetFields();
                  }}
                  optionFilterProp="children"
                  filterOption={(_, { children }) => children.toLowerCase().includes(_.toLowerCase())}
                  filterSort={(a, b) => a.children.localeCompare(b.children, undefined, { sensitivity: 'base' })}
                >
                  {_.map(globalTargets, (target) => (
                    <Option
                      key={target.id}
                      value={target.id}
                      disabled={!targetIds || _.includes(targetIds, target.id.toString())}
                    >
                      {target.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, { updateTargets })(UpdateTargetsModal);
