import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Modal, Form, Select, Row, Col, Input, Space, Button, Popconfirm } from "antd";
import { sortItemsByName } from "../../../functions";

import { addMenu } from "../../../actions/menus";

import _ from "lodash";

const AddMenuModal = (props) => {
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { menuModalVisible, setMenuModalVisible, addMenu } = props;

  const { Option } = Select;

  const [form] = Form.useForm();

  const history = useHistory();

  const brands = useSelector((state) => state.brands.brands);

  const sortedBrands = brands.filter(brand => brand.active).slice();

  sortItemsByName(sortedBrands);

  const handleSubmit = (values) => {
    const { brand, description, title } = values;
    const active = true;
    addMenu({ brand, description, title, active }, history);
    setMenuModalVisible(false);
    form.resetFields();
  };

  const handleClose = () => {
    setMenuModalVisible(false);
    setDisabled(true);
    form.resetFields();
  };

  const handlePopConfirmOk = () => {
    handleClose();
    setCancelPopConfirmVisible(false);
  };

  const handlePopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };

  return (
    <>
      <Modal
        title="Add Menu"
        centered={true}
        visible={menuModalVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        footer={
          <Space>
            <Popconfirm
              title="Are you sure? Your changes won't be saved."
              style={{ width: 100 }}
              visible={cancelPopConfirmVisible}
              onConfirm={handlePopConfirmOk}
              onCancel={handlePopConfirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button onClick={() => (disabled ? handleClose() : setCancelPopConfirmVisible(true))}>Cancel</Button>
            </Popconfirm>
            <Button disabled={disabled} onClick={() => form.submit()}>
              Save
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="brand"
                label="Brand"
                rules={[
                  {
                    required: true,
                    message: "Please select a brand",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Search brand"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {_.map(sortedBrands, (brand) => (
                    <Option key={brand.id} value={brand.id}>
                      {brand.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter menu title",
                  },
                ]}
              >
                <Input placeholder="Please enter menu title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea rows={4} showCount maxLength={500} placeholder="please enter menu description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, { addMenu })(AddMenuModal);
