import { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILURE } from "../../constants";
import { getUserData } from "../../functions";
import { message } from "antd";

// import _ from "lodash";

import * as StrapiApi from "../../apis/strapi";

window.StrapiApi = StrapiApi;

export const login = (endpoint, history) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_START });
    const response = StrapiApi.login(endpoint);
    response
      .then((res) => {
        dispatch({ type: LOGIN_SUCCESS, payload: res });
        localStorage.setItem("jwt", res.jwt);
        localStorage.setItem("user", JSON.stringify(res.user));
        getUserData();
        history.push("/");
        message.success("You have successfully logged in!");
      })
      .catch((err) => {
        dispatch({ type: LOGIN_FAILURE, payload: err });
        message.error("Access denied, please use your alldaykitchens email to login", 10);
        history.push("/login");
      });
  };
};
