import React from "react";

import MenuItemTable from "../MenuItemTable";
import { ItemsFooter } from "../Footers";

import "./styles.css";

const MenuItemComponent = (props) => {
  const { itemToEdit, setItemToEdit, setAddingItem, NO_IMAGE } = props;
  return (
    <>
      <div className="items-container">
        <MenuItemTable itemToEdit={itemToEdit} setItemToEdit={setItemToEdit} NO_IMAGE={NO_IMAGE} />
      </div>
      <ItemsFooter setAddingItem={setAddingItem} />
    </>
  );
};

export default MenuItemComponent;
