import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Avatar, Image, Tooltip, Input, Badge } from "antd";

import Fuse from "fuse.js";

import _ from "lodash";

import "./styles.css";

const MenuItemTable = (props) => {
  const [searchPattern, setSearchPattern] = useState("");

  const { itemToEdit, setItemToEdit, NO_IMAGE } = props;

  const menuItems = useSelector((state) => state.menu_items.menu_items);

  const newMenuItem = useSelector((state) => state.menu_items.new_menu_item);

  const menuItemStatus = useSelector((state) => state.menu_items.status);

  const skus = useSelector((state) => state.skus.skus);

  const fuseSearchResults = () => {
    const options = {
      threshold: 0.2,
      keys: ["name"],
    };
    const fuse = new Fuse(menuItems, options);
    const pattern = searchPattern;
    return fuse.search(pattern).map((res) => res.item);
  };

  const tableData = searchPattern === "" ? menuItems : fuseSearchResults();

  useEffect(() => {
    if (newMenuItem) {
      setItemToEdit(newMenuItem);
    }
  }, [newMenuItem, setItemToEdit]);

  const data = _.map(tableData, (item) => ({
    id: item.id,
    key: item.id,
    available: item.available,
    name: item.name,
    skus: skus.find((s) => s.id === _.get(item, "skus.id")),
    price: item.price,
    description: item.description,
    image: _.get(item, "skus.image_uber_eats.formats.thumbnail.url", NO_IMAGE),
  }));

  const handleOnChange = (e) => {
    const { value } = e.target;
    setSearchPattern(value);
  };

  const columns = [
    {
      title: "skus",
      dataIndex: "skus",
      key: "skus",
      render: (skus, item) => {
        const imageUrl = _.get(skus, "image_uber_eats.formats.thumbnail.url", NO_IMAGE);
        const available = _.get(item, "available");
        const missingSKU = skus;
        const archived = _.get(skus, "archived");
        const missingImages =
          _.get(skus, "image_uber_eats.formats") &&
          _.get(skus, "image_doordash.formats") &&
          _.get(skus, "image_grubhub.formats");
        const color = !available ? "black" : !missingSKU || archived ? "red" : missingImages ? "green" : "orange";
        return (
          <Badge color={color} dot={true}>
            <Avatar
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid rgb(235, 237, 240)",
              }}
              src={<Image style={{ height: 35, width: 35 }} src={imageUrl} preview={false} fallback={NO_IMAGE} />}
              shape="circle"
              size="default"
            />
          </Badge>
        );
      },
    },
    {
      title: "item",
      dataIndex: "name",
      key: "name",
      align: "left",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: 100,
      render: (price) => <Tooltip title="Price">{price}</Tooltip>,
    },
  ];

  return (
    <>
      <div className="menu-item-search-div">
        <h2 className="menu-item-table-label">Items</h2>
        <Input
          id="menu-item-search-input"
          placeholder="Search Item"
          onChange={(e) => {
            handleOnChange(e);
          }}
        />
      </div>
      <div className="menu-items-table-wrapper">
        <Table
          size="small"
          className="menu-items-table"
          showHeader={false}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={menuItemStatus === "pending" ? true : false}
          rowClassName={(record, index) => (itemToEdit && record.id === itemToEdit.id ? "highlighted-row" : null)}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => setItemToEdit(record),
            };
          }}
        />
      </div>
    </>
  );
};

export default MenuItemTable;
