import { apiGatewayWithAuth } from "./apiGatewayWithAuth";
import request from "./request";

const URL_BASE_DEVELOPMENT = "https://api-gateway-sandbox-4a2r.onrender.com/api";

const URL_BASE_SANDBOX = "https://api-gateway-sandbox-4a2r.onrender.com/api";

const URL_BASE_PROD = "https://api-gateway-production-m94a.onrender.com/api";

const isLocalhost = window.location.hostname === 'localhost';
const isSandbox = window.location.hostname.includes('sandbox');

export const URL_BASE =
  isLocalhost
    ? URL_BASE_DEVELOPMENT
    : (isSandbox ? URL_BASE_SANDBOX : URL_BASE_PROD);

export const getApiGateway = async (endpoint) => {
  const url = `${URL_BASE}/${endpoint}`;
  return apiGatewayWithAuth(url, {
    method: "GET",
    headers: {},
  });
}

export const postAPIGateway = async (endpoint, body) => {
  const url = `${URL_BASE}/${endpoint}`;
  return request(url, {
    method: "POST",
    headers: {},
    body,
  });
}

export const fetchTargets = async () => {
  const endpoint = "store-groups?isActive=true";
  const response = getApiGateway(endpoint);
  return response;
}

export const login = async (credentials) => {
  const endpoint = "auth/local";
  const response = postAPIGateway(endpoint, credentials);
  return response;
}