import { notification, Typography } from "antd";

import _ from "lodash";

import "./styles.css";

const BadMenuItemList = ({ items }) => {
  const { Text } = Typography;
  return (
    <>
      <Text>The following menu items are linked to invalid SKUs:</Text>
      <div className="list-div">
        <ul style={{ paddingLeft: 10 }}>
          {_.map(items, (item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

export const openNotification = ({ items }) => {
  const args = {
    message: "Warning",
    description: <BadMenuItemList items={items} />,
    duration: 0,
  };
  notification.warning(args);
};
