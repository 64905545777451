import React from "react";
import { Route } from "react-router-dom";
import { logout } from "../../functions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("jwt");
  return <Route {...rest} render={(props) => (token ? <Component {...props} {...rest} /> : logout())} />;
};

export default PrivateRoute;
