import React from "react";

import ModifierTable from "../ModifierTable";
import { ModifierFooter } from "../Footers";

import "./styles.css";

const ModifierComponent = (props) => {
  const { selectedModifier, setSelectedModifier, setModifierModalVisible } = props;
  return (
    <>
      <div className="modifier-container">
        <ModifierTable selectedModifier={selectedModifier} setSelectedModifier={setSelectedModifier} />
      </div>
      <ModifierFooter setModifierModalVisible={setModifierModalVisible} />
    </>
  );
};

export default ModifierComponent;
