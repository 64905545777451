import {
  FETCH_CATEGORY_ITEM_MODIFIERS_START,
  FETCH_CATEGORY_ITEM_MODIFIERS_SUCCESS,
  FETCH_CATEGORY_ITEM_MODIFIERS_FAILURE,
  ADD_CATEGORY_ITEM_MODIFIER_START,
  ADD_CATEGORY_ITEM_MODIFIER_SUCCESS,
  ADD_CATEGORY_ITEM_MODIFIER_FAILURE,
  UPDATE_CATEGORY_ITEM_MODIFIER_START,
  UPDATE_CATEGORY_ITEM_MODIFIER_SUCCESS,
  UPDATE_CATEGORY_ITEM_MODIFIER_FAILURE,
  DELETE_CATEGORY_ITEM_MODIFIER_START,
  DELETE_CATEGORY_ITEM_MODIFIER_SUCCESS,
  DELETE_CATEGORY_ITEM_MODIFIER_FAILURE,
} from "../constants";

const initialState = {
  category_item_modifiers: [],
  status: "idle",
  error: "",
};

const categoryItemModifiersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_ITEM_MODIFIERS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_CATEGORY_ITEM_MODIFIERS_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_item_modifiers: action.payload,
      };
    case FETCH_CATEGORY_ITEM_MODIFIERS_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case ADD_CATEGORY_ITEM_MODIFIER_START:
      return {
        ...state,
        status: "pending",
      };
    case ADD_CATEGORY_ITEM_MODIFIER_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_item_modifiers: [
          ...state.category_item_modifiers,
          action.payload,
        ],
      };
    case ADD_CATEGORY_ITEM_MODIFIER_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case UPDATE_CATEGORY_ITEM_MODIFIER_START:
      return {
        ...state,
        status: "pending",
      };
    case UPDATE_CATEGORY_ITEM_MODIFIER_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_item_modifiers: [
          ...state.category_item_modifiers.slice(
            0,
            state.category_item_modifiers.findIndex(
              (m) => m.id.toString() === action.payload.id.toString()
            )
          ),
          action.payload,
          ...state.category_item_modifiers.slice(
            state.category_item_modifiers.findIndex(
              (m) => m.id.toString() === action.payload.id.toString()
            ) + 1
          ),
        ],
      };
    case UPDATE_CATEGORY_ITEM_MODIFIER_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case DELETE_CATEGORY_ITEM_MODIFIER_START:
      return {
        ...state,
        status: "pending",
      };
    case DELETE_CATEGORY_ITEM_MODIFIER_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_item_modifiers: [
          ...state.category_item_modifiers.slice(
            0,
            state.category_item_modifiers.findIndex(
              (m) => m.id.toString() === action.payload.id.toString()
            )
          ),
          ...state.category_item_modifiers.slice(
            state.category_item_modifiers.findIndex(
              (m) => m.id.toString() === action.payload.id.toString()
            ) + 1
          ),
        ],
      };
    case DELETE_CATEGORY_ITEM_MODIFIER_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default categoryItemModifiersReducer;
