import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Spin, Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getMenu } from "../../actions/menu";
import { getMenuSkus } from "../../actions/menuSkus"
import { getCategories } from "../../actions/categories";
import { getMenuItems } from "../../actions/menuItems";
import { getCategoryItems } from "../../actions/categoryItems";
import { getModifiers } from "../../actions/modifiers";
import { getModifierItems } from "../../actions/modifierItems";
import { getCategoryItemModifiers } from "../../actions/categoryItemModifiers";
import { openNotification } from "../../components/Notification";
import CategoryComponent from "../../components/CategoryComponent";
import CategoryEditor from "../../components/CategoryEditor";
import MenuItemComponent from "../../components/MenuItemComponent";
import MenuItemEditor from "../../components/MenuItemEditor";
import ModifierEditor from "../../components/ModifierEditor";
import ModifierComponent from "../../components/ModifierComponent";
import MenuTargetComponent from "../../components/MenuTargetComponent";
import MenuInternalPromoComponent from "../../components/MenuInternalPromoComponent";
import MenuInternalPromoEditor from "../../components/MenuInternalPromoEditor";
import CategoryItemDrawer from "../../components/CategoryItemDrawer";
import PublishModal from "../../components/Modals/PublishModal";
import AddCategoryModal from "../../components/Modals/AddCategoryModal";
import AddCategoryItemModal from "../../components/Modals/AddCategoryItemModal";
import AddModifierModal from "../../components/Modals/AddModifierModal";
import ModifierItemModal from "../../components/Modals/AddModifierItemModal";
import UpdateTargetsModal from "../../components/Modals/UpdateTargetsModal";
import AddInternalPromoModal from "../../components/Modals/AddInternalPromoModal";
import SkuModal from "../../components/Modals/SkuModal";

import _ from "lodash";

import "./styles.css";
import MenuTargetEditor from "../../components/MenuTargetEditor";

const MenuPage = (props) => {
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [categoryItem, setCategoryItem] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [selectedInternalPromo, setSelectedInternalPromo] = useState(null);
  const [addingItem, setAddingItem] = useState(false);
  const [selectedModifier, setSelectedModifier] = useState(null);
  const [selectedModifierItem, setSelectedModifierItem] = useState(null);
  const [selectedSku, setSelectedSku] = useState(null);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [menuItemModalVisible, setMenuItemModalVisible] = useState(false);
  const [modifierModalVisible, setModifierModalVisible] = useState(false);
  const [modifierItemModalVisible, setModifierItemModalVisible] = useState(false);
  const [targetsModalVisible, setTargetsModalVisible] = useState(false);
  const [internalPromosModalVisible, setInternalPromosModalVisible] = useState(false);
  const [skuModalVisible, setSkuModalVisible] = useState(false);
  const [newModifierItems, setNewModifierItems] = useState(null);

  const {
    menu,
    skus,
    status,
    getMenu,
    getMenuSkus,
    getMenuItems,
    getCategories,
    getCategoryItems,
    getModifiers,
    getModifierItems,
    getCategoryItemModifiers,
    publishModalVisible,
    setPublishModalVisible,
    itemsVisible,
    setItemsVisible,
    setModifierVisible,
  } = props;

  const categories = useSelector((state) => state.categories.categories);

  const categoryId = _.get(categoryToEdit, "id", "");

  const NO_IMAGE = `https://vkc-files.s3.us-west-1.amazonaws.com/noimage_4dcae76445.png`;

  const params = useParams();

  const { TabPane } = Tabs;

  const menuItems = useSelector((state) => state.menu_items.menu_items);

  useEffect(() => {
    const badMenuItems = _.filter(menuItems, (item) => _.get(item, "skus.archived"));
    if (badMenuItems.length > 0) {
      openNotification({ items: badMenuItems });
    }
  }, [menuItems]);

  useEffect(() => {
    if (itemToEdit) {
      setAddingItem(false);
    }
  }, [itemToEdit]);

  useEffect(() => {
    if (menu.id !== params.id) {
      const { id } = params;
      getMenu(id);
      getMenuSkus(id);
      getCategories(id);
      getMenuItems(id);
      getCategoryItems(id);
      getModifiers(id);
      getModifierItems(id);
      getCategoryItemModifiers(id);
    }
  }, [
    params,
    getMenu,
    getMenuSkus,
    getCategories,
    getMenuItems,
    getCategoryItems,
    getModifiers,
    getModifierItems,
    getCategoryItemModifiers,
    menu,
  ]);

  const renderTabBar = (props, DefaultTabBar) => <DefaultTabBar {...props} className="site-custom-tab-bar" />;

  return (
    <>
      <div className="menu-wrapper">
        {status === "pending" ? (
          <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
        ) : (
          <>
            <Tabs
              className="ant-tabs"
              defaultActiveKey="1"
              renderTabBar={renderTabBar}
              tabBarStyle={{ margin: 0, paddingLeft: 10 }}
            >
              <TabPane className="ant-tabs-content" tab="Categories" key="1">
                <div className="left-component-wrapper">
                  <CategoryComponent
                    menu={menu}
                    categories={categories}
                    categoryToEdit={categoryToEdit}
                    setCategoryToEdit={setCategoryToEdit}
                    setCategoryModalVisible={setCategoryModalVisible}
                    NO_IMAGE={NO_IMAGE}
                  />
                </div>
                <div className="right-component-wrapper">
                  {categoryToEdit ? (
                    <CategoryEditor
                      categoryToEdit={categoryToEdit}
                      setCategoryToEdit={setCategoryToEdit}
                      setCategoryItem={setCategoryItem}
                      setItemsVisible={setItemsVisible}
                      setMenuItemModalVisible={setMenuItemModalVisible}
                      setModifierVisible={setModifierVisible}
                      setSelectedModifier={setSelectedModifier}
                      NO_IMAGE={NO_IMAGE}
                    />
                  ) : (
                    <div className="label-wrapper">
                      <h3>Select a category to edit details</h3>
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane className="ant-tabs-content" tab="Items" key="2">
                <div className="left-component-wrapper">
                  <MenuItemComponent
                    itemToEdit={itemToEdit}
                    setItemToEdit={setItemToEdit}
                    setAddingItem={setAddingItem}
                    NO_IMAGE={NO_IMAGE}
                  />
                </div>
                <div className="right-component-wrapper">
                  {itemToEdit || addingItem ? (
                    <MenuItemEditor
                      itemToEdit={itemToEdit}
                      setItemToEdit={setItemToEdit}
                      addingItem={addingItem}
                      setAddingItem={setAddingItem}
                      NO_IMAGE={NO_IMAGE}
                    />
                  ) : (
                    <div className="label-wrapper">
                      <h3>Select an item to edit details</h3>
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane className="ant-tabs-content" tab="Modifier Groups" key="3">
                <div className="left-component-wrapper">
                  <ModifierComponent
                    selectedModifier={selectedModifier}
                    setSelectedModifier={setSelectedModifier}
                    setModifierModalVisible={setModifierModalVisible}
                  />
                </div>
                <div className="right-component-wrapper">
                  {selectedModifier ? (
                    <ModifierEditor
                      selectedModifier={selectedModifier}
                      setSelectedModifier={setSelectedModifier}
                      selectedModifierItem={selectedModifierItem}
                      setSelectedModifierItem={setSelectedModifierItem}
                      setModifierItemModalVisible={setModifierItemModalVisible}
                      newModifierItems={newModifierItems}
                      setNewModifierItems={setNewModifierItems}
                      modifierItemModalVisible={modifierItemModalVisible}
                    />
                  ) : (
                    <div className="label-wrapper">
                      <h3>Select a modifier to edit details</h3>
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane className="ant-tabs-content" tab="Targets" key="5">
                <div className="left-component-wrapper">
                  <MenuTargetComponent
                    selectedTarget={selectedTarget}
                    setSelectedTarget={setSelectedTarget}
                    setTargetsModalVisible={setTargetsModalVisible}
                  />
                </div>
                <div className="right-component-wrapper">
                  {selectedTarget ? (
                    <MenuTargetEditor
                      selectedTarget={selectedTarget}
                      setSelectedTarget={setSelectedTarget}
                    />
                  ) : (
                    <div className="label-wrapper">
                      <h3>Select a target to edit details</h3>
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane className="ant-tabs-content" tab="Internal Promos" key="6">
                <div className="left-component-wrapper">
                  <MenuInternalPromoComponent
                    selectedInternalPromo={selectedInternalPromo}
                    setSelectedInternalPromo={setSelectedInternalPromo}
                    setInternalPromosModalVisible={setInternalPromosModalVisible}
                  />
                </div>
                <div className="right-component-wrapper">
                  {selectedInternalPromo ? (
                    <MenuInternalPromoEditor
                      selectedInternalPromo={selectedInternalPromo}
                      setSelectedInternalPromo={setSelectedInternalPromo}
                    />
                  ) : (
                     <div className="label-wrapper">
                       <h3>Select a internal promo to view details</h3>
                     </div>
                   )}
                </div>
              </TabPane>
            </Tabs>
            <CategoryItemDrawer
              itemsVisible={itemsVisible}
              setItemsVisible={setItemsVisible}
              categoryItem={categoryItem}
              setCategoryItem={setCategoryItem}
              categories={categories}
              categoryId={categoryId}
              categoryToEdit={categoryToEdit}
              NO_IMAGE={NO_IMAGE}
            />
            <PublishModal publishModalVisible={publishModalVisible} setPublishModalVisible={setPublishModalVisible} />
            <AddCategoryModal
              categoryModalVisible={categoryModalVisible}
              setCategoryModalVisible={setCategoryModalVisible}
            />
            <AddCategoryItemModal
              categoryToEdit={categoryToEdit}
              menuItemModalVisible={menuItemModalVisible}
              setMenuItemModalVisible={setMenuItemModalVisible}
            />
            <AddModifierModal
              modifierModalVisible={modifierModalVisible}
              setModifierModalVisible={setModifierModalVisible}
            />
            <ModifierItemModal
              selectedModifier={selectedModifier}
              setSelectedModifierItem={setSelectedModifierItem}
              modifierItemModalVisible={modifierItemModalVisible}
              setModifierItemModalVisible={setModifierItemModalVisible}
              newModifierItems={newModifierItems}
            />
            <UpdateTargetsModal
              targetsModalVisible={targetsModalVisible}
              setTargetsModalVisible={setTargetsModalVisible}
            />
            <AddInternalPromoModal
              internalPromosModalVisible={internalPromosModalVisible}
              setInternalPromosModalVisible={setInternalPromosModalVisible}
            />
            <SkuModal
              skus={skus}
              skuModalVisible={skuModalVisible}
              setSkuModalVisible={setSkuModalVisible}
              selectedSku={selectedSku}
              setSelectedSku={setSelectedSku}
            />
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu.menu,
    status: state.menu.status,
    skus: state.skus.skus,
  };
};

export default connect(mapStateToProps, {
  getMenu,
  getMenuSkus,
  getCategories,
  getMenuItems,
  getCategoryItems,
  getModifiers,
  getModifierItems,
  getCategoryItemModifiers,
})(MenuPage);
