import {
  FETCH_MODIFIERS_START,
  FETCH_MODIFIERS_SUCCESS,
  FETCH_MODIFIERS_FAILURE,
  ADD_MODIFIER_START,
  ADD_MODIFIER_SUCCESS,
  ADD_MODIFIER_FAILURE,
  UPDATE_MODIFIER_START,
  UPDATE_MODIFIER_SUCCESS,
  UPDATE_MODIFIER_FAILURE,
  DELETE_MODIFIER_START,
  DELETE_MODIFIER_SUCCESS,
  DELETE_MODIFIER_FAILURE,
} from "../constants";

const initialState = {
  modifiers: [],
  status: "idle",
  error: "",
};

const modifiersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MODIFIERS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_MODIFIERS_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        modifiers: action.payload,
      };
    case FETCH_MODIFIERS_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case ADD_MODIFIER_START:
      return {
        ...state,
        status: "pending",
      };
    case ADD_MODIFIER_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        modifiers: [...state.modifiers, action.payload],
      };
    case ADD_MODIFIER_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case UPDATE_MODIFIER_START:
      return {
        ...state,
        status: "pending",
      };
    case UPDATE_MODIFIER_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        modifiers: [
          ...state.modifiers.slice(
            0,
            state.modifiers.findIndex((m) => m.id.toString() === action.payload.id.toString())
          ),
          action.payload,
          ...state.modifiers.slice(
            state.modifiers.findIndex((m) => m.id.toString() === action.payload.id.toString()) + 1
          ),
        ],
      };
    case UPDATE_MODIFIER_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case DELETE_MODIFIER_START:
      return {
        ...state,
        status: "pending",
      };
    case DELETE_MODIFIER_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        modifiers: [
          ...state.modifiers.slice(
            0,
            state.modifiers.findIndex((m) => m.id.toString() === action.payload.id.toString())
          ),
          ...state.modifiers.slice(
            state.modifiers.findIndex((m) => m.id.toString() === action.payload.id.toString()) + 1
          ),
        ],
      };
    case DELETE_MODIFIER_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default modifiersReducer;
