import React, { useState } from "react";
import { useSelector, connect } from "react-redux";
import { Checkbox, Divider, Row, Col, Typography } from "antd";

import _ from "lodash";

import "./styles.css";

const MenuTargetCategoryPicker = (props) => {
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const { selectedTarget, checkedList, setCheckedList, setDisabled } = props;

  const { Text } = Typography;

  const categories = useSelector((state) => state.categories.categories);

  const plainOptions = _.map(categories, (c) => c.id);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    setDisabled(false);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setDisabled(false);
  };

  return (
    <div className="target-category-picker-wrapper">
      <div className="target-category-picker-title">
        <Text strong={true}>
          Select categories you would like to exclude from {_.get(selectedTarget, "name")}
        </Text>
      </div>
      <div className="target-check-all">
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Check all
        </Checkbox>
      </div>
      <Divider />
      <Checkbox.Group style={{ width: "100%", textAlign: "left" }} value={checkedList} onChange={onChange}>
        <Row>
          {_.map(categories, (c) => (
            <Col key={c.id} span={12}>
              <Checkbox key={c.id} value={c.id}>
                {_.get(c, "name")}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default connect(null, null)(MenuTargetCategoryPicker);
