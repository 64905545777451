import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./styles.css";

export const SearchInput = (props) => {
  const { onInputChange } = props;

  return (
    <Input
      allowClear
      style={{ width: 300 }}
      className="search-input"
      placeholder="Search for a Menu"
      suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
      onChange={(e) => onInputChange(e)}
    />
  );
};
