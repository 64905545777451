import { login } from "./apiGateway";
import request from "./request";

const API_GATEWAY_IDENTIFIER = process.env.REACT_APP_API_GATEWAY_IDENTIFIER;
const API_GATEWAY_PASSWORD = process.env.REACT_APP_API_GATEWAY_PASSWORD;

const API_GATEWAY_JWT_KEY = "jwtAPIGateway";

const getToken = async () => {
  const token = localStorage.getItem(API_GATEWAY_JWT_KEY);

  if (token) {
    return token;
  }
  const authResult = await login({
    identifier: API_GATEWAY_IDENTIFIER,
    password: API_GATEWAY_PASSWORD,
  });

  if (authResult?.jwt) {
    localStorage.setItem(API_GATEWAY_JWT_KEY, authResult.jwt);
    return authResult.jwt;
  }
}

export const apiGatewayWithAuth = async (url, data) => {
  const token = await getToken();
  return request(url, {
    ...data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
