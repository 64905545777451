import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Form, Row, Col, TimePicker, Typography, Button, Collapse, DatePicker } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { EditingComponentFooter } from "../Footers";
import MenuTargetCategoryPicker from "../MenuTargetCategoryPicker";

import { updateTargets } from "../../actions/targets";

import _ from "lodash";
import moment from "moment";

import "./styles.css";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const MenuTargetEditor = props => {
  const [checkedList, setCheckedList] = useState([]);
  const [dayOfWeekHours, setDayOfWeekHours] = useState({});
  const [closedDates, setClosedDates] = useState([]);
  const [closedDateStrings, setClosedDateStrings] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const dayOfWeekKeys = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const { selectedTarget, setSelectedTarget, updateTargets } = props;

  const [form] = Form.useForm();

  const { List } = Form;

  const { RangePicker } = TimePicker;
  const { RangePicker: HolidayRangePicker  } = DatePicker;

  const { Text } = Typography;

  const assignedTargets = useSelector(state => state.menu.menu.targets);

  const menu = useSelector(state => state.menu.menu);

  useEffect(() => {
    let { hours, dayOfWeekHours, closedDates = [] } = selectedTarget;

    if (!hours) {
      hours = [["11:00", "2:00"]];
    }

    if (!dayOfWeekHours) {
      dayOfWeekHours = {
        mon: hours,
        tue: hours,
        wed: hours,
        thu: hours,
        fri: hours,
        sat: hours,
        sun: hours,
      };

      setDisabled(false);
    }
    setDayOfWeekHours(dayOfWeekHours);
    
    if (closedDates.length > 0) {
      closedDates = closedDates.map(date => moment(date, 'YYYY-MM-DD'));
      setClosedDates([closedDates[0], closedDates[closedDates.length - 1]]);
    } else {
      setClosedDates([]);
    }
  }, [selectedTarget]);

  useEffect(() => {
    const disabledCategories = _.get(selectedTarget, "categories_disabled");
    if (disabledCategories.length > 0) {
      setCheckedList(disabledCategories);
    } else {
      setCheckedList([]);
    }
  }, [selectedTarget]);

  useEffect(() => {
    form.setFieldsValue({
      "mon-hours": dayOfWeekHours.mon,
      "tue-hours": dayOfWeekHours.tue,
      "wed-hours": dayOfWeekHours.wed,
      "thu-hours": dayOfWeekHours.thu,
      "fri-hours": dayOfWeekHours.fri,
      "sat-hours": dayOfWeekHours.sat,
      "sun-hours": dayOfWeekHours.sun,
    });
  }, [form, dayOfWeekHours]);

  const onHolidayClosuresChange = (dates = [], dateStrings) => {
    const datesToSave = dates || [];

    setClosedDates(datesToSave);
    
    const dateArray = [];
    if (datesToSave.length > 0) {
      const startMoment = moment(dateStrings[0], 'YYYY-MM-DD');
      const endMoment = moment(dateStrings[1], 'YYYY-MM-DD');
      while (startMoment.isSameOrBefore(endMoment, 'day')) {
        dateArray.push(startMoment.format('YYYY-MM-DD'));
        startMoment.add(1, 'day');
      }
    }
    setClosedDateStrings(dateArray);
    setDisabled(false);
  }

  const onHoursChange = (day, time, timeString, index) => {
    const dayOfWeekHoursCopy = { ...dayOfWeekHours };

    const newTime = [
      moment(timeString[0], "h:mm A").format("HH:mm"),
      moment(timeString[1], "h:mm A").format("HH:mm"),
    ];
    if (dayOfWeekHoursCopy && dayOfWeekHoursCopy[day] && dayOfWeekHoursCopy[day][index]) {
      dayOfWeekHoursCopy[day][index] = newTime;
      setDayOfWeekHours(dayOfWeekHoursCopy);
    } else if (dayOfWeekHoursCopy && dayOfWeekHoursCopy[day]) {
      dayOfWeekHoursCopy[day] = [...dayOfWeekHoursCopy[day], newTime];
      setDayOfWeekHours(dayOfWeekHoursCopy);
    } else {
      dayOfWeekHoursCopy[day] = [newTime];
      setDayOfWeekHours(dayOfWeekHoursCopy);
    }
    setDisabled(false);
  };

  const onFinish = () => {
    const { id, name, locationName } = selectedTarget;
    const newDaysOfWeekHours =
      dayOfWeekHours && dayOfWeekHours.length === 0 ? null : dayOfWeekHours;
    const existingTargets = _.filter(_.defaultTo(assignedTargets, []), t => t.id !== id);
    const body = [
      ...existingTargets,
      {
        id,
        name,
        locationName,
        dayOfWeekHours: newDaysOfWeekHours,
        closedDates: closedDateStrings,
        categories_disabled: checkedList,
      },
    ];
    updateTargets(menu.id, body, setDisabled(true));
  };

  const getDayOfWeekFromKey = key => {
    const mapping = {
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    };

    return mapping[key];
  };

  return (
    <>
      <div className="target-editing-component-bottom-container">
        <h2 className="editing-component-label">
          <em>{_.get(selectedTarget, "name")}</em>
        </h2>
        <Form
          form={form}
          name="menuTargetForm"
          className="target-form"
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={onFinish}>
          <Collapse ghost={true}>
            <CollapsePanel
              header={<Text className="pannel-header-text">Hours</Text>}
              className="pannel-header">
              {dayOfWeekKeys.map(day => (
                <Row>
                  <Col className="day-col">{getDayOfWeekFromKey(day)}</Col>
                  <Col className="time-picker-col">
                    <List name={`${day}-hours`}>
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Row key={field.key}>
                              <Col className="hours-col">
                                <Form.Item>
                                  <RangePicker
                                    use12Hours
                                    format="h:mm A"
                                    minuteStep={15}
                                    order={false}
                                    allowClear={false}
                                    onChange={(time, timeString) =>
                                      onHoursChange(day, time, timeString, index)
                                    }
                                    value={
                                      dayOfWeekHours &&
                                      dayOfWeekHours[day] &&
                                      dayOfWeekHours[day][index]
                                        ? [
                                            moment(dayOfWeekHours[day][index][0], "h:mm A"),
                                            moment(dayOfWeekHours[day][index][1], "h:mm A"),
                                          ]
                                        : null
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col>
                                {index > 0 ? (
                                  <Button
                                    id="remove-button"
                                    size="small"
                                    type="danger"
                                    icon={<MinusOutlined id="minus-outlined" />}
                                    onClick={() => {
                                      remove(field.name);
                                      const dayOfWeekHoursCopy = { ...dayOfWeekHours };
                                      dayOfWeekHoursCopy[day] = dayOfWeekHoursCopy[day]
                                        ? [
                                            ...dayOfWeekHours[day].slice(0, index),
                                            ...dayOfWeekHours[day].slice(index + 1),
                                          ]
                                        : [];
                                      setDayOfWeekHours(dayOfWeekHoursCopy);
                                    }}
                                  />
                                ) : (
                                  <Button
                                    id="add-button"
                                    size="small"
                                    icon={<PlusOutlined id="plus-outlined" />}
                                    onClick={() => add()}
                                  />
                                )}
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </List>
                  </Col>
                </Row>
              ))}
            </CollapsePanel>
          </Collapse>
        </Form>
        <Collapse ghost={true}>
          <CollapsePanel
            header={<Text className="pannel-header-text">Inactive Categories</Text>}
            className="pannel-header">
            <MenuTargetCategoryPicker
              selectedTarget={selectedTarget}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              setDisabled={setDisabled}
            />
          </CollapsePanel>
        </Collapse>
        <Collapse ghost={true}>
          <CollapsePanel
            header={<Text className="pannel-header-text">Holiday Closures</Text>}
            className="pannel-header">
              <div>The store will be closed for the duration of the date range that you select.</div>
              <br />
              <HolidayRangePicker
                allowClear={true}
                format={'YYYY-MM-DD'}
                value={closedDates || null}
                onChange={(dates, dateStrings) => onHolidayClosuresChange(dates, dateStrings)}
              />
          </CollapsePanel>
        </Collapse>
      </div>
      <EditingComponentFooter
        form={form}
        selectedTarget={selectedTarget}
        setSelectedTarget={setSelectedTarget}
        disabled={disabled}
      />
    </>
  );
};

export default connect(null, { updateTargets })(MenuTargetEditor);
