import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Form, Row, Col, Input, InputNumber, Tooltip, Button, Upload, Image, Progress, Divider } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { setMenuItemValues, setSkuValuesFunc, overrideValues } from "../../helpers";
import { addMenuItem, updateMenuItem, deleteMenuItem } from "../../actions/menuItems";
import { deleteCategoryItem } from "../../actions/categoryItems";
import {
  uploadMediaFile,
  updateUberEatsSkuImage,
  updateDoorDashSkuImage,
  updateGrubHubSkuImage,
} from "../../actions/images";

import SkuModal from "../Modals/SkuModal";
import { EditingComponentFooter } from "../Footers";

import _ from "lodash";

import "./styles.css";

const MenuItemEditor = (props) => {
  const [selectedSku, setSelectedSku] = useState(null);
  const [sku, setSku] = useState(null);
  const [skuValues, setSkuValues] = useState(null);
  const [available, setAvailable] = useState(true);
  const [skuModalVisible, setSkuModalVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [percent, setPercent] = useState(0);

  const {
    itemToEdit,
    setItemToEdit,
    addingItem,
    setAddingItem,
    NO_IMAGE,
    addMenuItem,
    updateMenuItem,
    // uploadMediaFile,
    updateUberEatsSkuImage,
    updateDoorDashSkuImage,
    updateGrubHubSkuImage,
  } = props;

  const [form] = Form.useForm();

  const { Dragger } = Upload;

  const menu = useSelector((state) => state.menu.menu);

  const skus = useSelector((state) => state.skus.skus);

  const skuId = sku ? sku.id : _.get(itemToEdit, "skus.id", "");

  const uberEatsImageStatus = useSelector((state) => state.skus.ubereats_status);

  const doorDashImageStatus = useSelector((state) => state.skus.doordash_status);

  const grubHubImageStatus = useSelector((state) => state.skus.grubhub_status);

  useEffect(() => {
    if (itemToEdit) {
      const initialFormValues = setMenuItemValues(itemToEdit);
      const s = skus.find((s) => s.id === _.get(itemToEdit, "skus.id"));
      const values = setSkuValuesFunc([[s], NO_IMAGE]);
      setSkuValues(values[0]);
      setAvailable(itemToEdit.available);
      form.setFieldsValue(initialFormValues[0]);
      return () => {
        setSku(null);
        setSelectedSku(null);
        setSkuValues(null);
        setDisabled(true);
        setAvailable(true);
        form.resetFields();
      };
    }
  }, [itemToEdit, skus, form, NO_IMAGE]);

  useEffect(() => {
    if (sku) {
      const s = skus.find((s) => s.id === sku.id);
      const values = setSkuValuesFunc([[s], NO_IMAGE]);
      const skuFormValuesOverride = overrideValues(s);
      form.setFieldsValue(skuFormValuesOverride[0]);
      setDisabled(false);
      setSkuValues(values[0]);
    }
  }, [sku, skus, form, NO_IMAGE]);

  useEffect(() => {
    if (addingItem) {
      setItemToEdit(null);
      setSkuValues(null);
      setAvailable(true);
      form.resetFields();
    }
  }, [addingItem, setItemToEdit, form]);

  const handleImageUpload = (file, platformName) => {
    const data = new FormData();
    data.append("files", file);
    data.append("ref", "sku");
    data.append("refId", skuId);
    data.append("field", `image_${platformName}`);
    if (platformName === "uber_eats") {
      updateUberEatsSkuImage(data, setPercent);
    } else if (platformName === "doordash") {
      updateDoorDashSkuImage(data, setPercent);
    } else {
      updateGrubHubSkuImage(data, setPercent);
    }
  };

  const handleOnFormSubmit = (values) => {
    const { name, sku, price, description } = values;
    const menuId = menu.id;
    const body = {
      menu: menuId,
      skus: sku,
      available: available,
      name: name,
      price: price,
      description: description,
    };
    if (addingItem) {
      addMenuItem(body);
    } else {
      const { id } = itemToEdit;
      updateMenuItem(id, body);
    }
  };

  return (
    <>
      <div className="item-editing-component-bottom-container">
        <h2 className="editing-component-label">
          <em>{_.get(itemToEdit, "name", "New Item")}</em>
        </h2>
        <Form
          form={form}
          className="menu-item-form"
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={(values) => handleOnFormSubmit(values)}
          scrollToFirstError={true}
        >
          <Row gutter={24}>
            <Col span={12} style={{ textAlign: "left" }}>
              <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter item name" }]}>
                <Input placeholder="Please enter item name" />
              </Form.Item>
            </Col>
            <Col span={8} style={{ textAlign: "left" }}>
              <Form.Item name="sku" label="Sku" rules={[{ required: true, message: "Please select sku" }]}>
                <Tooltip
                  title={
                    _.get(itemToEdit, "skus", "") || sku ? (
                      <>
                        {_.get(skuValues, "name", "")} <br />
                        {_.get(skuValues, "id", "")}
                      </>
                    ) : null
                  }
                >
                  <Button
                    type="link"
                    onClick={() => setSkuModalVisible(true)}
                    style={{
                      paddingLeft: 8,
                    }}
                  >
                    {_.get(itemToEdit, "skus", "") || sku ? (
                      <div id="sku-div">
                        {_.get(skuValues, "name", "")}
                        <Divider className="divider" type="vertical" />
                        {_.get(skuValues, "owner", "")
                          ? _.get(skuValues, "owner", "")
                          : _.get(
                              _.find(skus, (sku) => _.get(sku, "owner.id") === _.get(itemToEdit, "skus.owner", "")),
                              "owner.name",
                              ""
                            )}
                      </div>
                    ) : (
                      <span>Search Sku</span>
                    )}
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "left" }}>
              <Form.Item name="price" label="Price" rules={[{ required: true, message: "Please enter price" }]}>
                <InputNumber min={0} step={0.01} />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: 8 }}>
            <span>Images</span>
          </Row>
          <Row gutter={16} className="image-row">
            <Col span={8} className="image-column">
              <Form.Item name="uber_image">
                <Dragger
                  name="file"
                  disabled={sku || itemToEdit ? false : true}
                  multiple={false}
                  showUploadList={false}
                  onChange={() => setDisabled(false)}
                  action={(file) => handleImageUpload(file, "uber_eats")}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <Tooltip
                    title={sku || itemToEdit ? null : "To upload an image you must first select a SKU"}
                    placement="top"
                  >
                    <div id="image-div">
                      {uberEatsImageStatus === "pending" ? (
                        <Progress id="progress-bar" percent={percent} size="small" status="active" />
                      ) : (
                        <Image
                          id="item-image"
                          src={_.get(skuValues, "ubereats.url", "")}
                          alt="uber eats"
                          fallback={NO_IMAGE}
                          preview={false}
                        />
                      )}
                    </div>
                  </Tooltip>
                </Dragger>
              </Form.Item>
              <Tooltip
                title={sku || itemToEdit ? null : "To upload an image you must first select a SKU"}
                placement="top"
              >
                <div id="label-upload-div">
                  <h4 id="image-label">UberEats</h4>
                  <Upload
                    name="file"
                    disabled={sku || itemToEdit ? false : true}
                    showUploadList={false}
                    action={(file) => handleImageUpload(file, "uber_eats")}
                    onChange={() => setDisabled(false)}
                    multiple={false}
                  >
                    <Button type="link" icon={<UploadOutlined />} />
                  </Upload>
                </div>
              </Tooltip>
            </Col>
            <Col span={8} className="image-column">
              <Form.Item name="doordash_image">
                <Dragger
                  name="file"
                  disabled={sku || itemToEdit ? false : true}
                  multiple={false}
                  showUploadList={false}
                  onChange={() => setDisabled(false)}
                  action={(file) => handleImageUpload(file, "doordash")}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <Tooltip
                    title={sku || itemToEdit ? null : "To upload an image you must first select a SKU"}
                    placement="top"
                  >
                    <div id="image-div">
                      {doorDashImageStatus === "pending" ? (
                        <Progress id="progress-bar" percent={percent} size="small" status="active" />
                      ) : (
                        <Image
                          id="item-image"
                          src={_.get(skuValues, "doordash.url", "")}
                          alt="doordash"
                          fallback={NO_IMAGE}
                          preview={false}
                        />
                      )}
                    </div>
                  </Tooltip>
                </Dragger>
              </Form.Item>
              <Tooltip
                title={sku || itemToEdit ? null : "To upload an image you must first select a SKU"}
                placement="top"
              >
                <div id="label-upload-div">
                  <h4 id="image-label">DoorDash</h4>
                  <Upload
                    name="file"
                    disabled={sku || itemToEdit ? false : true}
                    showUploadList={false}
                    action={(file) => handleImageUpload(file, "doordash")}
                    onChange={() => setDisabled(false)}
                    multiple={false}
                  >
                    <Button type="link" icon={<UploadOutlined />} />
                  </Upload>
                </div>
              </Tooltip>
            </Col>
            <Col span={8} className="image-column">
              <Form.Item name="grubhub_image">
                <Dragger
                  name="file"
                  disabled={sku || itemToEdit ? false : true}
                  multiple={false}
                  showUploadList={false}
                  onChange={() => setDisabled(false)}
                  action={(file) => handleImageUpload(file, "grubhub")}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <Tooltip
                    title={sku || itemToEdit ? null : "To upload an image you must first select a SKU"}
                    placement="top"
                  >
                    <div id="image-div">
                      {grubHubImageStatus === "pending" ? (
                        <Progress id="progress-bar" percent={percent} size="small" status="active" />
                      ) : (
                        <Image
                          id="item-image"
                          src={_.get(skuValues, "grubhub.url", "")}
                          alt="grubhub"
                          fallback={NO_IMAGE}
                          preview={false}
                        />
                      )}
                    </div>
                  </Tooltip>
                </Dragger>
              </Form.Item>
              <Tooltip
                title={sku || itemToEdit ? null : "To upload an image you must first select a SKU"}
                placement="top"
              >
                <div id="label-upload-div">
                  <h4 id="image-label">GrubHub</h4>
                  <Upload
                    name="file"
                    disabled={sku || itemToEdit ? false : true}
                    showUploadList={false}
                    action={(file) => handleImageUpload(file, "grubhub")}
                    onChange={() => setDisabled(false)}
                    multiple={false}
                  >
                    <Button type="link" icon={<UploadOutlined />} />
                  </Upload>
                </div>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea rows={4} showCount maxLength={500} placeholder="please enter item description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <SkuModal
        skuModalVisible={skuModalVisible}
        setSkuModalVisible={setSkuModalVisible}
        selectedSku={selectedSku}
        setSelectedSku={setSelectedSku}
        setSku={setSku}
        itemToEdit={itemToEdit}
      />
      <EditingComponentFooter
        form={form}
        itemToEdit={itemToEdit}
        setItemToEdit={setItemToEdit}
        addingItem={addingItem}
        setAddingItem={setAddingItem}
        disabled={disabled}
      />
    </>
  );
};

export default connect(null, {
  addMenuItem,
  updateMenuItem,
  deleteMenuItem,
  deleteCategoryItem,
  uploadMediaFile,
  updateUberEatsSkuImage,
  updateDoorDashSkuImage,
  updateGrubHubSkuImage,
})(MenuItemEditor);
