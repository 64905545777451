import {
  FETCH_MENUS_START,
  FETCH_MENUS_SUCCESS,
  FETCH_MENUS_FAILURE,
  ADD_MENU_START,
  ADD_MENU_SUCCESS,
  ADD_MENU_FAILURE,
  DELETE_MENU_START,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAILURE,
  CLONE_MENU_START,
  CLONE_MENU_SUCCESS,
  CLONE_MENU_FAILURE,
  MESSAGE_DURATION,
} from "../../constants";

import * as StrapiApi from "../../apis/strapi";

import { handleError } from "../../functions";

import { message } from "antd";

window.StrapiApi = StrapiApi;

export const getMenus = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_MENUS_START });
    const response = StrapiApi.getMenus();
    response
      .then((res) => {
        dispatch({ type: FETCH_MENUS_SUCCESS, payload: res.data.menus });
      })
      .catch((err) => {
        dispatch({ type: FETCH_MENUS_FAILURE, payload: err });
      });
  };
};

export const addMenu = (body, history) => {
  return (dispatch) => {
    dispatch({ type: ADD_MENU_START });
    const response = StrapiApi.createStrapi("menus", body);
    response
      .then((res) => {
        dispatch({ type: ADD_MENU_SUCCESS, payload: res });
        const { id } = res;
        message.success("Menu successfully created!");
        history.push(`/menu/${id}`);
      })
      .catch((err) => {
        dispatch({ type: ADD_MENU_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const deleteMenu = (id, history) => {
  return (dispatch) => {
    dispatch({ type: DELETE_MENU_START });
    const response = StrapiApi.deleteStrapi(`menus/${id}`);
    response
      .then((res) => {
        dispatch({ type: DELETE_MENU_SUCCESS, payload: res });
        message.success("Menu successfully deleted!");
        history.push("/");
      })
      .catch((err) => {
        dispatch({ type: DELETE_MENU_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const cloneMenu = (id, title, brandId, history) => {
  return (dispatch) => {
    dispatch({ type: CLONE_MENU_START });
    const response = StrapiApi.duplicateMenu(id, title, brandId);
    response
      .then((res) => {
        if (res.errors && res.errors.length > 0) {
          const err = res.errors[0];
          const msg = err.message;
          dispatch({ type: CLONE_MENU_FAILURE, payload: err });
          message.error(msg, MESSAGE_DURATION);
        } else {
          dispatch({ type: CLONE_MENU_SUCCESS, payload: res.data });
          const { id } = res.data.duplicateMenu;
          message.success("Menu successfully cloned!");
          history.push(`/menu/${id}`);
        }
      })
      .catch((err) => {
        dispatch({ type: CLONE_MENU_FAILURE, payload: err.message });
        message.error("Oops, something went wrong!");
      });
  };
};
