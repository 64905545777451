import React from "react";

import MenuTargetTable from "../MenuTargetTable";
import { TargetsFooter } from "../Footers";

import "./styles.css";

const TargetComponent = (props) => {
  const { setTargetsModalVisible, selectedTarget, setSelectedTarget } = props;
  return (
    <>
      <div className="targets-container">
        <MenuTargetTable setSelectedTarget={setSelectedTarget} selectedTarget={selectedTarget} />
      </div>
      <TargetsFooter setTargetsModalVisible={setTargetsModalVisible} />
    </>
  );
};

export default TargetComponent;