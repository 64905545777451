import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Drawer, Space, Button, Typography, Popconfirm, Divider, Tooltip } from "antd";

import { deleteCategoryItemModifier } from "../../actions/categoryItemModifiers";

import _ from "lodash";

import "./styles.css";

const CategoryItemModifierDrawer = (props) => {
  const [newModifierItems, setNewModifierItems] = useState(null);
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);

  const {
    selectedModifier,
    setSelectedModifier,
    modifierDrawerVisible,
    setModifierDrawerVisible,
    skus,
    deleteCategoryItemModifier,
  } = props;

  const { Title, Text, Paragraph } = Typography;

  const menuItems = useSelector((state) => state.menu_items.menu_items);

  const modifiers = useSelector((state) => state.modifiers.modifiers);

  const modifierItems = useSelector((state) => state.modifier_items.modifier_items);

  useEffect(() => {
    if (selectedModifier) {
      const getModifierItems = (modifierItems) => {
        const modItems = _.filter(
          modifierItems,
          (i) => _.get(i, "modifier.id") === _.get(selectedModifier, "modifier.id")
        );
        const strToNum = _.map(modItems, (i) => ({ ...i, sort: i.sort ? parseInt(i.sort, 10) : i.sort }));
        const newModItems = _.map(strToNum, (item) => ({
          ...item,
          menu_item: {
            ...menuItems.find((i) => i.id === _.get(item, "menu_item.id")),
            skus: skus.find((sku) => sku.id === menuItems.find((i) => i.id === _.get(item, "menu_item.id")).skus.id),
          },
        }));
        const sortedArr = newModItems.sort((a, b) => a.sort - b.sort);
        setNewModifierItems(sortedArr);
      };
      getModifierItems(modifierItems);
    }
  }, [selectedModifier, modifierItems, menuItems, modifiers, skus]);

  const showDeletePopconfirm = () => {
    setDeletePopConfirmVisible(true);
  };

  const handleDeletePopConfirmOk = () => {
    const { id } = selectedModifier;
    setSelectedModifier(null);
    deleteCategoryItemModifier(id);
    setDeletePopConfirmVisible(false);
    setModifierDrawerVisible(false);
  };

  const handleDeletePopConfirmCancel = () => {
    setDeletePopConfirmVisible(false);
  };

  const handleOnDrawerClose = () => {
    setSelectedModifier(null);
    setModifierDrawerVisible(false);
  };

  return (
    <Drawer
      className="modifier-drawer"
      title={_.get(selectedModifier, "name")}
      width={"60%"}
      placement="right"
      visible={modifierDrawerVisible}
      onClose={() => handleOnDrawerClose()}
      zIndex={1}
      bodyStyle={{ paddingTop: 0, paddingBottom: 10 }}
      destroyOnClose={true}
      closable={true}
      maskClosable={true}
      footerStyle={{ textAlign: "right" }}
      footer={<Button onClick={() => handleOnDrawerClose()}>Close</Button>}
    >
      <div className="remove-div">
        <Popconfirm
          title="Are you sure you want to remove this modifier?"
          visible={deletePopConfirmVisible}
          onConfirm={handleDeletePopConfirmOk}
          onCancel={handleDeletePopConfirmCancel}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" onClick={() => showDeletePopconfirm()}>
            Remove Modifier
          </Button>
        </Popconfirm>
      </div>
      <div className="title-div">
        <Title level={4}>Name: {_.get(selectedModifier, "name")}</Title>
      </div>
      <div className="min-max-div">
        <Space size="large">
          <Text strong={true}>
            Min Allowed: <Text code>{_.get(selectedModifier, "min_allowed")}</Text>
          </Text>
          <Text strong={true}>
            Max Allowed: <Text code>{_.get(selectedModifier, "max_allowed")}</Text>
          </Text>
        </Space>
      </div>
      <div className="table-div">
        <h2 className="label">Available Options</h2>
        <Paragraph strong={true} style={{ lineHeight: 3 }}>
          <ul>
            {_.map(newModifierItems, (item) => (
              <li className="list" key={item.id}>
                <Space>
                  <Tooltip
                    title={
                      _.get(item, "name_override")
                        ? item.name_override
                        : _.get(item, "menu_item.name")
                        ? item.menu_item.name
                        : null
                    }
                  >
                    <Text ellipsis={true} style={{ width: 250 }}>
                      {_.get(item, "name_override")
                        ? item.name_override
                        : _.get(item, "menu_item.name")
                        ? item.menu_item.name
                        : null}
                    </Text>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip
                    title={
                      <>
                        {_.get(item, "menu_item.skus.name")} <br /> {_.get(item, "menu_item.skus.sku_id")}{" "}
                      </>
                    }
                  >
                    <Text ellipsis={true} style={{ width: 100 }}>
                      {_.get(item, "menu_item.skus.owner.name")}
                    </Text>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="price">
                    {_.get(item, "price_override") || _.get(item, "price_override") === 0
                      ? item.price_override
                      : _.get(item, "menu_item.price")
                      ? item.menu_item.price
                      : null}
                  </Tooltip>
                </Space>
              </li>
            ))}
          </ul>
        </Paragraph>
      </div>
    </Drawer>
  );
};

export default connect(null, {
  deleteCategoryItemModifier,
})(CategoryItemModifierDrawer);
