import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { Badge, Image, Avatar, message, Tooltip } from "antd";
import SortableTable from "../SortableTable";

import { getCategoryItems } from "../../actions/categoryItems";

import _ from "lodash";
import * as StrapiApi from "../../apis/strapi";

import "./styles.css";

const CategoryItemTable = (props) => {
  const [data, setData] = useState(null);
  const { categoryItems, categoryToEdit, setItemsVisible, setCategoryItem, NO_IMAGE, getCategoryItems } = props;

  const status = useSelector((state) => state.category_items.status);

  const menu = useSelector((state) => state.menu.menu);

  const skus = useSelector((state) => state.skus.skus);

  useEffect(() => {
    const data = _.map(categoryItems, (item) => ({
      id: item.id,
      sort: item.sort,
      image: _.get(item, "menu_item.skus.image_uber_eats.formats.thumbnail.url", NO_IMAGE),
      skus: skus.find((s) => s.id === _.get(item, "menu_item.skus.id")),
      name_override: _.get(item, "name_override"),
      price_override: _.get(item, "price_override"),
      menu_item: _.get(item, "menu_item"),
      category: categoryToEdit.id,
      modifiers: item.category_item_modifiers,
    }));
    setData(data);
  }, [categoryItems, categoryToEdit, skus, NO_IMAGE]);

  const columns = [
    {
      title: "skus",
      dataIndex: "skus",
      key: "skus",
      render: (skus, item) => {
        const imageUrl = _.get(skus, "image_uber_eats.formats.thumbnail.url", NO_IMAGE);
        const available = _.get(item, "menu_item.available");
        const missingSKU = skus;
        const missingImages =
          _.get(skus, "image_uber_eats.formats") &&
          _.get(skus, "image_doordash.formats") &&
          _.get(skus, "image_grubhub.formats");
        const color = !available ? "black" : !missingSKU ? "red" : missingImages ? "green" : "orange";
        return (
          <Badge color={color} dot={true}>
            <Avatar
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid rgb(235, 237, 240)",
              }}
              src={<Image style={{ height: 35, width: 35 }} src={imageUrl} preview={false} fallback={NO_IMAGE} />}
              shape="circle"
              size="default"
            />
          </Badge>
        );
      },
    },
    {
      title: "name_override",
      dataIndex: "name_override",
      key: "name_override",
      render: (name_override, item) => name_override || _.get(item, "menu_item.name"),
    },
    {
      title: "price_override",
      dataIndex: "price_override",
      key: "price_override",
      align: "right",
      render: (price_override, item) => (
        <Tooltip title="Price">{price_override === null ? _.get(item, "menu_item.price") : price_override}</Tooltip>
      ),
    },
  ];

  return (
    <>
      <h2 className="label">Items</h2>
      <div className="items-table-wrapper">
        <SortableTable
          size="small"
          className="items-table"
          showHeader={false}
          columns={columns}
          dataSource={data}
          pagination={false}
          onSortEnd={(updatedData) => {
            StrapiApi.updateSort(updatedData, "categoryItem")
              .then(() => {
                const { id } = menu;
                getCategoryItems(id);
                message.success("Sorting is saved");
              })
              .catch(() => {
                message.error("Sorting failed");
              });
          }}
          loading={status === "pending" ? true : false}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                setCategoryItem(record);
                setItemsVisible(true);
              },
            };
          }}
        />
      </div>
    </>
  );
};

export default connect(null, { getCategoryItems })(CategoryItemTable);
