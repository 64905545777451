import {
  FETCH_CATEGORY_ITEMS_START,
  FETCH_CATEGORY_ITEMS_SUCCESS,
  FETCH_CATEGORY_ITEMS_FAILURE,
  ADD_CATEGORY_ITEM_START,
  ADD_CATEGORY_ITEM_SUCCESS,
  ADD_CATEGORY_ITEM_FAILURE,
  UPDATE_CATEGORY_ITEM_START,
  UPDATE_CATEGORY_ITEM_SUCCESS,
  UPDATE_CATEGORY_ITEM_FAILURE,
  DELETE_CATEGORY_ITEM_START,
  DELETE_CATEGORY_ITEM_SUCCESS,
  DELETE_CATEGORY_ITEM_FAILURE,
} from "../constants";

const initialState = {
  category_items: [],
  status: "idle",
  error: "",
};

const categoryItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_ITEMS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_CATEGORY_ITEMS_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_items: action.payload,
      };
    case FETCH_CATEGORY_ITEMS_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case ADD_CATEGORY_ITEM_START:
      return {
        ...state,
        status: "pending",
      };
    case ADD_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_items: [...state.category_items, action.payload],
      };
    case ADD_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case UPDATE_CATEGORY_ITEM_START:
      return {
        ...state,
        status: "pending",
      };
    case UPDATE_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_items: [
          ...state.category_items.slice(
            0,
            state.category_items.findIndex(
              (i) => i.id.toString() === action.payload.id.toString()
            )
          ),
          action.payload,
          ...state.category_items.slice(
            state.category_items.findIndex(
              (i) => i.id.toString() === action.payload.id.toString()
            ) + 1
          ),
        ],
      };
    case UPDATE_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case DELETE_CATEGORY_ITEM_START:
      return {
        ...state,
        status: "pending",
      };
    case DELETE_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        category_items: [
          ...state.category_items.slice(
            0,
            state.category_items.findIndex(
              (i) => i.id.toString() === action.payload.id.toString()
            )
          ),
          ...state.category_items.slice(
            state.category_items.findIndex(
              (i) => i.id.toString() === action.payload.id.toString()
            ) + 1
          ),
        ],
      };
    case DELETE_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default categoryItemsReducer;
