import {
  MEDIA_UPLOAD_START,
  MEDIA_UPLOAD_SUCCESS,
  MEDIA_UPLOAD_FAILURE,
  UPDATE_UBEREATS_SKU_IMAGE_START,
  UPDATE_UBEREATS_SKU_IMAGE_SUCCESS,
  UPDATE_UBEREATS_SKU_IMAGE_FAILURE,
  UPDATE_DOORDASH_SKU_IMAGE_START,
  UPDATE_DOORDASH_SKU_IMAGE_SUCCESS,
  UPDATE_DOORDASH_SKU_IMAGE_FAILURE,
  UPDATE_GRUBHUB_SKU_IMAGE_START,
  UPDATE_GRUBHUB_SKU_IMAGE_SUCCESS,
  UPDATE_GRUBHUB_SKU_IMAGE_FAILURE,
  RESET_SKU_IMAGES,
  MESSAGE_DURATION,
} from "../../constants";

import { message } from "antd";

import { handleError } from "../../functions";

import Client from "../../apis/client";
import * as StrapiApi from "../../apis/strapi";

const client = new Client(StrapiApi.STRAPI_BASE);
window.StrapiApi = StrapiApi;

export const uploadMediaFile = (data) => {
  return async (dispatch) => {
    dispatch({ type: MEDIA_UPLOAD_START });
    const response = client.uploadMediaFile(data);
    response
      .then((res) => {
        dispatch({
          type: MEDIA_UPLOAD_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: MEDIA_UPLOAD_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateUberEatsSkuImage = (data, setPercent) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_UBEREATS_SKU_IMAGE_START });
    const response = client.updateSkuImage(data, setPercent);
    response
      .then((res) => {
        dispatch({
          type: UPDATE_UBEREATS_SKU_IMAGE_SUCCESS,
          payload: res.data,
        });
        message.success("Image successfully uploaded!");
        setPercent(0);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_UBEREATS_SKU_IMAGE_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateDoorDashSkuImage = (data, setPercent) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_DOORDASH_SKU_IMAGE_START });
    const response = client.updateSkuImage(data, setPercent);
    response
      .then((res) => {
        dispatch({
          type: UPDATE_DOORDASH_SKU_IMAGE_SUCCESS,
          payload: res.data,
        });
        message.success("Image successfully uploaded!");
        setPercent(0);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_DOORDASH_SKU_IMAGE_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateGrubHubSkuImage = (data, setPercent) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_GRUBHUB_SKU_IMAGE_START });
    const response = client.updateSkuImage(data, setPercent);
    response
      .then((res) => {
        dispatch({
          type: UPDATE_GRUBHUB_SKU_IMAGE_SUCCESS,
          payload: res.data,
        });
        message.success("Image successfully uploaded!");
        setPercent(0);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_GRUBHUB_SKU_IMAGE_FAILURE,
          payload: err.message,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const resetSkuImages = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_SKU_IMAGES });
  };
};
