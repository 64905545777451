import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, Form, Row, Col, Button, Popconfirm, Space, Select } from "antd";

import { addCategoryItemModifier } from "../../../actions/categoryItemModifiers";

import _ from "lodash";

import "./styles.css";

const AddCategoryItemModifierModal = (props) => {
  const [categoryItemModifiers, setCategoryItemModifiers] = useState([]);
  const [selectedModifier, setSelectedModifier] = useState(null);
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const {
    categoryItem,
    categoryItemModifierModalVisible,
    setCategoryItemModifierModalVisible,
    addCategoryItemModifier,
  } = props;

  const [form] = Form.useForm();

  const { Option } = Select;

  const menu = useSelector((state) => state.menu.menu);

  const modifiers = useSelector((state) => state.modifiers.modifiers);

  const category_item_modifiers = useSelector((state) => state.category_item_modifiers.category_item_modifiers);

  const categoryItemId = _.get(categoryItem, "id", null);

  useEffect(() => {
    console.log(selectedModifier);
  }, [selectedModifier]);

  useEffect(() => {
    if (categoryItem) {
      const getCategoryItemModifiers = () => {
        const mods = _.filter(category_item_modifiers, (m) => _.get(m.category_item, "id") === categoryItemId);
        setCategoryItemModifiers(mods);
      };
      getCategoryItemModifiers();
    }
  }, [categoryItem, categoryItemId, category_item_modifiers, menu]);

  const handlePopConfirmOk = () => {
    handleClose();
    setCancelPopConfirmVisible(false);
  };

  const handlePopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };

  const setSelectedModifierFunc = (id) => {
    const modifier = modifiers.find((item) => item.id === id);
    setSelectedModifier(modifier);
  };

  const handleSubmit = (values) => {
    const { modifier } = values;
    if (categoryItemModifiers.length === 0) {
      const sort = 1;
      addCategoryItemModifier({
        category_item: categoryItemId,
        modifier: modifier,
        sort: sort,
      });
    } else {
      const sort =
        parseInt(
          categoryItemModifiers.sort((a, b) => parseInt(a.sort, 10) - parseInt(b.sort, 10))[
            categoryItemModifiers.length - 1
          ].sort,
          10
        ) + 1;
      addCategoryItemModifier({
        category_item: categoryItemId,
        modifier: modifier,
        sort: sort,
      });
    }
    setCategoryItemModifierModalVisible(false);
    setDisabled(true);
    form.resetFields();
  };

  const handleClose = () => {
    setCategoryItemModifierModalVisible(false);
    setDisabled(true);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Select a Modifier"
        centered={true}
        visible={categoryItemModifierModalVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        zIndex={1}
        footer={
          <Space>
            <Popconfirm
              title="Are you sure? Your changes won't be saved."
              style={{ width: 100 }}
              visible={cancelPopConfirmVisible}
              onConfirm={handlePopConfirmOk}
              onCancel={handlePopConfirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button onClick={() => (disabled ? handleClose() : setCancelPopConfirmVisible(true))}>Cancel</Button>
            </Popconfirm>
            <Button disabled={disabled} onClick={() => form.submit()}>
              Add
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="modifier"
                label="Modifier"
                rules={[
                  {
                    required: true,
                    message: "Please select a modifier",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select a modifier"
                  onChange={() => setDisabled(false)}
                  onSelect={(value) => setSelectedModifierFunc(value)}
                  onClear={() => {
                    setSelectedModifier(null);
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {_.map(modifiers, (modifier) => (
                    <Option key={modifier.id} value={modifier.id}>
                      {modifier.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, { addCategoryItemModifier })(AddCategoryItemModifierModal);
