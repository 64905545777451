import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, Form, Row, Col, Input, InputNumber, Button, Space, Select } from "antd";

import { addModifierItem, updateModifierItem, deleteModifierItem } from "../../../actions/modifierItems";
import { sortItemsByName } from "../../../functions";

import _ from "lodash";

import "./styles.css";

const AddModifierItemModal = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuItemIds, setMenuItemIds] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const {
    selectedModifier,
    setSelectedModifierItem,
    modifierItemModalVisible,
    setModifierItemModalVisible,
    addModifierItem,
    // updateModifierItem,
    // deleteModifierItem,
    newModifierItems,
  } = props;

  const [form] = Form.useForm();

  const { Option } = Select;

  const modifierItems = useSelector((state) => state.modifier_items.modifier_items);

  const menuItems = useSelector((state) => state.menu_items.menu_items);

  const sortedMenuItems = menuItems.slice();

  sortItemsByName(sortedMenuItems);

  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedModifier) {
      const modItems = _.filter(modifierItems, (i) => i.modifier.id === selectedModifier.id);
      const ids = _.map(modItems, (i) => i.menu_item.id);
      setMenuItemIds(ids);
    }
  }, [modifierItems, selectedModifier]);

  const setSelectedOptionFunc = (id) => {
    const menuItem = menuItems.find((item) => item.id === id);
    setSelectedOption(menuItem);
  };

  const handleSubmit = (values) => {
    const { menu_item, name_override, price_override } = values;
    const modifierId = selectedModifier.modifier ? selectedModifier.modifier.id : selectedModifier.id;
    if (newModifierItems.length === 0) {
      const sort = 1;
      addModifierItem({
        menu_item: menu_item,
        modifier: modifierId,
        name_override: name_override,
        price_override: price_override,
        sort: sort,
      });
    } else {
      const sort =
        parseInt(
          newModifierItems.sort((a, b) => parseInt(a.sort, 10) - parseInt(b.sort, 10))[newModifierItems.length - 1]
            .sort,
          10
        ) + 1;
      addModifierItem({
        menu_item: menu_item,
        modifier: modifierId,
        name_override: name_override,
        price_override: price_override,
        sort: sort,
      });
    }
    setModifierItemModalVisible(false);
    setSelectedModifierItem(null);
    setDisabled(true);
    form.resetFields();
  };

  const handleClose = () => {
    setModifierItemModalVisible(false);
    setSelectedModifierItem(null);
    setSelectedOption(null);
    setDisabled(true);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Add Option"
        centered={true}
        visible={modifierItemModalVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        zIndex={1}
        footer={
          <Space>
            <Button onClick={() => handleClose()}>Cancel</Button>
            <Button disabled={disabled} onClick={() => form.submit()}>
              Add
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="menu_item" label="Option">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select option"
                  onChange={() => setDisabled(false)}
                  onSelect={(value) => setSelectedOptionFunc(value)}
                  onClear={() => {
                    setSelectedOption(null);
                    form.resetFields();
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {_.map(sortedMenuItems, (item) => (
                    <Option
                      key={item.id}
                      value={item.id}
                      disabled={!menuItemIds ? false : menuItemIds.find((id) => id === item.id) ? true : false}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="name_override" label="Name Override">
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="price_override" label="Price Override">
                <InputNumber min={0} step={0.01} placeholder="0" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, {
  addModifierItem,
  updateModifierItem,
  deleteModifierItem,
})(AddModifierItemModal);
