import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { message } from "antd";
import SortableTable from "../SortableTable";

import { getCategories } from "../../actions/categories";

import _ from "lodash";
import * as StrapiApi from "../../apis/strapi";

import "./styles.css";

window.StrapiApi = StrapiApi;

const CategoryTable = (props) => {
  const [data, setData] = useState(null);
  const { categories, categoryToEdit, setCategoryToEdit, getCategories } = props;

  const menu = useSelector((state) => state.menu.menu);

  const status = useSelector((state) => state.categories.status);

  useEffect(() => {
    const newData = _.map(categories, (category) => ({
      id: category.id,
      key: category.id,
      sort: category.sort,
      category: category.name,
      description: category.description,
      category_items: category.category_items,
      brand: _.get(category, "brand.id"),
    }));
    setData(newData);
  }, [categories]);

  const columns = [
    {
      title: "category",
      dataIndex: "category",
      key: "category",
    },
  ];

  return (
    <>
      <h2 className="label">Categories</h2>
      <div className="category-table-wrapper">
        <SortableTable
          size="small"
          className="category-table"
          showHeader={false}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={status === "pending" ? true : false}
          onSortEnd={(updatedData) => {
            StrapiApi.updateSort(updatedData, "category")
              .then(() => {
                const { id } = menu;
                getCategories(id);
                message.success("Sorting is saved");
              })
              .catch(() => {
                message.error("Sorting failed");
              });
          }}
          rowClassName={(record) => (categoryToEdit && record.id === categoryToEdit.id ? "highlighted-row" : null)}
          onRow={(record) => {
            return {
              onClick: () => {
                setCategoryToEdit(record);
              },
            };
          }}
        />
      </div>
    </>
  );
};

export default connect(null, { getCategories })(CategoryTable);
