import React, { useState } from "react";
import { Popconfirm, Layout } from "antd";
import { ButtonComponent } from "../Button";

import "./styles.css";

const { Footer } = Layout;

export const HomePageFooter = (props) => {
  const { className, setMenuModalVisible } = props;
  return (
    <Footer className={className}>
      <div className="home-page-footer">
        <ButtonComponent text="Add Menu" onClick={() => setMenuModalVisible(true)} />
      </div>
    </Footer>
  );
};

export const CategoriesFooter = (props) => {
  const { setCategoryModalVisible } = props;
  return (
    <div className="footer">
      <ButtonComponent
        className="add-category-button"
        data-testid="add-category-btn"
        text="Add Category"
        onClick={() => setCategoryModalVisible(true)}
      />
    </div>
  );
};

export const ItemsFooter = (props) => {
  const { setAddingItem } = props;
  return (
    <div className="footer">
      <ButtonComponent className="add-item-button" text="Add Item" onClick={() => setAddingItem(true)} />
    </div>
  );
};

export const ModifierFooter = (props) => {
  const { setModifierModalVisible } = props;
  return (
    <div className="footer">
      <ButtonComponent
        className="add-modifier-button"
        text="Add Modifier Group"
        onClick={() => setModifierModalVisible(true)}
      />
    </div>
  );
};

export const TargetsFooter = (props) => {
  const { setTargetsModalVisible } = props;
  return (
    <div className="footer">
      <ButtonComponent
        className="add-targets-button"
        text="Add Targets"
        onClick={() => setTargetsModalVisible(true)}
      />
    </div>
  );
};

export const InternalPromosFooter = (props) => {
  const { setInternalPromosModalVisible } = props;
  return (
    <div className="footer">
      <ButtonComponent
        className="add-targets-button"
        text="Add Internal Promos"
        onClick={() => setInternalPromosModalVisible(true)}
      />
    </div>
  );
};

export const EditingComponentFooter = (props) => {
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);

  const {
    itemToEdit,
    setItemToEdit,
    addingItem,
    setAddingItem,
    selectedModifier,
    setSelectedModifier,
    selectedTarget,
    setSelectedTarget,
    setModifierItemModalVisible,
    setMenuItemModalVisible,
    setCategoryToEdit,
    form,
    disabled,
  } = props;

  const handleCancelPopConfirmOk = () => {
    if (itemToEdit) {
      setItemToEdit(null);
    } else if (addingItem) {
      setAddingItem(false);
    } else if (selectedModifier) {
      setSelectedModifier(null);
    } else if (selectedTarget) {
      setSelectedTarget(null);
    } else {
      setCategoryToEdit(null);
    }
    setCancelPopConfirmVisible(false);
  };
  const handleCancelPopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };
  return (
    <div className="footer">
      {itemToEdit || addingItem || selectedTarget ? null : selectedModifier ? (
        <ButtonComponent
          className="add-modifier-item-button"
          text="Add Option"
          onClick={() => setModifierItemModalVisible(true)}
        />
      ) : (
        <ButtonComponent className="add-item-button" text="Add Item" onClick={() => setMenuItemModalVisible(true)} />
      )}
      <Popconfirm
        title="Are you sure? Your changes won't be saved."
        style={{ width: 100 }}
        visible={cancelPopConfirmVisible}
        onConfirm={handleCancelPopConfirmOk}
        onCancel={handleCancelPopConfirmCancel}
        okText="Yes"
        cancelText="No"
      >
        <ButtonComponent
          className="cancel-button"
          text="Cancel"
          onClick={() => {
            disabled && itemToEdit
              ? setItemToEdit(null)
              : disabled && addingItem
              ? setAddingItem(false)
              : disabled && selectedModifier
              ? setSelectedModifier(null)
              : disabled && selectedTarget
              ? setSelectedTarget(null)
              : disabled
              ? setCategoryToEdit(null)
              : setCancelPopConfirmVisible(true);
          }}
        />
      </Popconfirm>
      <ButtonComponent disabled={disabled} text="Save" onClick={() => form.submit()} />
    </div>
  );
};
