import {
  FETCH_TARGETS_START,
  FETCH_TARGETS_SUCCESS,
  FETCH_TARGETS_FAILURE,
} from "../constants";

const initialState = {
  targets: [],
  status: "idle",
  error: "",
};

const targetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TARGETS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_TARGETS_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        targets: action.payload,
      };
    case FETCH_TARGETS_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default targetsReducer;