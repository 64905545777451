import {
  FETCH_SKUS_START,
  FETCH_SKUS_SUCCESS,
  FETCH_SKUS_FAILURE,
  UPDATE_UBEREATS_SKU_IMAGE_START,
  UPDATE_UBEREATS_SKU_IMAGE_SUCCESS,
  UPDATE_UBEREATS_SKU_IMAGE_FAILURE,
  UPDATE_DOORDASH_SKU_IMAGE_START,
  UPDATE_DOORDASH_SKU_IMAGE_SUCCESS,
  UPDATE_DOORDASH_SKU_IMAGE_FAILURE,
  UPDATE_GRUBHUB_SKU_IMAGE_START,
  UPDATE_GRUBHUB_SKU_IMAGE_SUCCESS,
  UPDATE_GRUBHUB_SKU_IMAGE_FAILURE,
} from "../constants";

const initialState = {
  skus: [],
  status: "idle",
  ubereats_status: "idle",
  doordash_status: "idle",
  grubhub_status: "idle",
  error: "",
};

const skusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SKUS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_SKUS_SUCCESS:
      return {
        ...state,
        skus: action.payload,
        status: "succeeded",
      };
    case FETCH_SKUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: "failure",
      };
    case UPDATE_UBEREATS_SKU_IMAGE_START:
      return {
        ...state,
        ubereats_status: "pending",
      };
    case UPDATE_UBEREATS_SKU_IMAGE_SUCCESS:
      return {
        ...state,
        ubereats_status: "succeeded",
        skus: [
          ...state.skus.slice(
            0,
            state.skus.findIndex((s) => s.id === action.payload[0].related[0].id)
          ),
          {
            ...state.skus.find((s) => s.id === action.payload[0].related[0].id),
            image_uber_eats: action.payload[0],
          },
          ...state.skus.slice(state.skus.findIndex((s) => s.id === action.payload[0].related[0].id) + 1),
        ],
      };
    case UPDATE_UBEREATS_SKU_IMAGE_FAILURE:
      return {
        ...state,
        ubereats_status: "failed",
        error: action.payload,
      };
    case UPDATE_DOORDASH_SKU_IMAGE_START:
      return {
        ...state,
        doordash_status: "pending",
      };
    case UPDATE_DOORDASH_SKU_IMAGE_SUCCESS:
      return {
        ...state,
        doordash_status: "succeeded",
        skus: [
          ...state.skus.slice(
            0,
            state.skus.findIndex((s) => s.id === action.payload[0].related[0].id)
          ),
          {
            ...state.skus.find((s) => s.id === action.payload[0].related[0].id),
            image_doordash: action.payload[0],
          },
          ...state.skus.slice(state.skus.findIndex((s) => s.id === action.payload[0].related[0].id) + 1),
        ],
      };
    case UPDATE_DOORDASH_SKU_IMAGE_FAILURE:
      return {
        ...state,
        doordash_status: "failed",
        error: action.payload,
      };
    case UPDATE_GRUBHUB_SKU_IMAGE_START:
      return {
        ...state,
        grubhub_status: "pending",
      };
    case UPDATE_GRUBHUB_SKU_IMAGE_SUCCESS:
      return {
        ...state,
        grubhub_status: "succeeded",
        skus: [
          ...state.skus.slice(
            0,
            state.skus.findIndex((s) => s.id === action.payload[0].related[0].id)
          ),
          {
            ...state.skus.find((s) => s.id === action.payload[0].related[0].id),
            image_grubhub: action.payload[0],
          },
          ...state.skus.slice(state.skus.findIndex((s) => s.id === action.payload[0].related[0].id) + 1),
        ],
      };
    case UPDATE_GRUBHUB_SKU_IMAGE_FAILURE:
      return {
        ...state,
        grubhub_status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default skusReducer;
