import React ,{useState} from "react";
import Logo from "../../components/Logo";
import { Typography,Button, message, Form, Input } from 'antd';

import {  loginWithUserName, STRAPI_BASE } from '../../apis/strapi'

import "./styles.css";
import { useHistory } from 'react-router-dom'

const tailLayout = {
  wrapperCol: { offset: 0, span: 32 },
};

const LoginPage = () => {
  const { Title } = Typography;

  const history = useHistory();


  const [submitting, setSubmitting] = useState(false)


  const onFinish = async (values) => {
    setSubmitting(true)
    try {
      const res = await loginWithUserName(values)
      if (res?.jwt) {
        const defaultLoginSuccessMessage = 'Login successful!'
        message.success(defaultLoginSuccessMessage)

        localStorage.setItem("jwt", res.jwt);
        localStorage.setItem("user", JSON.stringify(res.user));

        setSubmitting(false)

        if (!history) return
        history.push(history?.location?.query?.redirect || '/')
      }
    } catch (error) {
      setSubmitting(false)
      const defaultLoginFailureMessage = 'Login failed, please try again!'
      message.error(defaultLoginFailureMessage)
    }


  };

  return (
    <div className="login-wrapper">
      <div className="login-div">
        <div>
          <Logo className="login-logo" />
        </div>
        <Title italic className="login-title" style={{ fontSize: 20 }}>
          ADK Menu Manager
        </Title>

        <div>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Identifier"
              name="identifier"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" disabled={submitting}>
                {submitting? 'Submitting':'Submit'}
              </Button>
              <Button type="link" htmlType="button"  onClick={() => (window.location = `${STRAPI_BASE}/connect/auth0`)}>
                Login with Google
              </Button>
            </Form.Item>
          </Form>
        </div>

      </div>
    </div>
  );
};

export default LoginPage;
