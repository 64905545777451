import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Layout } from "antd";
import { updateMenu } from "../../actions/menu";
import { deleteMenu } from "../../actions/menus";
import {
  renderHomePageHeaderContent,
  renderHomePageHeaderSkeleton,
  renderMenuPageHeaderContent,
  renderMenuPageHeaderSkeleton,
} from "./functions";

import moment from "moment";

import "./styles.css";

const PageHeader = (props) => {
  const [hours, setHours] = useState(null);
  const [hoursChanged, setHoursChanged] = useState(false);
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);

  const {
    className,
    onInputChange,
    setPublishModalVisible,
    deleteMenu,
    updateMenu,
    // toggleOn,
    // setToggleOn
  } = props;

  const { Header } = Layout;

  const history = useHistory();

  const location = useLocation();

  const menu = useSelector((state) => state.menu.menu);

  const homeStatus = useSelector((state) => state.menus.status);

  const menuStatus = useSelector((state) => state.menu.status);

  const NO_IMAGE = `https://vkc-files.s3.us-west-1.amazonaws.com/noimage_4dcae76445.png`;

  // const date = new Date(_.get(menu, "published_at")).toDateString();

  const publishStatus = useSelector((state) => state.menu.publishMenuStatus);

  useEffect(() => {
    const setInitialHours = () => {
      const { hours } = menu;
      setHours(hours);
    };
    setInitialHours();
  }, [menu]);

  const showDeletePopconfirm = () => {
    setDeletePopConfirmVisible(true);
  };

  const handleDeletePopConfirmOk = () => {
    deleteMenu(menu.id, history);
    setDeletePopConfirmVisible(false);
  };

  const handleDeletePopConfirmCancel = () => {
    setDeletePopConfirmVisible(false);
  };

  const onHoursChange = (time, timeString) => {
    if (time) {
      const newTime = [
        moment(timeString[0], "h:mm A").format("HH:mm"),
        moment(timeString[1], "h:mm A").format("HH:mm"),
      ];
      setHours([newTime]);
    } else {
      setHours(null);
    }
    setHoursChanged(true);
  };

  useEffect(() => {
    if (hoursChanged) {
      const { id } = menu;
      const body = { ...menu, hours: hours };
      updateMenu(id, body);
    }
    setHoursChanged(false);
  }, [hoursChanged, menu, hours, updateMenu]);

  return (
    <Header className={className}>
      <div className="site-page-header">
        <div className="header-contents">
          {location.pathname === "/" ? (
            <>
              {homeStatus === "pending"
                ? renderHomePageHeaderSkeleton()
                : renderHomePageHeaderContent({ onInputChange })}
            </>
          ) : (
            <>
              {menuStatus === "pending"
                ? renderMenuPageHeaderSkeleton({ publishStatus })
                : renderMenuPageHeaderContent({
                    history,
                    menu,
                    onHoursChange,
                    hours,
                    deletePopConfirmVisible,
                    handleDeletePopConfirmOk,
                    handleDeletePopConfirmCancel,
                    showDeletePopconfirm,
                    setPublishModalVisible,
                    NO_IMAGE,
                    publishStatus,
                  })}
            </>
          )}
        </div>
      </div>
    </Header>
  );
};

export default connect(null, { deleteMenu, updateMenu })(PageHeader);
