import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { deleteMenu } from "../../actions/menus";
import { clearMenuItems } from "../../actions/menuItems";
import AddMenuModal from "../../components/Modals/AddMenuModal";
import CloneMenuModal from "../../components/Modals/CloneMenuModal";
import MenuTable from "../../components/MenuTable";
import Fuse from "fuse.js";
import _ from "lodash";

import "./styles.css";

const HomePage = (props) => {
  const [cloneMenuModalVisible, setCloneMenuModalVisible] = useState(false);
  const [menuToClone, setMenuToClone] = useState(null);
  const [menuToDelete, setMenuToDelete] = useState(null);

  const {
    deleteMenu,
    menus,
    status,
    searchPattern,
    setSearchPattern,
    menuModalVisible,
    setMenuModalVisible,
    clearMenuItems,
  } = props;

  const history = useHistory();

  const activeMenus = _.filter(menus, (menu) => menu.active);

  const NO_IMAGE = `https://vkc-files.s3.us-west-1.amazonaws.com/noimage_4dcae76445.png`;

  useEffect(() => {
    setSearchPattern("");
  }, [setSearchPattern, menus]);

  useEffect(() => {
    clearMenuItems();
  }, [clearMenuItems]);

  const fuseSearchResults = () => {
    const options = {
      threshold: 0.2,
      keys: ["title", "brand.name", "location.name", "id"],
    };
    const fuse = new Fuse(activeMenus, options);
    const pattern = searchPattern;

    return fuse.search(pattern, { limit: 40 }).map((res) => res.item);
  };

  const data = searchPattern === "" ? activeMenus : fuseSearchResults();

  const handleDeletePopConfirmOk = (menu) => {
    const { id } = menu;
    deleteMenu(id, history);
    setSearchPattern("");
    setMenuToDelete(null);
  };

  const handleDeletePopConfirmCancel = () => {
    setMenuToDelete(null);
  };

  return (
    <>
      <div className="home-wrapper">
        {status === "pending" ? (
          <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
        ) : (
          <div className="home-content-wrapper">
            <MenuTable
              tableData={data}
              status={status}
              NO_IMAGE={NO_IMAGE}
              setMenuToClone={setMenuToClone}
              menuToDelete={menuToDelete}
              setMenuToDelete={setMenuToDelete}
              handleDeletePopConfirmOk={handleDeletePopConfirmOk}
              handleDeletePopConfirmCancel={handleDeletePopConfirmCancel}
              setCloneMenuModalVisible={setCloneMenuModalVisible}
              setSearchPattern={setSearchPattern}
            />
          </div>
        )}
        <AddMenuModal menuModalVisible={menuModalVisible} setMenuModalVisible={setMenuModalVisible} />
        <CloneMenuModal
          menuToClone={menuToClone}
          setMenuToClone={setMenuToClone}
          cloneMenuModalVisible={cloneMenuModalVisible}
          setCloneMenuModalVisible={setCloneMenuModalVisible}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menus: state.menus.menus,
    status: state.menus.status,
  };
};

export default connect(mapStateToProps, {
  deleteMenu,
  clearMenuItems,
})(HomePage);
