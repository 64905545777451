import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { Tooltip, message, Menu, Dropdown, Modal } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getModifierItems } from "../../actions/modifierItems";
import { deleteModifierItem } from "../../actions/modifierItems";

import SortableTable from "../SortableTable";
import * as StrapiApi from "../../apis/strapi";

import _ from "lodash";

import "./styles.css";

const ModifierItemTable = (props) => {
  const [data, setData] = useState(null);

  const { newModifierItems, deleteModifierItem, selectedModifierItem, setSelectedModifierItem, getModifierItems } =
    props;

  const status = useSelector((state) => state.modifier_items.status);

  const menu = useSelector((state) => state.menu.menu);

  const { Item } = Menu;

  const { confirm } = Modal;

  useEffect(() => {
    if (newModifierItems) {
      const tableData = _.map(newModifierItems, (item) => ({
        id: item.id,
        key: item.id,
        name: _.get(item, "name_override")
          ? item.name_override
          : _.get(item, "menu_item.name")
          ? item.menu_item.name
          : null,
        menu_item: _.get(item, "menu_item.id"),
        sku: _.get(item, "menu_item.skus.id"),
        sku_id: _.get(item, "menu_item.skus.sku_id"),
        sku_name: _.get(item, "menu_item.skus.name"),
        owner: _.get(item, "menu_item.skus.owner.name"),
        price:
          _.get(item, "price_override") || _.get(item, "price_override") === 0
            ? item.price_override
            : _.get(item, "menu_item.price")
            ? item.menu_item.price
            : null,
        sort: item.sort,
      }));
      setData(tableData);
    }
  }, [newModifierItems]);

  function showConfirm() {
    confirm({
      title: "Are you sure you want to remove this item?",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        const { id } = selectedModifierItem;
        deleteModifierItem(id);
        setSelectedModifierItem(null);
      },
      onCancel() {
        setSelectedModifierItem(null);
      },
    });
  }

  const dropdownMenu = (
    <Menu>
      <Item
        key="0"
        onClick={() => {
          showConfirm();
        }}
      >
        Remove Item
      </Item>
    </Menu>
  );

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: "sku",
      dataIndex: "sku",
      key: "sku",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (sku, record) => {
        return (
          <Tooltip
            title={
              <>
                {record.sku_name}
                <br />
                {record.sku_id}
              </>
            }
          >
            <span>{record.owner}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: 50,
      render: (price) => (
        <Tooltip title="Price" placement="left">
          {price}
        </Tooltip>
      ),
    },
    {
      title: "actions",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      width: 50,
      render: () => (
        <Dropdown overlay={dropdownMenu} overlayStyle={{ paddingRight: 10 }} trigger={["click"]}>
          <EllipsisOutlined rotate={90} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <h2 className="label">Available Options</h2>
      <div className="modifier-item-table-wrapper">
        <SortableTable
          className="modifier-item-table"
          size="small"
          showHeader={false}
          columns={columns}
          dataSource={data}
          pagination={false}
          onSortEnd={(updatedData) => {
            StrapiApi.updateSort(updatedData, "modifierItem")
              .then(() => {
                const { id } = menu;
                getModifierItems(id);
                message.success("Sorting is saved");
              })
              .catch(() => {
                message.error("Sorting failed");
              });
          }}
          loading={status === "pending" ? true : false}
          onRow={(record) => {
            return {
              onClick: () => setSelectedModifierItem(record),
            };
          }}
        />
      </div>
    </>
  );
};

export default connect(null, { deleteModifierItem, getModifierItems })(ModifierItemTable);
