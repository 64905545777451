import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { setCategoryItemValues, setSkuValuesFunc, overrideValues } from "../../helpers";
import {
  Drawer,
  Image,
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Tooltip,
  Button,
  Popconfirm,
  Upload,
  Progress,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import CategoryItemModifierDrawer from "../CategoryItemModifierDrawer";
import CategoryItemModifierTable from "../CategoryItemModifierTable";
import AddCategoryItemModifierModal from "../Modals/AddCategoryItemModifierModal";
import SkuModal from "../Modals/SkuModal";
import { updateCategoryItem, deleteCategoryItem } from "../../actions/categoryItems";
import { updateMenuItem } from "../../actions/menuItems";
import {
  uploadMediaFile,
  updateUberEatsSkuImage,
  updateDoorDashSkuImage,
  updateGrubHubSkuImage,
} from "../../actions/images";
import { sortItemsBySortNum } from "../../functions/index";

import _ from "lodash";

import "./styles.css";

const CategoryItemDrawer = (props) => {
  const [selectedSku, setSelectedSku] = useState(null);
  const [sku, setSku] = useState(null);
  const [skuValues, setSkuValues] = useState(null);
  const [modifierGroups, setModifierGroups] = useState(null);
  const [categoryItemModifierIds, setCategoryItemModifierIds] = useState(null);
  const [sortedCategoryItemModifiers, setSortedCategoryItemModifiers] = useState(null);
  const [selectedModifier, setSelectedModifier] = useState(null);
  const [available, setAvailable] = useState(true);
  const [skuModalVisible, setSkuModalVisible] = useState(false);
  const [modifierDrawerVisible, setModifierDrawerVisible] = useState(false);
  const [categoryItemModifierModalVisible, setCategoryItemModifierModalVisible] = useState(false);
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [percent, setPercent] = useState(0);

  const {
    itemsVisible,
    setItemsVisible,
    categoryItem,
    setCategoryItem,
    categoryId,
    categoryToEdit,
    NO_IMAGE,
    updateMenuItem,
    updateCategoryItem,
    deleteCategoryItem,
    // uploadMediaFile,
    updateUberEatsSkuImage,
    updateDoorDashSkuImage,
    updateGrubHubSkuImage,
  } = props;

  const [form] = Form.useForm();

  const { Dragger } = Upload;

  const menuId = useSelector((state) => state.menu.menu.id);

  const category_item_modifiers = useSelector((state) => state.category_item_modifiers.category_item_modifiers);

  const modifiers = useSelector((state) => state.modifiers.modifiers);

  const skus = useSelector((state) => state.skus.skus);

  const skuId = sku ? sku.id : _.get(categoryItem, "menu_item.skus.id", "");

  const uberEatsImageStatus = useSelector((state) => state.skus.ubereats_status);

  const doorDashImageStatus = useSelector((state) => state.skus.doordash_status);

  const grubHubImageStatus = useSelector((state) => state.skus.grubhub_status);

  useEffect(() => {
    console.log(modifierGroups);
  }, [modifierGroups]);

  useEffect(() => {
    if (categoryItem) {
      const initialFormValues = setCategoryItemValues(categoryItem);
      const s = skus.find((s) => s.id === _.get(categoryItem, "menu_item.skus.id"));
      const values = setSkuValuesFunc([[s], NO_IMAGE]);
      setSkuValues(values[0]);
      setAvailable(_.get(categoryItem, "menu_item.available"));
      form.setFieldsValue(initialFormValues[0]);
    }
  }, [categoryItem, form, skus, NO_IMAGE]);

  useEffect(() => {
    if (categoryItem) {
      const categoryItemModifiers = _.filter(
        category_item_modifiers,
        (m) => _.get(m.category_item, "id") === categoryItem.id
      );
      const ids = categoryItemModifiers.map((mod) => mod.id);
      setCategoryItemModifierIds(ids);
      const sortedArr = sortItemsBySortNum(categoryItemModifiers);
      const newCategoryItemMods = _.map(sortedArr, (m) => ({
        ...m,
        modifier: modifiers.find((mod) => mod.id === m.modifier.id),
      }));
      setSortedCategoryItemModifiers(newCategoryItemMods);
    }
  }, [categoryItem, category_item_modifiers, modifiers]);

  useEffect(() => {
    if (sku) {
      const s = skus.find((s) => s.id === sku.id);
      const values = setSkuValuesFunc([[s], NO_IMAGE]);
      const skuFormValuesOverride = overrideValues(s);
      form.setFieldsValue(skuFormValuesOverride[0]);
      setDisabled(false);
      setSkuValues(values[0]);
    }
  }, [sku, skus, form, NO_IMAGE]);

  const handleImageUpload = (file, platformName) => {
    const data = new FormData();
    data.append("files", file);
    data.append("ref", "sku");
    data.append("refId", skuId);
    data.append("field", `image_${platformName}`);
    if (platformName === "uber_eats") {
      updateUberEatsSkuImage(data, setPercent);
    } else if (platformName === "doordash") {
      updateDoorDashSkuImage(data, setPercent);
    } else {
      updateGrubHubSkuImage(data, setPercent);
    }
  };

  const showDeletePopconfirm = () => {
    setDeletePopConfirmVisible(true);
  };

  const handleDeletePopConfirmOk = () => {
    const { id } = categoryItem;
    deleteCategoryItem(id);
    setDeletePopConfirmVisible(false);
    handleOnDrawerClose();
  };

  const handleDeletePopConfirmCancel = () => {
    setDeletePopConfirmVisible(false);
  };

  const handleOnDrawerClose = () => {
    setItemsVisible(false);
    setCategoryItem(null);
    setModifierGroups(null);
    setSku(null);
    setSelectedSku(null);
    setSkuValues(null);
    setAvailable(true);
    setDisabled(true);
    form.resetFields();
    if (!categoryItem) {
      form.setFieldsValue({ category: categoryId });
    }
  };

  const handlePopConfirmOk = () => {
    handleOnDrawerClose();
    setCancelPopConfirmVisible(false);
  };

  const handlePopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };

  const handleOnFormSubmit = (values) => {
    const { sku, name, name_override, price, price_override, description, category } = values;
    const categoryItemId = categoryItem.id;
    const menuItemId = _.get(categoryItem, "menu_item.id");
    const { sort } = categoryItem;
    updateMenuItem(menuItemId, {
      menu: menuId,
      skus: sku,
      name: name,
      price: price,
      available: available,
      description: description,
    });
    updateCategoryItem(categoryItemId, {
      menu_item: menuItemId,
      category: category,
      name_override: name_override || null,
      price_override: price_override,
      category_item_modifiers: categoryItemModifierIds,
      sort: sort,
    });
    handleOnDrawerClose();
  };

  return (
    <>
      <Drawer
        className="editingModifier-component"
        title={
          <>
            {_.get(categoryItem, "item")}
            <Divider className="divider" type="vertical" />
            {_.get(categoryToEdit, "category")}
          </>
        }
        width={"60%"}
        placement="right"
        visible={itemsVisible}
        onClose={() => handleOnDrawerClose()}
        zIndex={1}
        bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
        destroyOnClose={true}
        closable={false}
        maskClosable={true}
        footerStyle={
          categoryItem
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", justifyContent: "flex-end" }
        }
        footer={
          <>
            {categoryItem ? (
              <Button
                onClick={() => {
                  setCategoryItemModifierModalVisible(true);
                }}
              >
                Add Modifier
              </Button>
            ) : null}
            <div>
              <Popconfirm
                title="Are you sure? Your changes won't be saved."
                style={{ width: 100 }}
                visible={cancelPopConfirmVisible}
                onConfirm={handlePopConfirmOk}
                onCancel={handlePopConfirmCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="cancel-button"
                  onClick={() => {
                    disabled ? handleOnDrawerClose() : setCancelPopConfirmVisible(true);
                  }}
                >
                  Cancel
                </Button>
              </Popconfirm>
              <Button className="save-button" disabled={disabled} onClick={() => form.submit()}>
                Save
              </Button>
            </div>
          </>
        }
      >
        <Form
          form={form}
          className="category-item-form"
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={(values) => handleOnFormSubmit(values)}
          scrollToFirstError={true}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Col style={{ paddingTop: 2 }}>
              <Popconfirm
                title="Are you sure you want to remove this item?"
                visible={deletePopConfirmVisible}
                onConfirm={handleDeletePopConfirmOk}
                onCancel={handleDeletePopConfirmCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" onClick={() => showDeletePopconfirm()}>
                  Remove Item
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter item name" }]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={8} style={{ marginLeft: 18 }}>
              <Form.Item name="sku" label="Sku" rules={[{ required: true, message: "Please select sku" }]}>
                <Tooltip
                  title={
                    _.get(categoryItem, "menu_item.skus") || selectedSku ? (
                      <>
                        {_.get(skuValues, "name", "")} <br />
                        {_.get(skuValues, "id", "")}
                      </>
                    ) : null
                  }
                >
                  <Button
                    type="link"
                    onClick={() => setSkuModalVisible(true)}
                    style={{
                      padding: 0,
                    }}
                  >
                    {_.get(categoryItem, "menu_item.skus") || selectedSku ? (
                      <div id="sku-div">
                        {_.get(skuValues, "name", "")}
                        <Divider className="divider" type="vertical" />
                        {_.get(skuValues, "owner", "")
                          ? _.get(skuValues, "owner", "")
                          : _.get(
                              _.find(skus, (sku) => _.get(sku, "owner.id") === _.get(categoryItem, "skus.owner")),
                              "owner.name"
                            )}
                      </div>
                    ) : (
                      <span>Search Sku</span>
                    )}
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="name_override" label="Name Override">
                <Input placeholder="Name Override" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item name="price" label="Price" rules={[{ required: true, message: "Please enter price" }]}>
                <InputNumber min={0} step={0.01} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="price_override" label="Price Override">
                <InputNumber min={0} step={0.01} />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: 8 }}>
            <span>Images</span>
          </Row>
          <Row gutter={16} className="image-row">
            <Col span={8} className="image-column">
              <Form.Item name="uber_image">
                <Dragger
                  name="file"
                  multiple={false}
                  showUploadList={false}
                  onChange={() => setDisabled(false)}
                  action={(file) => handleImageUpload(file, "uber_eats")}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <div id="image-div">
                    {uberEatsImageStatus === "pending" ? (
                      <Progress id="progress-bar" percent={percent} size="small" status="active" />
                    ) : (
                      <Image
                        id="item-image"
                        src={_.get(skuValues, "ubereats.url", "")}
                        alt="uber eats"
                        fallback={NO_IMAGE}
                        preview={false}
                      />
                    )}
                  </div>
                </Dragger>
              </Form.Item>
              <div id="label-upload-div">
                <h4 id="image-label">UberEats</h4>
                <Upload
                  name="file"
                  showUploadList={false}
                  action={(file) => handleImageUpload(file, "uber_eats")}
                  onChange={() => setDisabled(false)}
                  multiple={false}
                >
                  <Button type="link" icon={<UploadOutlined />} />
                </Upload>
              </div>
            </Col>
            <Col span={8} className="image-column">
              <Form.Item name="doordash_image">
                <Dragger
                  name="file"
                  multiple={false}
                  showUploadList={false}
                  onChange={() => setDisabled(false)}
                  action={(file) => handleImageUpload(file, "doordash")}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <div id="image-div">
                    {doorDashImageStatus === "pending" ? (
                      <Progress id="progress-bar" percent={percent} size="small" status="active" />
                    ) : (
                      <Image
                        id="item-image"
                        src={_.get(skuValues, "doordash.url", "")}
                        alt="doordash"
                        fallback={NO_IMAGE}
                        preview={false}
                      />
                    )}
                  </div>
                </Dragger>
              </Form.Item>
              <div id="label-upload-div">
                <h4 id="image-label">DoorDash</h4>
                <Upload
                  name="file"
                  showUploadList={false}
                  action={(file) => handleImageUpload(file, "doordash")}
                  onChange={() => setDisabled(false)}
                  multiple={false}
                >
                  <Button type="link" icon={<UploadOutlined />} />
                </Upload>
              </div>
            </Col>
            <Col span={8} className="image-column">
              <Form.Item name="grubhub_image">
                <Dragger
                  name="file"
                  multiple={false}
                  showUploadList={false}
                  onChange={() => setDisabled(false)}
                  action={(file) => handleImageUpload(file, "grubhub")}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <div id="image-div">
                    {grubHubImageStatus === "pending" ? (
                      <Progress id="progress-bar" percent={percent} size="small" status="active" />
                    ) : (
                      <Image
                        id="item-image"
                        src={_.get(skuValues, "grubhub.url", "")}
                        alt="grubhub"
                        fallback={NO_IMAGE}
                        preview={false}
                      />
                    )}
                  </div>
                </Dragger>
              </Form.Item>
              <div id="label-upload-div">
                <h4 id="image-label">GrubHub</h4>
                <Upload
                  name="file"
                  showUploadList={false}
                  action={(file) => handleImageUpload(file, "grubhub")}
                  onChange={() => setDisabled(false)}
                  multiple={false}
                >
                  <Button type="link" icon={<UploadOutlined />} />
                </Upload>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea rows={4} showCount maxLength={500} placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <CategoryItemModifierTable
          categoryItem={categoryItem}
          categoryItemModifiers={sortedCategoryItemModifiers}
          setSelectedModifier={setSelectedModifier}
          setModifierDrawerVisible={setModifierDrawerVisible}
        />
        <AddCategoryItemModifierModal
          categoryItem={categoryItem}
          categoryItemModifierModalVisible={categoryItemModifierModalVisible}
          setCategoryItemModifierModalVisible={setCategoryItemModifierModalVisible}
        />
        <SkuModal
          categoryItem={categoryItem}
          setSku={setSku}
          skuModalVisible={skuModalVisible}
          setSkuModalVisible={setSkuModalVisible}
          selectedSku={selectedSku}
          setSelectedSku={setSelectedSku}
        />
        <CategoryItemModifierDrawer
          categoryItem={categoryItem}
          selectedModifier={selectedModifier}
          setSelectedModifier={setSelectedModifier}
          modifierDrawerVisible={modifierDrawerVisible}
          setModifierDrawerVisible={setModifierDrawerVisible}
          skus={skus}
        />
      </Drawer>
    </>
  );
};

export default connect(null, {
  updateMenuItem,
  updateCategoryItem,
  deleteCategoryItem,
  uploadMediaFile,
  updateUberEatsSkuImage,
  updateDoorDashSkuImage,
  updateGrubHubSkuImage,
})(CategoryItemDrawer);
