import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Layout } from "antd";
import PageHeader from "./components/Header";
import HomePage from "./containers/HomePage";
import MenuPage from "./containers/MenuPage";
import LoginPage from "./containers/LoginPage";
import PrivateRoute from "./containers/PrivateRoute";
import LoginRedirect from "./containers/LoginRedirect";
import { HomePageFooter } from "./components/Footers";
import { getMenus } from "./actions/menus";
import { getBrands } from "./actions/brands";
import { getSkus } from "./actions/skus";
import { getTargets } from "./actions/targets";

import "./App.less";

const App = (props) => {
  const [searchPattern, setSearchPattern] = useState("");
  const [menuModalVisible, setMenuModalVisible] = useState(false);
  const [publishModalVisible, setPublishModalVisible] = useState(false);
  const [modifierVisible, setModifierVisible] = useState(false);
  const [itemsVisible, setItemsVisible] = useState(false);

  const { getMenus, getBrands, getSkus, getTargets } = props;

  const { Content } = Layout;

  useEffect(() => {
    getMenus();
    getBrands();
    getSkus();
    getTargets();
  }, [getMenus, getBrands, getSkus, getTargets]);

  const onInputChange = (e) => {
    const { value } = e.target;
    setSearchPattern(value);
  };

  return (
    <Router>
      <div className="App">
        <Layout className="layout">
          <Route exact path={["/", "/menu/:id"]}>
            <PageHeader
              className="main-header"
              onInputChange={onInputChange}
              setMenuModalVisible={setMenuModalVisible}
              setPublishModalVisible={setPublishModalVisible}
              // toggleOn={toggleOn}
              // setToggleOn={setToggleOn}
            />
          </Route>
          <Content className="content">
            <Switch>
              <Route path="/login">
                <LoginPage className="loginPage" />
              </Route>
              <Route path="/connect/:providerName/redirect">
                <LoginRedirect />
              </Route>
              <PrivateRoute
                exact
                path="/"
                className="homePage"
                searchPattern={searchPattern}
                setSearchPattern={setSearchPattern}
                menuModalVisible={menuModalVisible}
                setMenuModalVisible={setMenuModalVisible}
                component={HomePage}
              />
              <PrivateRoute
                path="/menu/:id"
                publishModalVisible={publishModalVisible}
                setPublishModalVisible={setPublishModalVisible}
                itemsVisible={itemsVisible}
                setItemsVisible={setItemsVisible}
                modifierVisible={modifierVisible}
                setModifierVisible={setModifierVisible}
                component={MenuPage}
              />
            </Switch>
          </Content>
          <Route exact path="/">
            <HomePageFooter className="main-footer" setMenuModalVisible={setMenuModalVisible} />
          </Route>
        </Layout>
      </div>
    </Router>
  );
};

export default connect(null, {
  getMenus,
  getBrands,
  getSkus,
  getTargets,
})(App);
