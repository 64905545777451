import React from 'react'

import MenuInternalPromoTable from '../MenuInternalPromoTable'
import { InternalPromosFooter } from '../Footers'

import './styles.css'

const InternalPromoComponent = ({
  setInternalPromosModalVisible, selectedInternalPromo, setSelectedInternalPromo
}) => {
  return (<>
    <div className="targets-container">
      <MenuInternalPromoTable setSelectedInternalPromo={setSelectedInternalPromo}
                              selectedInternalPromo={selectedInternalPromo}/>
    </div>
    <InternalPromosFooter setInternalPromosModalVisible={setInternalPromosModalVisible}/>
  </>)
}

export default InternalPromoComponent