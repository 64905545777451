import {
  FETCH_MENUS_START,
  FETCH_MENUS_SUCCESS,
  FETCH_MENUS_FAILURE,
  DELETE_MENU_START,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAILURE,
} from "../constants";

const initialState = {
  menus: [],
  status: "idle",
  error: "",
};

const menusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENUS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_MENUS_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        menus: action.payload,
      };
    case FETCH_MENUS_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case DELETE_MENU_START:
      return {
        ...state,
        status: "pending",
      };
    case DELETE_MENU_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        menus: [
          ...state.menus.slice(
            0,
            state.menus.findIndex((m) => m.id.toString() === action.payload.id.toString())
          ),
          ...state.menus.slice(state.menus.findIndex((m) => m.id.toString() === action.payload.id.toString()) + 1),
        ],
      };
    case DELETE_MENU_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default menusReducer;
