import React from "react";
import { Popconfirm, Avatar, Skeleton, Space, Spin } from "antd";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import Logo from "../../Logo";
import ProfilePicture from "../../ProfilePicture";
// import Toggle from "../Toggle";
import { SearchInput } from "../../Input";
import { ButtonComponent } from "../../Button";

import _ from "lodash";

export const renderHomePageHeaderSkeleton = (props) => {
  return (
    <>
      <div className="search-div">
        <>
          <Skeleton.Avatar className="skeleton-avatar-home" active={true} size={40} shape="circle" />
          <Skeleton.Input className="skeleton-input-home" style={{ width: 300 }} active={true} size="default" />
        </>
      </div>
      <div className="profile">
        <Skeleton.Avatar className="skeleton-avatar-home" active={true} size={40} shape="circle" />
      </div>
    </>
  );
};

export const renderHomePageHeaderContent = (props) => {
  const {
    onInputChange,
    // toggleOn,
    // setToggleOn
  } = props;
  return (
    <>
      <div className="search-div">
        <>
          <Logo className="header-logo" />
          <SearchInput onInputChange={onInputChange} />
          {/* <div className="home-toggle-button">
          <Space>
            <Text strong={true}>List</Text>
            <Toggle toggleOn={toggleOn} setToggleOn={setToggleOn} />
            <Text strong={true}>Board</Text>
          </Space>
        </div> */}
        </>
      </div>
      <div className="profile">
        <ProfilePicture />
      </div>
    </>
  );
};

export const renderMenuPageHeaderSkeleton = (props) => {
  return (
    <>
      <div className="left-div">
        <div className="menu-div">
          <div className="brand-logo-div">
            <Skeleton.Avatar className="skeleton-avatar" active={true} size={40} shape="circle" />
          </div>
          <div className="menu-title-div">
            <Skeleton.Input className="skeleton-input" style={{ width: 300 }} active={true} size="default" />
          </div>
          <div className="tag-div">
            <Skeleton.Input
              className="skeleton-input"
              style={{ width: 100, marginTop: 12 }}
              active={true}
              size="default"
            />
          </div>
        </div>
      </div>
      <div className="right-div">
        <Space size="middle">
          <Skeleton.Button
            className="skeleton-buttons"
            active={true}
            size="default"
            shape="default"
            block={false}
            style={{ width: 100 }}
          />
          <Skeleton.Button
            className="skeleton-buttons"
            active={true}
            size="default"
            shape="default"
            block={false}
            style={{ width: 100 }}
          />
          <div className="profile">
            <Skeleton.Avatar className="skeleton-avatar-home" active={true} size={40} shape="circle" />
          </div>
        </Space>
      </div>
    </>
  );
};

export const renderMenuPageHeaderContent = (props) => {
  const {
    history,
    menu,
    deletePopConfirmVisible,
    handleDeletePopConfirmOk,
    handleDeletePopConfirmCancel,
    showDeletePopconfirm,
    setPublishModalVisible,
    NO_IMAGE,
    publishStatus,
  } = props;

  return (
    <>
      <div className="left-div">
        <div className="back-div">
          <ArrowLeftOutlined className="back-arrow" onClick={() => history.push("/")} />
        </div>
        <div className="menu-div">
          <div className="brand-logo-div">
            <Avatar className="brand-logo" src={_.get(menu, "brand.logo.formats.thumbnail.url", NO_IMAGE)} size={40} />
          </div>
          <div className="menu-title-div">
            <h2 className="menu-title">
              <em>{_.get(menu, "title", "")}</em>
            </h2>
          </div>
          <div className="tag-div">
            {publishStatus === "pending" ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            ) : null}
            {/* <Tag color="blue">Published on: {date}</Tag> */}
          </div>
        </div>
      </div>
      <div className="right-div">
        <Popconfirm
          title="Are you sure you want to delete this menu?"
          visible={deletePopConfirmVisible}
          onConfirm={handleDeletePopConfirmOk}
          onCancel={handleDeletePopConfirmCancel}
          okText="Yes"
          cancelText="No"
        >
          <ButtonComponent
            className="delete-menu-button"
            text="Delete Menu"
            type="danger"
            onClick={() => showDeletePopconfirm()}
          />
        </Popconfirm>
        <ButtonComponent
          className="publish-button"
          disabled={false}
          text="Publish"
          onClick={() => setPublishModalVisible(true)}
        />
        <div className="profile">
          <ProfilePicture />
        </div>
      </div>
    </>
  );
};
