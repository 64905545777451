import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, Form, Row, Col, Input, InputNumber, Button, Popconfirm, Space } from "antd";

import { addModifier } from "../../../actions/modifiers";

const AddModifierModal = (props) => {
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);
  const [maxAllowedValue, setMaxAllowedValue] = useState(0);
  const [minAllowedValue, setMinAllowedValue] = useState(0);
  const [validateStatus, setValidateStatus] = useState("success");
  const [errorMsg, setErrorMsg] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { modifierModalVisible, setModifierModalVisible, addModifier } = props;

  const [form] = Form.useForm();

  const menu = useSelector((state) => state.menu.menu);

  useEffect(() => {
    function validateMinMaxAllowed(min, max) {
      if (min > max) {
        setValidateStatus("error");
        setErrorMsg("min allowed can't be greater than max allowed!");
        setDisabled(true);
      } else {
        setValidateStatus("success");
        setErrorMsg(null);
      }
    }
    validateMinMaxAllowed(minAllowedValue, maxAllowedValue);
  }, [minAllowedValue, maxAllowedValue]);

  const handleSubmit = async (values) => {
    const { name, min_allowed, max_allowed } = values;
    addModifier({
      menu: menu.id,
      name: name,
      min_allowed: min_allowed,
      max_allowed: max_allowed,
    });
    setModifierModalVisible(false);
    setValidateStatus("success");
    setErrorMsg(null);
    setDisabled(true);
    form.resetFields();
  };

  const handleClose = () => {
    setModifierModalVisible(false);
    setDisabled(true);
    setMaxAllowedValue(0);
    setMinAllowedValue(0);
    form.resetFields();
  };

  const handlePopConfirmOk = () => {
    handleClose();
    setCancelPopConfirmVisible(false);
  };

  const handlePopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };

  return (
    <>
      <Modal
        title="Add Modifier Group"
        centered={true}
        visible={modifierModalVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        zIndex={1}
        footer={
          <Space>
            <Popconfirm
              title="Are you sure? Your changes won't be saved."
              style={{ width: 100 }}
              visible={cancelPopConfirmVisible}
              onConfirm={handlePopConfirmOk}
              onCancel={handlePopConfirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button onClick={() => (disabled ? handleClose() : setCancelPopConfirmVisible(true))}>Cancel</Button>
            </Popconfirm>
            <Button disabled={disabled} onClick={() => form.submit()}>
              Save
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          requiredMark={true}
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter item name",
                  },
                ]}
              >
                <Input placeholder="Please enter item name" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: "left" }}>
              <Form.Item name={"min_allowed"} label="min allowed :" validateStatus={validateStatus} help={errorMsg}>
                <InputNumber
                  min={0}
                  max={maxAllowedValue ? maxAllowedValue : 10}
                  onChange={(value) => setMinAllowedValue(value)}
                />
              </Form.Item>
            </Col>
            <Col span={6} style={{ textAlign: "left" }}>
              <Form.Item name={"max_allowed"} label="max allowed :">
                <InputNumber
                  min={minAllowedValue ? minAllowedValue : 0}
                  max={10}
                  onChange={(value) => setMaxAllowedValue(value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, { addModifier })(AddModifierModal);
