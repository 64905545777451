// LOGIN
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// GET MENUS
export const FETCH_MENUS_START = "FETCH_MENUS_START";
export const FETCH_MENUS_SUCCESS = "FETCH_MENUS_SUCCESS";
export const FETCH_MENUS_FAILURE = "FETCH_MENUS_FAILURE";

// GET MENU
export const FETCH_MENU_START = "FETCH_MENU_START";
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";
export const FETCH_MENU_FAILURE = "FETCH_MENU_FAILURE";

// ADD MENU
export const ADD_MENU_START = "ADD_MENU_START";
export const ADD_MENU_SUCCESS = "ADD_MENU_SUCCESS";
export const ADD_MENU_FAILURE = "ADD_MENU_FAILURE";

// UPDATE MENU
export const UPDATE_MENU_START = "UPDATE_MENU_START";
export const UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS";
export const UPDATE_MENU_FAILURE = "UPDATE_MENU_FAILURE";

// DELETE MENU
export const DELETE_MENU_START = "DELETE_MENU_START";
export const DELETE_MENU_SUCCESS = "DELETE_MENU_SUCCESS";
export const DELETE_MENU_FAILURE = "DELETE_MENU_FAILURE";

// PUBLISH MENU
export const PUBLISH_MENU_START = "PUBLISH_MENU_START";
export const PUBLISH_MENU_SUCCESS = "PUBLISH_MENU_SUCCESS";
export const PUBLISH_MENU_FAILURE = "PUBLISH_MENU_FAILURE";

// CLONE MENU
export const CLONE_MENU_START = "CLONE_MENU_START";
export const CLONE_MENU_SUCCESS = "CLONE_MENU_SUCCESS";
export const CLONE_MENU_FAILURE = "CLONE_MENU_FAILURE";

// GET BRANDS
export const FETCH_BRANDS_START = "FETCH_BRANDS_START";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_FAILURE = "FETCH_BRANDS_FAILURE";

// UPDATE MENU-TARGETS
export const UPDATE_MENU_TARGETS_START = "UPDATE_MENU_TARGETS_START";
export const UPDATE_MENU_TARGETS_SUCCESS = "UPDATE_MENU_TARGETS_SUCCESS";
export const UPDATE_MENU_TARGETS_FAILURE = "UPDATE_MENU_TARGETS_FAILURE";

// UPDATE MENU-INTERNAL_PROMOS
export const UPDATE_INTERNAL_PROMO_START = "UPDATE_INTERNAL_PROMO_START";
export const UPDATE_INTERNAL_PROMO_SUCCESS = "UPDATE_INTERNAL_PROMO_SUCCESS";
export const UPDATE_INTERNAL_PROMO_FAILURE = "UPDATE_INTERNAL_PROMO_FAILURE";

// GET SKUS
export const FETCH_SKUS_START = "FETCH_SKUS_START";
export const FETCH_SKUS_SUCCESS = "FETCH_SKUS_SUCCESS";
export const FETCH_SKUS_FAILURE = "FETCH_SKUS_FAILURE";

// GET MENU TARGETS
export const FETCH_TARGETS_START = "FETCH_TARGETS_START";
export const FETCH_TARGETS_SUCCESS = "FETCH_TARGETS_SUCCESS";
export const FETCH_TARGETS_FAILURE = "FETCH_TARGETS_FAILURE";

// GET CATEGORIES
export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

// ADD CATEGORY
export const ADD_CATEGORY_START = "ADD_CATEGORY_START";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

// UPDATE CATEGORY
export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

// DELETE CATEGORY
export const DELETE_CATEGORY_START = "DELETE_CATEGORY_START";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

// FETCH MENU-ITEM
export const FETCH_MENU_ITEMS_START = "FETCH_MENU_ITEMS_START";
export const FETCH_MENU_ITEMS_SUCCESS = "FETCH_MENU_ITEMS_SUCCESS";
export const FETCH_MENU_ITEMS_FAILURE = "FETCH_MENU_ITEMS_FAILURE";

// ADD MENU-ITEM
export const ADD_MENU_ITEM_START = "ADD_MENU_ITEM_START";
export const ADD_MENU_ITEM_SUCCESS = "ADD_MENU_ITEM_SUCCESS";
export const ADD_MENU_ITEM_FAILURE = "ADD_MENU_ITEM_FAILURE";

// UPDATE MENU-ITEM
export const UPDATE_MENU_ITEM_START = "UPDATE_MENU_ITEM_START";
export const UPDATE_MENU_ITEM_SUCCESS = "UPDATE_MENU_ITEM_SUCCESS";
export const UPDATE_MENU_ITEM_FAILURE = "UPDATE_MENU_ITEM_FAILURE";

// DELETE MENU-ITEM
export const DELETE_MENU_ITEM_START = "DELETE_MENU_ITEM_START";
export const DELETE_MENU_ITEM_SUCCESS = "DELETE_MENU_ITEM_SUCCESS";
export const DELETE_MENU_ITEM_FAILURE = "DELETE_MENU_ITEM_FAILURE";

// CLEAR MENU ITEMS
export const CLEAR_MENU_ITEMS = "CLEAR_MENU_ITEMS";

// FETCH CATEGORY-ITEMS
export const FETCH_CATEGORY_ITEMS_START = "FETCH_CATEGORY_ITEMS_START";
export const FETCH_CATEGORY_ITEMS_SUCCESS = "FETCH_CATEGORY_ITEMS_SUCCESS";
export const FETCH_CATEGORY_ITEMS_FAILURE = "FETCH_CATEGORY_ITEMS_FAILURE";

// ADD CATEGORY-ITEM
export const ADD_CATEGORY_ITEM_START = "ADD_CATEGORY_ITEM_START";
export const ADD_CATEGORY_ITEM_SUCCESS = "ADD_CATEGORY_ITEM_SUCCESS";
export const ADD_CATEGORY_ITEM_FAILURE = "ADD_CATEGORY_ITEM_FAILURE";

// UPDATE CATEGORY-ITEM
export const UPDATE_CATEGORY_ITEM_START = "UPDATE_CATEGORY_ITEM_START";
export const UPDATE_CATEGORY_ITEM_SUCCESS = "UPDATE_CATEGORY_ITEM_SUCCESS";
export const UPDATE_CATEGORY_ITEM_FAILURE = "UPDATE_CATEGORY_ITEM_FAILURE";

// DELETE CATEGORY-ITEM
export const DELETE_CATEGORY_ITEM_START = "DELETE_CATEGORY_ITEM_START";
export const DELETE_CATEGORY_ITEM_SUCCESS = "DELETE_CATEGORY_ITEM_SUCCESS";
export const DELETE_CATEGORY_ITEM_FAILURE = "DELETE_CATEGORY_ITEM_FAILURE";

// GET MODIFIERS
export const FETCH_MODIFIERS_START = "FETCH_MODIFIERS_START";
export const FETCH_MODIFIERS_SUCCESS = "FETCH_MODIFIERS_SUCCESS";
export const FETCH_MODIFIERS_FAILURE = "FETCH_MODIFIERS_FAILURE";

// GET CATEGORY ITEM MODIFIERS
export const FETCH_CATEGORY_ITEM_MODIFIERS_START = "FETCH_CATEGORY_ITEM_MODIFIERS_START";
export const FETCH_CATEGORY_ITEM_MODIFIERS_SUCCESS = "FETCH_CATEGORY_ITEM_MODIFIERS_SUCCESS";
export const FETCH_CATEGORY_ITEM_MODIFIERS_FAILURE = "FETCH_CATEGORY_ITEM_MODIFIERS_FAILURE";

// ADD MODIFIER
export const ADD_MODIFIER_START = "ADD_MODIFIER_START";
export const ADD_MODIFIER_SUCCESS = "ADD_MODIFIER_SUCCESS";
export const ADD_MODIFIER_FAILURE = "ADD_MODIFIER_FAILURE";

// UPDATE MODIFIER
export const UPDATE_MODIFIER_START = "UPDATE_MODIFIER_START";
export const UPDATE_MODIFIER_SUCCESS = "UPDATE_MODIFIER_SUCCESS";
export const UPDATE_MODIFIER_FAILURE = "UPDATE_MODIFIER_FAILURE";

// DELETE MODIFIER
export const DELETE_MODIFIER_START = "DELETE_MODIFIER_START";
export const DELETE_MODIFIER_SUCCESS = "DELETE_MODIFIER_SUCCESS";
export const DELETE_MODIFIER_FAILURE = "DELETE_MODIFIER_FAILURE";

// GET MODIFIER-ITEMS
export const FETCH_MODIFIER_ITEMS_START = "FETCH_MODIFIER_ITEMS_START";
export const FETCH_MODIFIER_ITEMS_SUCCESS = "FETCH_MODIFIER_ITEMS_SUCCESS";
export const FETCH_MODIFIER_ITEMS_FAILURE = "FETCH_MODIFIER_ITEMS_FAILURE";

// GET MENU_SKUS
export const FETCH_MENU_SKUS_START = "FETCH_MENU_SKUS_START";
export const FETCH_MENU_SKUS_SUCCESS = "FETCH_MENU_SKUS_SUCCESS";
export const FETCH_MENU_SKUS_FAILURE = "FETCH_MENU_SKUS_FAILURE";

// ADD MODIFIER-ITEM
export const ADD_MODIFIER_ITEM_START = "ADD_MODIFIER_ITEM_START";
export const ADD_MODIFIER_ITEM_SUCCESS = "ADD_MODIFIER_ITEM_SUCCESS";
export const ADD_MODIFIER_ITEM_FAILURE = "ADD_MODIFIER_ITEM_FAILURE";

// UPDATE MODIFIER-ITEM
export const UPDATE_MODIFIER_ITEM_START = "UPDATE_MODIFIER_ITEM_START";
export const UPDATE_MODIFIER_ITEM_SUCCESS = "UPDATE_MODIFIER_ITEM_SUCCESS";
export const UPDATE_MODIFIER_ITEM_FAILURE = "UPDATE_MODIFIER_ITEM_FAILURE";

// DELETE MODIFIER-ITEM
export const DELETE_MODIFIER_ITEM_START = "DELETE_MODIFIER_ITEM_START";
export const DELETE_MODIFIER_ITEM_SUCCESS = "DELETE_MODIFIER_ITEM_SUCCESS";
export const DELETE_MODIFIER_ITEM_FAILURE = "DELETE_MODIFIER_ITEM_FAILURE";

// ADD-CATEGORY-ITEM-MODIFIER
export const ADD_CATEGORY_ITEM_MODIFIER_START = "ADD_CATEGORY_ITEM_MODIFIER_START";
export const ADD_CATEGORY_ITEM_MODIFIER_SUCCESS = "ADD_CATEGORY_ITEM_MODIFIER_SUCCESS";
export const ADD_CATEGORY_ITEM_MODIFIER_FAILURE = "ADD_CATEGORY_ITEM_MODIFIER_FAILURE";

// UPDATE CATEGORY-ITEM-MODIFIER
export const UPDATE_CATEGORY_ITEM_MODIFIER_START = "UPDATE_CATEGORY_ITEM_MODIFIER_START";
export const UPDATE_CATEGORY_ITEM_MODIFIER_SUCCESS = "UPDATE_CATEGORY_ITEM_MODIFIER_SUCCESS";
export const UPDATE_CATEGORY_ITEM_MODIFIER_FAILURE = "UPDATE_CATEGORY_ITEM_MODIFIER_FAILURE";

// DELETE-CATEGORY-ITEM-MODIFIER
export const DELETE_CATEGORY_ITEM_MODIFIER_START = "DELETE_CATEGORY_ITEM_MODIFIER_START";
export const DELETE_CATEGORY_ITEM_MODIFIER_SUCCESS = "DELETE_CATEGORY_ITEM_MODIFIER_SUCCESS";
export const DELETE_CATEGORY_ITEM_MODIFIER_FAILURE = "DELETE_CATEGORY_ITEM_MODIFIER_FAILURE";

// MEDIA UPLOAD
export const MEDIA_UPLOAD_START = "MEDIA_UPLOAD_START";
export const MEDIA_UPLOAD_SUCCESS = "MEDIA_UPLOAD_SUCCESS";
export const MEDIA_UPLOAD_FAILURE = "MEDIA_UPLOAD_FAILURE";

// UPDATE UBEREATS SKU IMAGE
export const UPDATE_UBEREATS_SKU_IMAGE_START = "UPDATE_UBEREATS_SKU_IMAGE_START";
export const UPDATE_UBEREATS_SKU_IMAGE_SUCCESS = "UPDATE_UBEREATS_SKU_IMAGE_SUCCESS";
export const UPDATE_UBEREATS_SKU_IMAGE_FAILURE = "UPDATE_UBEREATS_SKU_IMAGE_FAILURE";

// UPDATE DOORDASH SKU IMAGE
export const UPDATE_DOORDASH_SKU_IMAGE_START = "UPDATE_DOORDASH_SKU_IMAGE_START";
export const UPDATE_DOORDASH_SKU_IMAGE_SUCCESS = "UPDATE_DOORDASH_SKU_IMAGE_SUCCESS";
export const UPDATE_DOORDASH_SKU_IMAGE_FAILURE = "UPDATE_DOORDASH_SKU_IMAGE_FAILURE";

// UPDATE GRUBHUB SKU IMAGE
export const UPDATE_GRUBHUB_SKU_IMAGE_START = "UPDATE_GRUBHUB_SKU_IMAGE_START";
export const UPDATE_GRUBHUB_SKU_IMAGE_SUCCESS = "UPDATE_GRUBHUB_SKU_IMAGE_SUCCESS";
export const UPDATE_GRUBHUB_SKU_IMAGE_FAILURE = "UPDATE_GRUBHUB_SKU_IMAGE_FAILURE";

// RESET SKU IMAGES
export const RESET_SKU_IMAGES = "RESET_SKU_IMAGES";

// MESSAGE DURATION
export const MESSAGE_DURATION = 10;


export const ENV = window.location.hostname === "localhost"
  ? "DEV"
  : window.location.hostname.includes("sandbox")
  ? "SANDBOX"
  : "PROD";

export const IS_PROD = ENV === "PROD";

// forces publishing all locations, which will also trigger a menu snapshot sync (internally menu system)
export const USE_MENU_SNAPSHOT = true;

export const INTERNAL_PROMO_TYPES = [
  "BASKET_SIZE",
  "NEW_CUSTOMER",
  "RETURN_CUSTOMER"
]

export const INTERNAL_PLATFORMS = [
  'All',
  'Doordash',
  'UberEats'
]
