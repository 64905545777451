import axios from "axios";

export const axiosWithAuth = (url) => {
  const token = localStorage.getItem("jwt");
  return axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
