import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Modal, Form, Input, Row, Col, Space, Button, Popconfirm, Select } from "antd";

import { cloneMenu } from "../../../actions/menus";

import _ from "lodash";

const CloneMenuModal = (props) => {
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { menuToClone, setMenuToClone, cloneMenuModalVisible, setCloneMenuModalVisible, cloneMenu } = props;

  const [form] = Form.useForm();

  const { Option } = Select;

  const history = useHistory();

  const brands = useSelector((state) => state.brands.brands);

  const sortedBrands = brands.slice();

  sortedBrands.sort((a, b) => {
    let nameA = a.name ? a.name.toLowerCase() : null;
    let nameB = b.name ? b.name.toLowerCase() : null;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const handleSubmit = (values) => {
    const { title, brand } = values;
    const { id } = menuToClone;
    cloneMenu(id, title, brand, history);
    setCloneMenuModalVisible(false);
    setMenuToClone(null);
    setDisabled(true);
    form.resetFields();
  };

  const handleClose = () => {
    setCloneMenuModalVisible(false);
    setMenuToClone(null);
    setDisabled(true);
    form.resetFields();
  };

  const handlePopConfirmOk = () => {
    handleClose();
    setCancelPopConfirmVisible(false);
  };

  const handlePopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };

  return (
    <>
      <Modal
        title={`Clone ${_.get(menuToClone, "title", "Menu")}`}
        centered={true}
        visible={cloneMenuModalVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        footer={
          <Space>
            <Popconfirm
              title="Are you sure? Your changes won't be saved."
              style={{ width: 100 }}
              visible={cancelPopConfirmVisible}
              onConfirm={handlePopConfirmOk}
              onCancel={handlePopConfirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button onClick={() => (disabled ? handleClose() : setCancelPopConfirmVisible(true))}>Cancel</Button>
            </Popconfirm>
            <Button disabled={disabled} onClick={() => form.submit()}>
              Ok
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="brand"
                label="Brand"
                rules={[
                  {
                    required: true,
                    message: "Please select a brand",
                  },
                ]}
              >
                <Select allowClear showSearch placeholder="Search brand" onChange={() => setDisabled(false)}>
                  {_.map(sortedBrands, (brand) => (
                    <Option key={brand.id} value={brand.id}>
                      {brand.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter menu title",
                  },
                ]}
              >
                <Input placeholder="Menu title" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, { cloneMenu })(CloneMenuModal);
