import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, Form, Row, Col, Input, Space, Button, Popconfirm } from "antd";

import { addCategory } from "../../../actions/categories";

const AddCategoryModal = (props) => {
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { categoryModalVisible, setCategoryModalVisible, addCategory, menu } = props;

  const [form] = Form.useForm();

  const categories = useSelector((state) => state.categories.categories);

  const handleSubmit = (values) => {
    const { name, description } = values;
    if (categories.length === 0) {
      const sort = 1;
      addCategory({
        name: name,
        description: description,
        menu: menu.id,
        sort: sort,
      });
    } else {
      const sort =
        parseInt(
          categories.sort((a, b) => parseInt(a.sort, 10) - parseInt(b.sort, 10))[categories.length - 1].sort,
          10
        ) + 1;
      addCategory({
        name: name,
        description: description,
        menu: menu.id,
        sort: sort,
      });
    }
    setCategoryModalVisible(false);
    form.resetFields();
  };

  const handleClose = () => {
    setCategoryModalVisible(false);
    setDisabled(true);
    form.resetFields();
  };

  const handlePopConfirmOk = () => {
    handleClose();
    setCancelPopConfirmVisible(false);
  };

  const handlePopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };

  return (
    <>
      <Modal
        title="Add Category"
        centered={true}
        visible={categoryModalVisible}
        destroyOnClose={true}
        footer={
          <Space>
            <Popconfirm
              title="Are you sure? Your changes won't be saved."
              style={{ width: 100 }}
              visible={cancelPopConfirmVisible}
              onConfirm={handlePopConfirmOk}
              onCancel={handlePopConfirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                onClick={() => {
                  disabled ? handleClose() : setCancelPopConfirmVisible(true);
                }}
              >
                Cancel
              </Button>
            </Popconfirm>
            <Button data-testid="category-modal-add-btn" disabled={disabled} onClick={() => form.submit()}>
              Add
            </Button>
          </Space>
        }
        closable={false}
      >
        <Form
          form={form}
          layout="vertical"
          onChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter category name",
                  },
                ]}
              >
                <Input data-testid="category-name-input" placeholder="Please enter category name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea
                  data-testid="category-description-input"
                  rows={4}
                  showCount
                  maxLength={500}
                  placeholder="please enter category description"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu.menu,
    status: state.menu.categoryStatus,
  };
};

export default connect(mapStateToProps, { addCategory })(AddCategoryModal);
