import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";

import "./styles.css";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const SortableTable = (props) => {
  const [data, setData] = useState([]);
  const { dataSource, columns } = props;
  const newColumns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    ...columns,
  ];

  useEffect(() => {
    const newData = _.sortBy(dataSource, ["sort"]);
    setData(newData);
  }, [dataSource]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const oldData = data;
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(data), oldIndex, newIndex).filter((el) => !!el);
      setData(newData);
      const updatedData = newData
        .map((data, index) => {
          return {
            ...data,
            sort: index + 1,
          };
        })
        .filter((data, index) => {
          return data.id !== oldData[index].id;
        });
      if (props.onSortEnd) props.onSortEnd(updatedData, oldData, newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex((x) => x.sort === restProps["data-row-key"]);
    return <SortableItem className={className} index={index} {...restProps} />;
  };

  return (
    <Table
      {...props}
      dataSource={data}
      columns={newColumns}
      rowKey="sort"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default SortableTable;
