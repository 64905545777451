import _ from "lodash";

export const setMenuItemValues = (menuItem) => {
  const initialFormValues = _.map([menuItem], (item) => ({
    id: item.id,
    sku: _.get(item, "skus.id", ""),
    available: item.available,
    name: item.name,
    price: item.price,
    uber_image: _.get(item, "skus.image_uber_eats", ""),
    doordash_image: _.get(item, "skus.image_doordash", ""),
    grubhub_image: _.get(item, "skus.image_grubhub", ""),
    description: item.description,
  }));
  return initialFormValues;
};

export const setCategoryItemValues = (categoryItem) => {
  const initialFormValues = _.map([categoryItem], (item) => ({
    category: item.category,
    sku: _.get(item, "menu_item.skus.id", ""),
    available: _.get(item, "menu_item.available"),
    name: _.get(item, "menu_item.name"),
    name_override: item.name_override,
    price: _.get(item, "menu_item.price"),
    price_override: item.price_override,
    uber_image: _.get(item, "menu_item.skus.image_uber_eats", ""),
    doordash_image: _.get(item, "menu_item.skus.image_doordash", ""),
    grubhub_image: _.get(item, "menu_item.skus.image_grubhub", ""),
    description: _.get(item, "menu_item.description"),
    sort: item.sort,
  }));
  return initialFormValues;
};

export const overrideValues = (selectedSku) => {
  const values = _.map([selectedSku], (sku) => ({
    sku: _.get(sku, "id", ""),
    uber_image: _.get(sku, "image_uber_eats", ""),
    doordash_image: _.get(sku, "image_doordash", ""),
    grubhub_image: _.get(sku, "image_grubhub", ""),
  }));
  return values;
};

export const setSkuValuesFunc = (props) => {
  const [skus, NO_IMAGE] = props;
  if (skus.length > 0) {
    const ubereats = _.get(skus[0], "image_uber_eats.formats.thumbnail", NO_IMAGE);
    const doordash = _.get(skus[0], "image_doordash.formats.thumbnail", NO_IMAGE);
    const grubhub = _.get(skus[0], "image_grubhub.formats.thumbnail", NO_IMAGE);
    const values = _.map(skus, (sku) => ({
      id: _.get(sku, "sku_id", ""),
      name: _.get(sku, "name", ""),
      owner: _.get(sku, "owner.name", ""),
      ubereats: ubereats,
      doordash: doordash,
      grubhub: grubhub,
    }));
    return values;
  }
};
