import React from "react";
import { Card } from "antd";

import "./styles.css";

const { Meta } = Card;

export const CardComponent = (props) => {
  return (
    <Card {...props}>
      <Meta
        avatar={props.avatar}
        title={props.cardtitle}
        description={props.description ? props.description : null}
        actions={props.actions ? props.actions : null}
      />
    </Card>
  );
};
