import axios from "axios";
import { axiosWithAuth } from "./axiosWithAuth";

import _ from "lodash";

export default class Client {
  axiosInstance;
  url;
  constructor(baseURL) {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
    });
    this.url = baseURL;
  }

  // image apis
  uploadMediaFile = async (data) => {
    const request = await this.axiosInstance.request({
      method: "post",
      url: "/upload",
      data,
    });
    return request;
  };

  updateSkuImage = async (data, setPercent) => {
    const calculatePercent = (value, total) => Math.round((value / total) * 100);
    const request = await axiosWithAuth(this.url).request({
      method: "post",
      url: "/upload",
      data,
      onUploadProgress: (progress) => setPercent(calculatePercent(progress.loaded, progress.total)),
    });
    return request;
  };

  searchImages = async (query) => {
    const { platformName, skuName } = query;
    const url = `/upload/files?name_contains=${platformName}&alternativeText_contains=${skuName}`;
    const request = await this.axiosInstance.request({
      url,
    });
    return request;
  };

  updateMenuItemImage = async (id, fileId, platformName) => {
    const imageKey = `image_${_.snakeCase(platformName)}`;
    const request = await this.axiosInstance.request({
      method: "put",
      url: `/menu-items/${id}`,
      data: {
        [imageKey]: [fileId],
      },
    });
    return request;
  };

  deleteMenuItemImage = async (id, platformName) => {
    const imageKey = `image_${_.snakeCase(platformName)}`;
    const request = await this.axiosInstance.request({
      method: "put",
      url: `/menu-items/${id}`,
      data: {
        [imageKey]: [],
        query: `{menu(id:${id}){id,title,description,brand{id,name,logo{formats}}location{id,name}categories{id,name,description,sort,category_items{id,name_override,price_override,sort,menu_item{id,name,description,price,available,skus{id,sku_id,image_uber_eats{name,alternativeText,caption,formats}image_doordash{name,alternativeText,caption,formats}image_grubhub{name,alternativeText,caption,formats}}}category_item_modifiers{id,modifier{id,name,modifier_items{id,name_override,price_override,menu_item{id,name,price,skus{id}}}}}}}}}`,
      },
    });
    return request;
  };
}
