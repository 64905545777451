import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, List, Badge, Input, Tooltip, Tag, Avatar } from "antd";
import { CheckCircleTwoTone, QuestionCircleTwoTone } from "@ant-design/icons";
import { CardComponent } from "../../Card";

import Fuse from "fuse.js";

import _ from "lodash";

import "./styles.css";

const SkuModal = (props) => {
  const [searchPattern, setSearchPattern] = useState("");
  const [disabled, setDisabled] = useState(true);

  const { skuModalVisible, setSkuModalVisible, selectedSku, setSelectedSku, setSku, itemToEdit, categoryItem } = props;

  const skus = useSelector((state) => state.skus.skus);

  const orderableSkus = skus.filter((sku) => sku.active && sku.archived !== true);
  const NO_IMAGE = "https://vkc-files.s3.us-west-1.amazonaws.com/noimage_4dcae76445.png";

  useEffect(() => {
    if (selectedSku) {
      setDisabled(false);
    }
  }, [selectedSku]);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setSearchPattern(value);
  };

  const fuseSearchResults = () => {
    const options = {
      threshold: 0.2,
      keys: ["id", "name", "owner.name", "sku_id"],
    };
    const fuse = new Fuse(orderableSkus, options);
    const pattern = searchPattern;
    return fuse.search(pattern, { limit: 10 }).map((res) => res.item);
  };

  const data = searchPattern === "" ? _.filter(orderableSkus, (sku, index) => index < 50) : fuseSearchResults();
  return (
    <Modal
      className="sku-modal"
      visible={skuModalVisible}
      centered={true}
      closable={false}
      okType={"default"}
      onCancel={() => {
        setSkuModalVisible(false);
        setSelectedSku(null);
      }}
      onOk={() => {
        setSku(selectedSku);
        setSkuModalVisible(false);
        setSearchPattern("");
      }}
      okButtonProps={{ disabled: disabled }}
      afterClose={() => setSearchPattern("")}
      width={1100}
      zIndex={1}
      destroyOnClose={true}
      maskClosable={false}
    >
      <header className="sku-modal-header">
        <div className="input-wrapper">
          <Input
            id="sku-modal-search-input"
            placeholder="Search Item"
            onChange={(e) => {
              handleOnChange(e);
            }}
          />
          <Tooltip title="Search item by name, owner, id, or sku id.">
            <QuestionCircleTwoTone />
          </Tooltip>
        </div>
        <div className="currently-selected-item">
          {itemToEdit || categoryItem ? (
            <h5>
              Currently selected item:{" "}
              <strong>{_.get(itemToEdit, "name", _.get(categoryItem, "menu_item.name"))}</strong>
            </h5>
          ) : null}
        </div>
        <div>
          {selectedSku ? (
            <Tooltip
              title={
                <>
                  {_.get(selectedSku, "name", "")} <br />
                  {_.get(selectedSku, "sku_id", "")}
                </>
              }
            >
              <Tag color="blue">{_.get(selectedSku, "name", "")}</Tag>
            </Tooltip>
          ) : null}
        </div>
      </header>
      <div className="modal-content">
        <List
          grid={{
            gutter: 16,
            column: 3,
          }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Badge count={_.get(selectedSku, "id") === item.id ? <CheckCircleTwoTone twoToneColor="#1890ff" /> : 0}>
                <CardComponent
                  className="sku-card"
                  hoverable={true}
                  avatar={
                    <Avatar size={{ xl: 70 }} src={_.get(item, "image_uber_eats.formats.thumbnail.url", NO_IMAGE)} />
                  }
                  cardtitle={<Tooltip title={_.get(item, "name", "")}>{_.get(item, "name", "")}</Tooltip>}
                  description={<><div>{_.get(item, "owner.name", "")}</div><div>{
                    (_.get(item, "msrp", "") || "").toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD"
                    })
                  }</div></>}
                  onClick={() => setSelectedSku(item)}
                />
              </Badge>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default SkuModal;
