import _ from "lodash";

const axios = require("axios");
const jose = require("jose");

export const sortItemsBySortNum = (items) => {
  const strToNum = _.map(items, (c) => ({ ...c, sort: c.sort ? parseInt(c.sort, 10) : c.sort }));
  const sortedArr = strToNum.sort((a, b) => a.sort - b.sort);
  _.map(sortedArr, (c, i) =>
    i === 0 && !c.sort
      ? (c.sort = 1)
      : i !== 0 && sortedArr[i - 1].sort >= sortedArr[i].sort
      ? (c.sort = sortedArr[i - 1].sort + 1)
      : c.sort
  );
  return sortedArr;
};

export const sortItemsByName = (items) => {
  items.sort((a, b) => {
    let nameA = a.name ? a.name.toLowerCase() : null;
    let nameB = b.name ? b.name.toLowerCase() : null;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const handleError = (err, message, messageDuration) => {
  if (err && err.response?.status === 403) {
    message.error("Forbidden", messageDuration);
  } else {
    message.error("Oops, something went wrong!");
  }
};

export const logout = () => {
  localStorage.clear();
  window.location = `${window.location.origin}/login`;
};

export const getUserData = async (url = "https://dev-z45qix1p.us.auth0.com/api/v2/users") => {
  try {
    const userInfo = localStorage.getItem("user");
    // get access token
    const token = await getJWT();
    // request users info
    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (userInfo) {
      const { email } = JSON.parse(userInfo);
      // find current user profile
      const user = response.data.find((user) => user.email === email);
      localStorage.setItem("profile", JSON.stringify(user));
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const getJWT = async () => {
  const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const client_secret = process.env.REACT_APP_AUTH0_CLIENT_SECRET;
  const options = {
    method: "POST",
    url: "https://dev-z45qix1p.us.auth0.com/oauth/token",
    headers: { "content-type": "application/json" },
    data: {
      grant_type: "client_credentials",
      client_id: client_id,
      client_secret: client_secret,
      audience: "https://dev-z45qix1p.us.auth0.com/api/v2/",
    },
  };
  try {
    const response = await axios.request(options);
    const { access_token } = _.get(response, "data");
    return access_token;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const verifyJWT = async (token) => {
  try {
    const decoded = jose.decodeJwt(token);
    const issuer = _.get(decoded, "iss");
    if (issuer && issuer.includes("auth0")) {
      try {
        const response = await axios("https://dev-z45qix1p.us.auth0.com/.well-known/jwks.json");
        const { keys } = _.get(response, "data");
        const JWKS = jose.createLocalJWKSet({ keys });
        const { payload, protectedHeader } = await jose.jwtVerify(token, JWKS);
        console.log(payload);
        console.log(protectedHeader);
      } catch (err) {
        console.log(err.message);
        return err.message;
      }
    }
    console.log(decoded);
    return decoded;
  } catch (err) {
    console.log(err.message);
    return err.message;
  }
};
