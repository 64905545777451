import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CategoryTable from "../CategoryTable";
import { CategoriesFooter } from "../Footers";
import { sortItemsBySortNum } from "../../functions";

import "./styles.css";

const CategoryComponent = (props) => {
  const [sortedCategories, setSortedCategories] = useState([]);

  const { categoryToEdit, setCategoryToEdit, setCategoryModalVisible } = props;

  const categories = useSelector((state) => state.categories.categories);

  useEffect(() => {
    if (categories.length > 0) {
      const sortedArr = sortItemsBySortNum(categories);
      setSortedCategories(sortedArr);
    } else {
      setSortedCategories([]);
    }
  }, [categories]);

  return (
    <>
      <div className="categories-container">
        <CategoryTable
          categories={sortedCategories}
          categoryToEdit={categoryToEdit}
          setCategoryToEdit={setCategoryToEdit}
        />
      </div>
      <CategoriesFooter setCategoryModalVisible={setCategoryModalVisible} />
    </>
  );
};

export default CategoryComponent;
