import { createStore, combineReducers, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import menusReducer from '../reducers/menusReducer'
import menuReducer from '../reducers/menuReducer'
import menuSkusReducer from '../reducers/menuSkusReducer'
import categoriesReducer from '../reducers/categoriesReducer'
import menuItemsReducer from '../reducers/menuItemsReducer'
import modifiersReducer from '../reducers/modifiersReducer'
import brandsReducer from '../reducers/brandsReducer'
import categoryItemsReducer from '../reducers/categoryItemsReducer'
import modifierItemsReducer from '../reducers/modifierItemsReducer'
import categoryItemModifiersReducer from '../reducers/categoryItemModifiersReducer'
import skusReducer from '../reducers/skusReducer'
import targetsReducer from '../reducers/targetsReducer'

const rootReducer = combineReducers({
  menus: menusReducer,
  menu: menuReducer,
  categories: categoriesReducer,
  menu_items: menuItemsReducer,
  modifiers: modifiersReducer,
  category_items: categoryItemsReducer,
  modifier_items: modifierItemsReducer,
  category_item_modifiers: categoryItemModifiersReducer,
  brands: brandsReducer,
  skus: skusReducer,
  targets: targetsReducer,
  menu_skus: menuSkusReducer
});

export const store = createStore(rootReducer, applyMiddleware(thunk, logger));
