import {
  FETCH_MENU_ITEMS_START,
  FETCH_MENU_ITEMS_SUCCESS,
  FETCH_MENU_ITEMS_FAILURE,
  ADD_MENU_ITEM_START,
  ADD_MENU_ITEM_SUCCESS,
  ADD_MENU_ITEM_FAILURE,
  UPDATE_MENU_ITEM_START,
  UPDATE_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_FAILURE,
  DELETE_MENU_ITEM_START,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_FAILURE,
  CLEAR_MENU_ITEMS,
} from "../constants";

const initialState = {
  menu_items: [],
  new_menu_item: null,
  status: "idle",
  error: "",
};

const menuItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_ITEMS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        menu_items: action.payload,
      };
    case FETCH_MENU_ITEMS_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case ADD_MENU_ITEM_START:
      return {
        ...state,
        status: "pending",
      };
    case ADD_MENU_ITEM_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        menu_items: [...state.menu_items, action.payload],
        new_menu_item: action.payload,
      };
    case ADD_MENU_ITEM_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case UPDATE_MENU_ITEM_START:
      return {
        ...state,
        status: "pending",
      };
    case UPDATE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        status: "succeeded!",
        menu_items: [
          ...state.menu_items.slice(
            0,
            state.menu_items.findIndex((i) => i.id.toString() === action.payload.id.toString())
          ),
          action.payload,
          ...state.menu_items.slice(
            state.menu_items.findIndex((i) => i.id.toString() === action.payload.id.toString()) + 1
          ),
        ],
      };
    case UPDATE_MENU_ITEM_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case DELETE_MENU_ITEM_START:
      return {
        ...state,
        status: "pending",
      };
    case DELETE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        menu_items: [
          ...state.menu_items.slice(
            0,
            state.menu_items.findIndex((i) => i.id.toString() === action.payload.id.toString())
          ),
          ...state.menu_items.slice(
            state.menu_items.findIndex((i) => i.id.toString() === action.payload.id.toString()) + 1
          ),
        ],
      };
    case DELETE_MENU_ITEM_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case CLEAR_MENU_ITEMS:
      return {
        ...state,
        menu_items: [],
      };
    default: {
      return state;
    }
  }
};

export default menuItemsReducer;
