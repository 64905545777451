import {
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  ADD_CATEGORY_START,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  MESSAGE_DURATION,
} from "../../constants";

import { message } from "antd";

import { handleError } from "../../functions";

// import _ from "lodash";

import * as StrapiApi from "../../apis/strapi";

window.StrapiApi = StrapiApi;

export const getCategories = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CATEGORIES_START });
    const response = StrapiApi.getStrapi(`categories?menu.id=${id}&_limit=-1`);
    response
      .then((res) => {
        dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_CATEGORIES_FAILURE, payload: err });
      });
  };
};

export const addCategory = (body) => {
  return (dispatch) => {
    dispatch({ type: ADD_CATEGORY_START });
    const response = StrapiApi.createStrapi("categories", body);
    response
      .then((res) => {
        dispatch({ type: ADD_CATEGORY_SUCCESS, payload: res });
        message.success("Category successfully created!");
      })
      .catch((err) => {
        dispatch({ type: ADD_CATEGORY_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateCategory = (id, body) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY_START });
    const response = StrapiApi.updateStrapi(`categories/${id}`, body);
    response
      .then((res) => {
        dispatch({ type: UPDATE_CATEGORY_SUCCESS, payload: res });
        message.success("Category successfully updated!");
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CATEGORY_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const deleteCategory = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_CATEGORY_START });
    const response = StrapiApi.deleteStrapi(`categories/${id}`);
    response
      .then((res) => {
        dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: res });
        message.success("Category successfully deleted!");
      })
      .catch((err) => {
        dispatch({ type: DELETE_CATEGORY_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};
