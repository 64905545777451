import {
  FETCH_MENU_SKUS_START, FETCH_MENU_SKUS_SUCCESS, FETCH_MENU_SKUS_FAILURE
} from '../../constants'
import _ from 'lodash'
import * as StrapiApi from '../../apis/strapi'

window.StrapiApi = StrapiApi

export const getMenuSkus = (menuId) => {
  return async (dispatch) => {
    dispatch({type: FETCH_MENU_SKUS_START})
    const response = StrapiApi.getMenuSkus(menuId)
    response
      .then((res) => {
        const sortedUniqueActiveItems = _.chain(_.get(res, 'data.menu.menu_items', []))
                                         .flatMap(menuItem => menuItem.skus)
                                         .filter(item => !!item.active)
                                         .uniqBy('id')
                                         .sortBy('name')
                                         .value()
        dispatch({
          type: FETCH_MENU_SKUS_SUCCESS, payload: sortedUniqueActiveItems
        })
      })
      .catch((err) => {
        dispatch({type: FETCH_MENU_SKUS_FAILURE, payload: err.message})
      })
  }
}