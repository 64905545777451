import {
  FETCH_MENU_START,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_FAILURE,
  UPDATE_MENU_START,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_FAILURE,
  PUBLISH_MENU_START,
  PUBLISH_MENU_SUCCESS,
  PUBLISH_MENU_FAILURE,
  UPDATE_MENU_TARGETS_START,
  UPDATE_MENU_TARGETS_SUCCESS,
  UPDATE_MENU_TARGETS_FAILURE,
  UPDATE_INTERNAL_PROMO_START,
  UPDATE_INTERNAL_PROMO_SUCCESS,
  UPDATE_INTERNAL_PROMO_FAILURE,
} from "../constants";

const initialState = {
  menu: {},
  status: "idle",
  updateMenuStatus: "idle",
  targetsStatus: "idle",
  publishMenuStatus: "idle",
  internalPromosStatus: "idle",
  error: "",
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_MENU_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        menu: action.payload,
      };
    case FETCH_MENU_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case UPDATE_MENU_START:
      return {
        ...state,
        updateMenuStatus: "pending",
      };
    case UPDATE_MENU_SUCCESS:
      return {
        ...state,
        updateMenuStatus: "succeeded",
        menu: {
          ...state.menu,
          hours: action.payload.hours,
        },
      };
    case UPDATE_MENU_FAILURE:
      return {
        ...state,
        updateMenuStatus: "failed",
        error: action.payload,
      };
    case PUBLISH_MENU_START:
      return {
        ...state,
        publishMenuStatus: "pending",
      };
    case PUBLISH_MENU_SUCCESS:
      return {
        ...state,
        publishMenuStatus: "succeeded",
      };
    case PUBLISH_MENU_FAILURE:
      return {
        ...state,
        publishMenuStatus: "failed",
        error: action.payload,
      };
    case UPDATE_MENU_TARGETS_START:
      return {
        ...state,
        targetsStatus: "pending",
      };
    case UPDATE_MENU_TARGETS_SUCCESS:
      return {
        ...state,
        targetsStatus: "succeeded",
        menu: {
          ...state.menu,
          targets: [...action.payload.targets],
        },
      };
    case UPDATE_MENU_TARGETS_FAILURE: {
      return {
        ...state,
        targetsStatus: "failed",
        error: action.payload,
      };
    }
    case UPDATE_INTERNAL_PROMO_START:
      return {
        ...state,
        internalPromosStatus: "pending",
      };
    case UPDATE_INTERNAL_PROMO_SUCCESS:
      return {
        ...state,
        internalPromosStatus: "succeeded",
        menu: {
          ...state.menu,
          internal_promos: [...action.payload.internal_promos],
        },
      };
    case UPDATE_INTERNAL_PROMO_FAILURE: {
      return {
        ...state,
        internalPromosStatus: "failed",
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default menuReducer;
