import {
  FETCH_CATEGORY_ITEMS_START,
  FETCH_CATEGORY_ITEMS_SUCCESS,
  FETCH_CATEGORY_ITEMS_FAILURE,
  ADD_CATEGORY_ITEM_START,
  ADD_CATEGORY_ITEM_SUCCESS,
  ADD_CATEGORY_ITEM_FAILURE,
  UPDATE_CATEGORY_ITEM_START,
  UPDATE_CATEGORY_ITEM_SUCCESS,
  UPDATE_CATEGORY_ITEM_FAILURE,
  DELETE_CATEGORY_ITEM_START,
  DELETE_CATEGORY_ITEM_SUCCESS,
  DELETE_CATEGORY_ITEM_FAILURE,
  MESSAGE_DURATION,
} from "../../constants";

import { message } from "antd";

import { handleError } from "../../functions";

// import _ from "lodash";

import * as StrapiApi from "../../apis/strapi";

window.StrapiApi = StrapiApi;

export const getCategoryItems = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CATEGORY_ITEMS_START });
    const response = StrapiApi.getStrapi(`category-items?menu_item.menu.id=${id}&_limit=-1`);
    response
      .then((res) => {
        dispatch({ type: FETCH_CATEGORY_ITEMS_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_CATEGORY_ITEMS_FAILURE, payload: err });
      });
  };
};

export const addCategoryItem = (body) => {
  return (dispatch) => {
    dispatch({ type: ADD_CATEGORY_ITEM_START });
    const response = StrapiApi.createStrapi("category-items", body);
    response
      .then((res) => {
        dispatch({
          type: ADD_CATEGORY_ITEM_SUCCESS,
          payload: res,
        });
        message.success("Item successfully added!");
      })
      .catch((err) => {
        dispatch({
          type: ADD_CATEGORY_ITEM_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateCategoryItem = (id, body) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY_ITEM_START });
    const promise = StrapiApi.updateStrapi(`category-items/${id}`, body);
    promise
      .then((res) => {
        dispatch({
          type: UPDATE_CATEGORY_ITEM_SUCCESS,
          payload: res,
        });
        message.success("Item successfully updated!");
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_CATEGORY_ITEM_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const deleteCategoryItem = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_CATEGORY_ITEM_START });
    const promise = StrapiApi.deleteStrapi(`category-items/${id}`);
    promise
      .then((res) => {
        dispatch({
          type: DELETE_CATEGORY_ITEM_SUCCESS,
          payload: res,
        });
        message.success("Item successfully removed!");
      })
      .catch((err) => {
        dispatch({
          type: DELETE_CATEGORY_ITEM_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};
