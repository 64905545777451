import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, Form, Row, Col, Button, Popconfirm, Space, Select } from "antd";

import { addCategoryItem } from "../../../actions/categoryItems";

import _ from "lodash";

import "./styles.css";

const AddCategoryItemModal = (props) => {
  const [categoryItems, setCategoryItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [cancelPopConfirmVisible, setCancelPopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { categoryToEdit, menuItemModalVisible, setMenuItemModalVisible, addCategoryItem } = props;

  const [form] = Form.useForm();

  const { Option } = Select;

  const menu_items = useSelector((state) => state.menu_items.menu_items);

  const category_items = useSelector((state) => state.category_items.category_items);

  const handlePopConfirmOk = () => {
    handleClose();
    setCancelPopConfirmVisible(false);
  };

  const handlePopConfirmCancel = () => {
    setCancelPopConfirmVisible(false);
  };

  const setSelectedMenuItemFunc = (id) => {
    const menuItem = menu_items.find((item) => item.id === id);
    setSelectedMenuItem(menuItem);
  };

  useEffect(() => {
    if (categoryToEdit && category_items.length > 0) {
      const getCategoryItems = (category_items) => {
        const items = _.filter(category_items, (i) => _.get(i.category, "id") === categoryToEdit.id);
        setCategoryItems(items);
      };
      getCategoryItems(category_items);
    }
  }, [category_items, categoryToEdit]);

  const handleSubmit = () => {
    const categoryId = _.get(categoryToEdit, "id");
    if (categoryItems.length === 0) {
      const sort = 1;
      const { id } = selectedMenuItem;
      addCategoryItem({ category: categoryId, menu_item: id, name_override: null, price_override: null, sort: sort });
    } else {
      const sort =
        parseInt(
          categoryItems.sort((a, b) => parseInt(a.sort, 10) - parseInt(b.sort, 10))[categoryItems.length - 1].sort,
          10
        ) + 1;
      const { id } = selectedMenuItem;
      addCategoryItem({ category: categoryId, menu_item: id, name_override: null, price_override: null, sort: sort });
    }
    setMenuItemModalVisible(false);
    setDisabled(true);
    form.resetFields();
  };

  const handleClose = () => {
    setMenuItemModalVisible(false);
    setDisabled(true);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Select an Item"
        centered={true}
        visible={menuItemModalVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        zIndex={1}
        footer={
          <Space>
            <Popconfirm
              title="Are you sure? Your changes won't be saved."
              style={{ width: 100 }}
              visible={cancelPopConfirmVisible}
              onConfirm={handlePopConfirmOk}
              onCancel={handlePopConfirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button onClick={() => (disabled ? handleClose() : setCancelPopConfirmVisible(true))}>Cancel</Button>
            </Popconfirm>
            <Button disabled={disabled} onClick={() => form.submit()}>
              Add
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="menu_item" label="Item">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select a menu item"
                  onChange={() => setDisabled(false)}
                  onSelect={(value) => setSelectedMenuItemFunc(value)}
                  onClear={() => {
                    setSelectedMenuItem(null);
                    form.resetFields();
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {_.map(menu_items, (item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(null, { addCategoryItem })(AddCategoryItemModal);
