import {
  FETCH_CATEGORY_ITEM_MODIFIERS_START,
  FETCH_CATEGORY_ITEM_MODIFIERS_SUCCESS,
  FETCH_CATEGORY_ITEM_MODIFIERS_FAILURE,
  ADD_CATEGORY_ITEM_MODIFIER_START,
  ADD_CATEGORY_ITEM_MODIFIER_SUCCESS,
  ADD_CATEGORY_ITEM_MODIFIER_FAILURE,
  UPDATE_CATEGORY_ITEM_MODIFIER_START,
  UPDATE_CATEGORY_ITEM_MODIFIER_SUCCESS,
  UPDATE_CATEGORY_ITEM_MODIFIER_FAILURE,
  DELETE_CATEGORY_ITEM_MODIFIER_START,
  DELETE_CATEGORY_ITEM_MODIFIER_SUCCESS,
  DELETE_CATEGORY_ITEM_MODIFIER_FAILURE,
  MESSAGE_DURATION,
} from "../../constants";

import * as StrapiApi from "../../apis/strapi";

import { handleError } from "../../functions";

import { message } from "antd";

export const getCategoryItemModifiers = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_CATEGORY_ITEM_MODIFIERS_START });
    const promise = StrapiApi.getStrapi(`category-item-modifiers?modifier.menu.id=${id}&_limit=-1`);
    promise
      .then((res) => {
        dispatch({
          type: FETCH_CATEGORY_ITEM_MODIFIERS_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CATEGORY_ITEM_MODIFIERS_FAILURE,
          payload: err,
        });
      });
  };
};

export const addCategoryItemModifier = (body) => {
  return (dispatch) => {
    dispatch({ type: ADD_CATEGORY_ITEM_MODIFIER_START });
    const promise = StrapiApi.createStrapi("category-item-modifiers", body);
    promise
      .then((res) => {
        dispatch({
          type: ADD_CATEGORY_ITEM_MODIFIER_SUCCESS,
          payload: res,
        });
        message.success("Modifier successfully added!");
      })
      .catch((err) => {
        dispatch({
          type: ADD_CATEGORY_ITEM_MODIFIER_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateCategoryItemModifier = async (id, body) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY_ITEM_MODIFIER_START });
    const promise = StrapiApi.updateStrapi(`category-item-modifiers/${id}`, body);
    promise
      .then((res) => {
        dispatch({
          type: UPDATE_CATEGORY_ITEM_MODIFIER_SUCCESS,
          payload: res,
        });
        message.success("Modifier successfully updated!");
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_CATEGORY_ITEM_MODIFIER_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const deleteCategoryItemModifier = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_CATEGORY_ITEM_MODIFIER_START });
    const promise = StrapiApi.deleteStrapi(`category-item-modifiers/${id}`);
    promise
      .then((res) => {
        dispatch({
          type: DELETE_CATEGORY_ITEM_MODIFIER_SUCCESS,
          payload: res,
        });
        message.success("Modifier successfully removed!");
      })
      .catch((err) => {
        dispatch({
          type: DELETE_CATEGORY_ITEM_MODIFIER_FAILURE,
          payload: err,
        });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};
