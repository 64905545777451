import React from "react";
import logo from "../../Assets/BagB.png";
import "./styles.css";

const Logo = (props) => {
  return (
    <div {...props}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export default Logo;
