import React, { useEffect, useState } from "react";
import { Avatar, Image, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { logout } from "../../functions";

import "./styles.css";

const menu = (
  <Menu>
    <Menu.Item key="1" onClick={() => logout()}>
      Logout
    </Menu.Item>
  </Menu>
);

const ProfilePicture = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (!imageUrl && !username) {
      const profile = localStorage.getItem("profile");
      if (profile) {
        const { picture, name } = JSON.parse(profile);
        setImageUrl(picture);
        setUsername(name);
      }
    }
  }, [imageUrl, username]);

  return (
    <Dropdown overlay={menu}>
      <Avatar
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid rgb(235, 237, 240)",
        }}
        src={imageUrl ? <Image style={{ height: 40, width: 40 }} src={imageUrl} preview={false} /> : null}
        icon={<UserOutlined />}
        shape="circle"
        size="large"
      />
    </Dropdown>
  );
};

export default ProfilePicture;
