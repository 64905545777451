import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { Modal, Checkbox, Divider, Row, Col, Typography, Button, Spin, Collapse } from "antd";

import { publishMenu } from "../../../actions/menu";
import * as StrapiApi from "../../../apis/strapi";

import _ from "lodash";

import "./styles.css";
import { USE_MENU_SNAPSHOT } from "../../../constants";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const PublishModal = props => {
  const [targetIdList, setTargetIdList] = useState([]);
  const [checkedTargetList, setCheckedTargetList] = useState([]);

  const { publishModalVisible, setPublishModalVisible, publishMenu } = props;

  const menuId = useSelector((state) => state.menu.menu.id);

  const targets = useSelector((state) => state.menu.menu.targets);

  const { Text } = Typography;

  useEffect(() => {
    const setTargetIds = () => {
      const ids = _.map(targets, (t) => t.id, 10);
      setTargetIdList(ids);
    };
    setTargetIds();
  }, [targets]);

  useEffect(() => {
    setCheckedTargetList(targetIdList);
  }, [targetIdList]);

  const handleSubmit = () => {
    publishMenu({ menuId })
    setPublishModalVisible(false);
  };

  const handleClose = () => {
    setPublishModalVisible(false);
  };

  return (
    <Modal
      className="publish-modal"
      title="Publish"
      visible={publishModalVisible}
      centered={true}
      closable={false}
      okType={"default"}
      okText="Publish"
      onCancel={() => handleClose()}
      onOk={() => handleSubmit()}
      width={500}
      zIndex={1}
      destroyOnClose={true}
      maskClosable={true}>
      <div className="publish-modal-title">
        <Text strong={true}>All targets attached share the exact same menu</Text>
      </div>
      <br />
      <div style={{ marginTop: 10 }}>
        <h2>Targets</h2>
      </div>
      <Checkbox.Group style={{ width: "100%" }} value={checkedTargetList}>
        <Row>
          {_.map(targets, (t) => (
            <Col key={t.id} span={12}>
              <Checkbox key={t.id} value={t.id} disabled={USE_MENU_SNAPSHOT}>
                {_.get(t, "name")}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Divider />
      {USE_MENU_SNAPSHOT ? <SnapshotHelp id={menuId} /> : null}
    </Modal>
  );
};

function SnapshotHelp({ id }) {
  const [publishPreview, setPublishPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  function onChange(key) {
    if (key.includes("diff") && !publishPreview) {
      loadDiff();
    }
  }

  function loadDiff() {
    setLoading(true);
    StrapiApi.getStrapi(`menus/${id}/preview`)
      .then(res => {
        setPublishPreview(res);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }

  return (
    <Collapse onChange={onChange}>
      <CollapsePanel header="What are the changes that will be published?" key="diff">
        <div style={{ maxHeight: 200, overflow: "auto" }}>
          {loading ? (
            <Spin />
          ) : (
            <div>
              <Button type="dashed" onClick={loadDiff} style={{ marginBottom: 10 }}>
                Reload
              </Button>
              {publishPreview?.data ? (
                <pre>{JSON.stringify(publishPreview.data, null, 2)}</pre>
              ) : (
                publishPreview?.status
              )}
            </div>
          )}
        </div>
      </CollapsePanel>
    </Collapse>
  );
}

export default connect(null, { publishMenu })(PublishModal);
