import React, { useEffect, useState } from "react";
import { deleteCategory, updateCategory } from "../../actions/categories";
import { useSelector, connect } from "react-redux";
import { DeleteTwoTone } from "@ant-design/icons";
import { Form, Row, Col, Select, Input, Popconfirm, Tooltip } from "antd";

import CategoryItemTable from "../CategoryItemTable";
// import EditableModifier from "../EditableModifier";
import { EditingComponentFooter } from "../Footers";
import { sortItemsBySortNum, sortItemsByName } from "../../functions/index";

import _ from "lodash";

import "./styles.css";

const CategoryEditor = (props) => {
  const [sortedCategoryItems, setSortedCategoryItems] = useState([]);
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const {
    categoryToEdit,
    setCategoryToEdit,
    setCategoryItem,
    setItemsVisible,
    deleteCategory,
    updateCategory,
    // modifierGroup,
    setMenuItemModalVisible,
    NO_IMAGE,
  } = props;

  const [form] = Form.useForm();

  const { Option } = Select;

  const id = _.get(categoryToEdit, "id", "");

  const menuId = useSelector((state) => state.menu.menu.id);

  const brands = useSelector((state) => state.brands.brands);

  const menuItems = useSelector((state) => state.menu_items.menu_items);

  const skus = useSelector((state) => state.skus.skus);

  const category_items = useSelector((state) => state.category_items.category_items);

  const sortedBrands = brands.slice();

  sortItemsByName(sortedBrands);

  useEffect(() => {
    form.resetFields();
  }, [categoryToEdit, form]);

  useEffect(() => {
    if (categoryToEdit && category_items.length > 0) {
      const categoryItems = _.filter(category_items, (i) => _.get(i.category, "id") === categoryToEdit.id);
      const sortedArr = sortItemsBySortNum(categoryItems);
      const newCategoryItems = _.map(sortedArr, (item) => ({
        ...item,
        menu_item: menuItems.find((i) => i.id === item.menu_item.id),
      }));
      setSortedCategoryItems(newCategoryItems);
    } else {
      setSortedCategoryItems([]);
    }
  }, [category_items, categoryToEdit, menuItems, skus]);

  const showDeletePopconfirm = () => {
    setDeletePopConfirmVisible(true);
  };

  const handleDeletePopConfirmOk = () => {
    deleteCategory(id);
    setDeletePopConfirmVisible(false);
    setCategoryToEdit(null);
  };

  const handleDeletePopConfirmCancel = () => {
    setDeletePopConfirmVisible(false);
  };

  const onFormSubmit = (values) => {
    const { name, description, brand } = values;
    const brandValue = brand === undefined ? null : brand;
    updateCategory(id, {
      menu: menuId,
      name: name,
      description: description,
      brand: brandValue,
    });
  };

  return (
    <>
      <div className="editing-component-bottom-container">
        <h2 className="editing-component-label">
          <em>{_.get(categoryToEdit, "category", "")}</em>
        </h2>
        <Form
          className="category-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: _.get(categoryToEdit, "category", ""),
            description: _.get(categoryToEdit, "description", ""),
            brand: _.get(categoryToEdit, "brand"),
          }}
          onValuesChange={() => setDisabled(false)}
          onFinish={(values) => onFormSubmit(values)}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Col style={{ paddingTop: 5 }}>
              <Popconfirm
                title="Are you sure you want to delete this category?"
                visible={deletePopConfirmVisible}
                onConfirm={handleDeletePopConfirmOk}
                onCancel={handleDeletePopConfirmCancel}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="delete" placement="left">
                  <DeleteTwoTone twoToneColor="#f5222d" onClick={() => showDeletePopconfirm()} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
          <h2 className="label">Category</h2>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter category name",
                  },
                ]}
              >
                <Input placeholder="Please enter category name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="brand" label="Brand">
                <Select
                  allowClear
                  showSearch
                  placeholder="Search brand"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {_.map(sortedBrands, (brand) => (
                    <Option key={brand.id} value={brand.id}>
                      {brand.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea rows={4} showCount maxLength={500} placeholder="please enter category description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <CategoryItemTable
          categoryItems={sortedCategoryItems}
          categoryToEdit={categoryToEdit}
          setCategoryItem={setCategoryItem}
          setItemsVisible={setItemsVisible}
          NO_IMAGE={NO_IMAGE}
        />
      </div>
      <EditingComponentFooter
        form={form}
        setMenuItemModalVisible={setMenuItemModalVisible}
        setCategoryToEdit={setCategoryToEdit}
        disabled={disabled}
      />
    </>
  );
};

export default connect(null, { deleteCategory, updateCategory })(CategoryEditor);
