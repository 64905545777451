import {
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  ADD_CATEGORY_START,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
} from "../../constants";

const initialState = {
  categories: [],
  status: "idle",
  error: "",
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        status: "succeeded",
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case ADD_CATEGORY_START:
      return {
        ...state,
        status: "pending",
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        categories: [...state.categories, action.payload],
      };
    case ADD_CATEGORY_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case UPDATE_CATEGORY_START:
      return {
        ...state,
        status: "pending",
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        categories: [
          ...state.categories.slice(
            0,
            state.categories.findIndex((c) => c.id.toString() === action.payload.id.toString())
          ),
          action.payload,
          ...state.categories.slice(
            state.categories.findIndex((c) => c.id.toString() === action.payload.id.toString()) + 1
          ),
        ],
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case DELETE_CATEGORY_START:
      return {
        ...state,
        status: "pending",
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        categories: [
          ...state.categories.slice(
            0,
            state.categories.findIndex((c) => c.id.toString() === action.payload.id.toString())
          ),
          ...state.categories.slice(
            state.categories.findIndex((c) => c.id.toString() === action.payload.id.toString()) + 1
          ),
        ],
      };
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default categoriesReducer;
