import {
  FETCH_MODIFIER_ITEMS_START,
  FETCH_MODIFIER_ITEMS_SUCCESS,
  FETCH_MODIFIER_ITEMS_FAILURE,
  ADD_MODIFIER_ITEM_START,
  ADD_MODIFIER_ITEM_SUCCESS,
  ADD_MODIFIER_ITEM_FAILURE,
  UPDATE_MODIFIER_ITEM_START,
  UPDATE_MODIFIER_ITEM_SUCCESS,
  UPDATE_MODIFIER_ITEM_FAILURE,
  DELETE_MODIFIER_ITEM_START,
  DELETE_MODIFIER_ITEM_SUCCESS,
  DELETE_MODIFIER_ITEM_FAILURE,
  MESSAGE_DURATION,
} from "../../constants";

import * as StrapiApi from "../../apis/strapi";

import { handleError } from "../../functions";

import { message } from "antd";

export const getModifierItems = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MODIFIER_ITEMS_START });
    const promise = StrapiApi.getStrapi(`modifier-items?modifier.menu.id=${id}&_limit=-1`);
    promise
      .then((res) => {
        dispatch({ type: FETCH_MODIFIER_ITEMS_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_MODIFIER_ITEMS_FAILURE, payload: err });
      });
  };
};

export const addModifierItem = (body) => {
  return (dispatch) => {
    dispatch({ type: ADD_MODIFIER_ITEM_START });
    const promise = StrapiApi.createStrapi("modifier-items", body);
    promise
      .then((res) => {
        dispatch({ type: ADD_MODIFIER_ITEM_SUCCESS, payload: res });
        message.success("Option successfully added!");
      })
      .catch((err) => {
        dispatch({ type: ADD_MODIFIER_ITEM_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateModifierItem = (id, body) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MODIFIER_ITEM_START });
    const promise = StrapiApi.updateStrapi(`modifier-items/${id}`, body);
    promise
      .then((res) => {
        dispatch({ type: UPDATE_MODIFIER_ITEM_SUCCESS, payload: res });
        message.success("Option successfully updated!");
      })
      .catch((err) => {
        dispatch({ type: UPDATE_MODIFIER_ITEM_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const deleteModifierItem = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_MODIFIER_ITEM_START });
    const promise = StrapiApi.deleteStrapi(`modifier-items/${id}`);
    promise
      .then((res) => {
        dispatch({ type: DELETE_MODIFIER_ITEM_SUCCESS, payload: res });
        message.success("Option successfully removed!");
      })
      .catch((err) => {
        dispatch({ type: DELETE_MODIFIER_ITEM_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};
