import { FETCH_SKUS_START, FETCH_SKUS_SUCCESS, FETCH_SKUS_FAILURE } from "../../constants";

import * as StrapiApi from "../../apis/strapi";

export const getSkus = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_SKUS_START });
    const promise = StrapiApi.getStrapi("skus?active=true&is_orderable=true&_limit=-1");
    promise
      .then((res) => {
        dispatch({ type: FETCH_SKUS_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_SKUS_FAILURE, payload: err });
      });
  };
};
