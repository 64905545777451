import {
  UPDATE_INTERNAL_PROMO_START,
  UPDATE_INTERNAL_PROMO_SUCCESS,
  UPDATE_INTERNAL_PROMO_FAILURE,
  MESSAGE_DURATION,
} from '../../constants'

import _ from 'lodash'
import * as StrapiApi from '../../apis/strapi'

import { handleError } from '../../functions'
import { message } from 'antd'

window.StrapiApi = StrapiApi

export const addInternalPromo = (menu, internalPromo, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({type: UPDATE_INTERNAL_PROMO_START})
    const promise = StrapiApi.createStrapi('internal-promos', internalPromo)
    promise
      .then((res) => {
        dispatch({
          type: UPDATE_INTERNAL_PROMO_SUCCESS,
          payload: {internal_promos: [...menu.internal_promos, res]}
        })
        message.success('InternalPromo successfully created!')
        onSuccess()
      })
      .catch((err) => {
        dispatch({type: UPDATE_INTERNAL_PROMO_FAILURE, paylaod: err})
        handleError(err, message, MESSAGE_DURATION)
      })
  }
}

export const voidInternalPromos = (menu, internalPromo, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({type: UPDATE_INTERNAL_PROMO_START})
    const response = StrapiApi.updateStrapi(`internal-promos/${internalPromo.id}`, {
      ...internalPromo, voided_at: new Date()
    })
    response
      .then((res) => {
        dispatch({
          type: UPDATE_INTERNAL_PROMO_SUCCESS, payload: {
            internal_promos: _.map(menu.internal_promos, item => {
              return item.id === internalPromo.id ? _.assign({}, item, res) : item
            })
          }
        })
        message.success('InternalPromos successfully updated!')
        onSuccess()
      })
      .catch((err) => {
        dispatch({type: UPDATE_INTERNAL_PROMO_FAILURE, payload: err})
        handleError(err, message, MESSAGE_DURATION)
      })
  }
}
