import {
  FETCH_MODIFIERS_START,
  FETCH_MODIFIERS_SUCCESS,
  FETCH_MODIFIERS_FAILURE,
  ADD_MODIFIER_START,
  ADD_MODIFIER_SUCCESS,
  ADD_MODIFIER_FAILURE,
  UPDATE_MODIFIER_START,
  UPDATE_MODIFIER_SUCCESS,
  UPDATE_MODIFIER_FAILURE,
  DELETE_MODIFIER_START,
  DELETE_MODIFIER_SUCCESS,
  DELETE_MODIFIER_FAILURE,
  MESSAGE_DURATION,
} from "../../constants";

import * as StrapiApi from "../../apis/strapi";

import { handleError } from "../../functions";

import { message } from "antd";

export const getModifiers = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MODIFIERS_START });
    const promise = StrapiApi.getStrapi(`modifiers?menu.id=${id}&_limit=-1`);
    promise
      .then((res) => {
        dispatch({ type: FETCH_MODIFIERS_SUCCESS, payload: res });
      })
      .catch((err) => {
        dispatch({ type: FETCH_MODIFIERS_FAILURE, payload: err });
      });
  };
};

export const addModifier = (body) => {
  return (dispatch) => {
    dispatch({ type: ADD_MODIFIER_START });
    const promise = StrapiApi.createStrapi("modifiers", body);
    promise
      .then((res) => {
        dispatch({ type: ADD_MODIFIER_SUCCESS, payload: res });
        message.success("Modifier group successfully created!");
      })
      .catch((err) => {
        dispatch({ type: ADD_MODIFIER_FAILURE, paylaod: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const updateModifier = (id, body) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MODIFIER_START });
    const promise = StrapiApi.updateStrapi(`modifiers/${id}`, body);
    promise
      .then((res) => {
        dispatch({ type: UPDATE_MODIFIER_SUCCESS, payload: res });
        message.success("Modifier group successfully updated!");
      })
      .catch((err) => {
        dispatch({ type: UPDATE_MODIFIER_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

export const deleteModifier = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_MODIFIER_START });
    const promise = StrapiApi.deleteStrapi(`modifiers/${id}`);
    promise
      .then((res) => {
        dispatch({ type: DELETE_MODIFIER_SUCCESS, payload: res });
        message.success("Modifier group successfully deleted!");
      })
      .catch((err) => {
        dispatch({ type: DELETE_MODIFIER_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};
