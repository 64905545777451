import React from 'react'
import { Table, Menu, Dropdown, Modal } from 'antd'
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { connect, useSelector } from 'react-redux'
import { voidInternalPromos } from '../../actions/internalPromos'
import _ from 'lodash'

import './styles.css'

const {Item} = Menu
const {confirm} = Modal

const MenuInternalPromoTable = ({
  selectedInternalPromo, setSelectedInternalPromo, voidInternalPromos
}) => {
  const menu = useSelector((state) => state.menu.menu)
  const assignedInternalPromos = useSelector((state) => state.menu.menu.internal_promos)
  const internalPromosStatus = useSelector((state) => state.menu.internalPromosStatus)

  const data = assignedInternalPromos.filter(item => {
    if (item.voided_at) {
      return false
    }

    if (item.expiry_time) {
      const now = new Date()
      const expiredDate = new Date(item.expiry_time)
      return expiredDate >= now
    }

    return true
  })

  function showConfirm () {
    confirm({
      title: `Are you sure you want to void this internal promo: ${_.get(selectedInternalPromo, 'name')}?`,
      icon: <ExclamationCircleOutlined/>,
      centered: true,
      okText: 'Void',
      onOk () {
        voidInternalPromos(menu, selectedInternalPromo)
        setSelectedInternalPromo(null)
      },
      onCancel () {
        console.log('canceled')
      },
    })
  }

  const dropdownMenu = (<Menu>
    <Item
      key="0"
      onClick={() => {
        showConfirm()
      }}
    >
      Void Internal Promo
    </Item>
  </Menu>)

  const columns = [{
    title: 'internalPromo', dataIndex: 'name', key: 'name',
  }, {
    title: 'actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'right',
    width: 50,
    render: () => (
      <Dropdown overlay={dropdownMenu} overlayStyle={{paddingRight: 10}} trigger={['click']}>
        <EllipsisOutlined rotate={90}/>
      </Dropdown>),
  },]

  return (<>
    <h2 className="label">Internal Promos</h2>
    <div className="internalPromos-table-wrapper">
      <Table
        rowKey="id"
        className="internalPromos-table"
        size="small"
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={internalPromosStatus === 'pending'}
        rowClassName={(record) => selectedInternalPromo && record.id === selectedInternalPromo.id ? 'highlighted-row' : null}
        onRow={(record) => {
          return {
            onClick: () => setSelectedInternalPromo(record),
          }
        }}
      />
    </div>
  </>)
}

export default connect(null, {voidInternalPromos})(MenuInternalPromoTable)
