import {
  FETCH_MENU_START,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_FAILURE,
  UPDATE_MENU_START,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_FAILURE,
  PUBLISH_MENU_START,
  PUBLISH_MENU_SUCCESS,
  PUBLISH_MENU_FAILURE,
  MESSAGE_DURATION,
} from "../../constants";

import { handleError } from "../../functions";

import { message } from "antd";

import * as StrapiApi from "../../apis/strapi";

window.StrapiApi = StrapiApi;

export const getMenu = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MENU_START });
    const response = StrapiApi.getMenu(id);
    response
      .then((res) => {
        dispatch({
          type: FETCH_MENU_SUCCESS,
          payload: res.data.menu,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_MENU_FAILURE, payload: err.message });
      });
  };
};

export const updateMenu = (id, body) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_MENU_START });
    const response = StrapiApi.updateStrapi(`menus/${id}`, body);
    response
      .then((res) => {
        dispatch({ type: UPDATE_MENU_SUCCESS, payload: res });
        message.success("Menu successfully updated!");
      })
      .catch((err) => {
        dispatch({ type: UPDATE_MENU_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

/**
 * Publishes menu to all targets
 * @param {{
 *   menuId: string, // menu id in production
 * }} body
 * @returns
 */
export const publishMenu = (body) => {
  const { menuId } = body;
  return async (dispatch) => {
    dispatch({ type: PUBLISH_MENU_START });

    publish(menuId)
      .then((res) => {
        dispatch({ type: PUBLISH_MENU_SUCCESS, payload: res });
        const { status, errors = [] } = res;
        if (status === 200) {
          message.success("Menu successfully published!");
        } else if (status === 400) {
          message.error(`Validation Error: Please check menu item configuration and ensure hours are set`, MESSAGE_DURATION);
        } else {
          message.error(`Publishing Error: ${errors.join('. ')}`, MESSAGE_DURATION);
        }
      })
      .catch((err) => {
        dispatch({ type: PUBLISH_MENU_FAILURE, payload: err });
        handleError(err, message, MESSAGE_DURATION);
      });
  };
};

async function publish(menuId) {
  const targetPublishResponse = await StrapiApi.updateStrapi(`menus/${menuId}/publish`, {}, undefined);
  return targetPublishResponse;
}