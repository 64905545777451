import {uniqBy} from 'lodash'
import { FETCH_BRANDS_START, FETCH_BRANDS_SUCCESS, FETCH_BRANDS_FAILURE } from "../constants";

const initialState = {
  brands: [],
  status: "idle",
  error: "",
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRANDS_START:
      return {
        ...state,
        status: "pending",
      };
    case FETCH_BRANDS_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        brands: uniqBy(action.payload, 'brand_id')
      };
    case FETCH_BRANDS_FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default brandsReducer;
