import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { message } from "antd";
import SortableTable from "../SortableTable";

import { getCategoryItemModifiers } from "../../actions/categoryItemModifiers";

import * as StrapiApi from "../../apis/strapi";

import _ from "lodash";

import "./styles.css";

window.StrapiApi = StrapiApi;

const CategoryItemModifierTable = (props) => {
  const [data, setData] = useState(null);

  const { categoryItemModifiers, setSelectedModifier, setModifierDrawerVisible, getCategoryItemModifiers } = props;

  const status = useSelector((state) => state.category_item_modifiers.status);

  const menu = useSelector((state) => state.menu.menu);

  useEffect(() => {
    if (categoryItemModifiers) {
      const data = _.map(categoryItemModifiers, (modifier) => ({
        id: modifier.id,
        key: modifier.id,
        sort: modifier.sort,
        modifier: modifier.modifier,
        name: _.get(modifier, "modifier.name"),
        min_allowed: _.get(modifier, "modifier.min_allowed"),
        max_allowed: _.get(modifier, "modifier.max_allowed"),
        modifier_items: _.get(modifier, "modifier.modifier_items"),
      }));
      setData(data);
    }
  }, [categoryItemModifiers]);

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <>
      <h2 className="label">Modifier Groups</h2>
      <div className="category-item-modifiers-table-wrapper">
        <SortableTable
          className="category-item-modifiers-table"
          size="small"
          rowClassName="ant-table-row"
          showHeader={false}
          style={{ hover: true }}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={status === "pending" ? true : false}
          onSortEnd={(updatedData) => {
            StrapiApi.updateSort(updatedData, "categoryItemModifier")
              .then(() => {
                const { id } = menu;
                getCategoryItemModifiers(id);
                message.success("Sorting is saved");
              })
              .catch(() => {
                message.error("Sorting failed");
              });
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                setSelectedModifier(record);
                setModifierDrawerVisible(true);
              },
            };
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu.menu,
  };
};

export default connect(mapStateToProps, { getCategoryItemModifiers })(CategoryItemModifierTable);
