import request from "./request";

export const strapiWithAuth = (url, data) => {
  const token = localStorage.getItem("jwt");
  return request(url, {
    ...data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
