import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Form, Row, Col, Input, InputNumber } from "antd";
// import { DeleteTwoTone } from "@ant-design/icons";
import { updateModifier, deleteModifier } from "../../actions/modifiers";

import ModifierItemTable from "../ModifierItemTable";
import { EditingComponentFooter } from "../Footers";
import { sortItemsBySortNum } from "../../functions/index";

import _ from "lodash";

import "./styles.css";

const ModifierEditor = (props) => {
  const [modifierItemIds, setModifierItemIds] = useState([]);
  // const [sortedModifierItems, setSortedModifierItems] = useState([])
  // const [selectedModifierItem, setSelectedModifierItem] = useState(null);
  // const [newModifierItems, setNewModifierItems] = useState(null);
  // const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);
  const [maxAllowedValue, setMaxAllowedValue] = useState(0);
  const [minAllowedValue, setMinAllowedValue] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const {
    selectedModifier,
    setSelectedModifier,
    selectedModifierItem,
    setSelectedModifierItem,
    setModifierItemModalVisible,
    updateModifier,
    // deleteModifier,
    newModifierItems,
    setNewModifierItems,
    modifierItemModalVisible,
  } = props;

  const [form] = Form.useForm();

  const modifierItems = useSelector((state) => state.modifier_items.modifier_items);

  const menuItems = useSelector((state) => state.menu_items.menu_items);

  const menu = useSelector((state) => state.menu.menu);

  const skus = useSelector((state) => state.skus.skus);

  useEffect(() => {
    if (selectedModifier) {
      form.setFieldsValue({
        id: selectedModifier.id,
        name: selectedModifier.name,
        min_allowed: selectedModifier.min_allowed,
        max_allowed: selectedModifier.max_allowed,
        modifier_items: modifierItemIds,
      });
      setMinAllowedValue(selectedModifier.min_allowed);
      setMaxAllowedValue(selectedModifier.max_allowed);
    }
  }, [selectedModifier, modifierItemIds, form]);

  useEffect(() => {
    const items = _.filter(modifierItems, (i) => _.get(i.modifier, "id") === selectedModifier.id);
    const ids = _.filter(items, (i) => i.id);
    setModifierItemIds(ids);
    const sortedArr = sortItemsBySortNum(items);
    const newItems = _.map(sortedArr, (i) => ({
      ...i,
      menu_item: {
        ...menuItems.find((item) => item.id === _.get(i, "menu_item.id")),
        skus: skus.find((sku) => sku.id === menuItems.find((item) => item.id === _.get(i, "menu_item.id")).skus.id),
      },
    }));
    setNewModifierItems(newItems);
  }, [modifierItems, menuItems, selectedModifier, skus, setNewModifierItems]);

  useEffect(() => {
    if (minAllowedValue > maxAllowedValue) {
      setDisabled(true);
    }
  }, [maxAllowedValue, minAllowedValue]);

  // const showDeletePopconfirm = () => {
  //   setDeletePopConfirmVisible(true);
  // };

  // const handleDeletePopConfirmOk = () => {
  //   const { id } = selectedModifier;
  //   deleteModifierGroup(id);
  //   setDeletePopConfirmVisible(false);
  //   setSelectedModifier(null);
  // };

  // const handleDeletePopConfirmCancel = () => {
  //   setDeletePopConfirmVisible(false);
  // };

  const onFinish = (values) => {
    const { name, min_allowed, max_allowed } = values;
    const { id } = selectedModifier;
    updateModifier(id, {
      menu: menu.id,
      name: name,
      modifier_items: modifierItemIds,
      min_allowed: min_allowed,
      max_allowed: max_allowed,
    });
  };

  return (
    <>
      <div className="modifier-editing-component-bottom-container">
        <h2 className="editing-component-label">
          <em>{_.get(selectedModifier, "name", "")}</em>
        </h2>
        <Form
          form={form}
          name="modifierForm"
          className="modifier-form"
          layout="vertical"
          onFieldsChange={() => setDisabled(false)}
          onFinish={onFinish}
        >
          {/* <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Col style={{ paddingTop: 5 }}>
              <Popconfirm
                title="Are you sure you want to delete this modifier?"
                visible={deletePopConfirmVisible}
                onConfirm={handleDeletePopConfirmOk}
                onCancel={handleDeletePopConfirmCancel}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="delete" placement="left">
                  <DeleteTwoTone twoToneColor="#f5222d" onClick={() => showDeletePopconfirm()} />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row> */}
          <h2 className="label">Modifier</h2>
          <Row>
            <Col span={24}>
              <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter modifier name" }]}>
                <Input placeholder="Please enter modifier name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={2}>
            <Col span={8} style={{ textAlign: "left" }}>
              <Form.Item name="min_allowed" label="min allowed :">
                <InputNumber
                  disabled={modifierItemModalVisible}
                  min={0}
                  max={maxAllowedValue ? maxAllowedValue : 10}
                  onChange={(value) => setMinAllowedValue(value)}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ textAlign: "left" }}>
              <Form.Item name="max_allowed" label="max allowed :">
                <InputNumber
                  disabled={modifierItemModalVisible}
                  min={minAllowedValue ? minAllowedValue : 0}
                  max={10}
                  onChange={(value) => setMaxAllowedValue(value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <ModifierItemTable
          newModifierItems={newModifierItems}
          selectedModifierItem={selectedModifierItem}
          setSelectedModifierItem={setSelectedModifierItem}
          selectedModifier={selectedModifier}
          setModifierItemModalVisible={setModifierItemModalVisible}
        />
      </div>
      <EditingComponentFooter
        form={form}
        selectedModifier={selectedModifier}
        setSelectedModifier={setSelectedModifier}
        setModifierItemModalVisible={setModifierItemModalVisible}
        disabled={disabled}
      />
    </>
  );
};

export default connect(null, { updateModifier, deleteModifier })(ModifierEditor);
