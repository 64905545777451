import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { ButtonComponent } from '../Button'

import './styles.css'

const MenuInternalPromoEditor = props => {
  const {selectedInternalPromo, setSelectedInternalPromo} = props

  return (<>
    <div className="internal-promo-editing-component-bottom-container">
      <h2
        className="internal-promo-editing-component-label"
      >
        <em>{_.get(selectedInternalPromo, 'name')}</em>
      </h2>

      <div>
        <p>Type: {_.get(selectedInternalPromo, 'type')}</p>
        <div>
          <p>Rule: </p>
          <ul>
            {!!selectedInternalPromo?.rule?.basketSizeThreshold &&
             <li>Basket Size Threshold: {selectedInternalPromo?.rule?.basketSizeThreshold}</li>}
            {!!selectedInternalPromo?.rule?.orderIteration &&
             <li>Order Iteration: {selectedInternalPromo?.rule?.orderIteration}</li>}
            {!!selectedInternalPromo?.rule?.daysSinceFirstOrder &&
             <li>Days Since First Order: {selectedInternalPromo?.rule?.daysSinceFirstOrder}</li>}
            <li>Platforms: {selectedInternalPromo?.rule?.platforms?.join(', ') || "All"}</li>
          </ul>
        </div>
        <p>Item:</p>
        <ul>
          <li> {_.get(selectedInternalPromo, 'sku.name')}</li>
        </ul>
        <p>Targets: </p>
        <ul>
          {_.get(selectedInternalPromo, 'targets', []).map(item => <li
            key={item.id}>{item.name}</li>)}
        </ul>
        {!!_.get(selectedInternalPromo, 'expiry_time') &&
        <p>Expires
          On: {moment(_.get(selectedInternalPromo, 'expiry_time')).format('MM/DD/YYYY')}</p>}
      </div>
    </div>
    <div className="footer">
      <ButtonComponent
        className="cancel-button"
        text="Cancel"
        onClick={() => {setSelectedInternalPromo(null)}}
      />
    </div>
  </>)
}

export default MenuInternalPromoEditor
